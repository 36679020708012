import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from 'components';
import { useToasts } from 'react-toast-notifications';
import { Cell, Row, Table } from 'components/MyTable';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import AdminUserAPI from 'apis/AdminUserAPI';
import PATHS from 'routes/paths';
import TopButtonsBox from 'components/TopButtonsBox';
import CheckMark from 'components/misc/CheckMark';
import axios from 'axios';
import UserAPI from 'apis/UserAPI';
import MailButton from 'components/misc/MailButton';
import { getRole } from 'apis/AuthAPI';

const AdminUserList = () => {
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const header = [
		{ id: 'id', label: 'ID', filter: 'text' },
		{ id: 'email', label: 'E-mail', filter: 'text' },
		{ id: 'first_name', label: 'Imię', filter: 'text' },
		{ id: 'last_name', label: 'Nazwisko', filter: 'text' },
	];
	const [user, setUser] = useState({});
	const [limitReached, setLimitReached] = useState(false);
	const [limitText, setLimitText] = useState('');
	const role = getRole();
	const isSuperAdmin = role === 'superadmin';
	if (isSuperAdmin) {
		header.push(
			{ id: 'plan_name', label: 'Abonament', disableSort: true },
			{ id: 'subscription_valid_until', label: 'Ważny do', disableSort: true },
			{ id: 'parent_name', label: 'Manager', disableSort: true },
			{
				id: 'is_superadmin',
				label: 'Admin',
				filter: 'select',
				align: 'center',
				list: [
					{ id: 0, name: 'Nie' },
					{ id: 1, name: 'Tak' }
				]
			}, {
				id: 'is_billable',
				label: 'Abonament',
				filter: 'select',
				align: 'center',
				list: [
					{ id: 0, name: 'Nie' },
					{ id: 1, name: 'Tak' }
				]
			}, {
				id: 'is_active',
				label: 'Aktywny',
				filter: 'select',
				align: 'center',
				list: [
					{ id: 0, name: 'Nie' },
					{ id: 1, name: 'Tak' }
				]
			},
		);
	}
	header.push({
		id: 'is_confirmed',
		label: 'Potwierdzony',
		filter: 'select',
		align: 'center',
		list: [
			{ id: 0, name: 'Nie' },
			{ id: 1, name: 'Tak' }
		]
	}, {
		id: 'is_invited',
		label: 'Zaproszony',
		filter: 'select',
		align: 'center',
		list: [
			{ id: 0, name: 'Nie' },
			{ id: 1, name: 'Tak' }
		]
	}, {
		id: 'action',
		label: 'Akcje',
		align: 'center',
		disableSort: true,
		disableExport: true
	});

	const handleEdit = id => {
		history.push(PATHS.AdminUserEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			AdminUserAPI.delete(deleteId).then(response => {
				addToast(response?.message || 'Nieoczekiwany błąd', {
					appearance: response?.code === 200 ? 'success' : 'error',
					autoDismissTimeout: 10000,
					autoDismiss: true
				});
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleInvite = id => {
		AdminUserAPI.invite(id).then(response => {
			addToast(response?.message || 'Nieoczekiwany błąd', {
				appearance: response?.code === 200 ? 'success' : 'error',
				autoDismissTimeout: 10000,
				autoDismiss: true
			});
			getData();
		});
	};

	const getData = () => {
		setLoading(true);
		axios.all([
			AdminUserAPI.index(options),
			UserAPI.get(),
		]).then(axios.spread((list, user) => {
			if (list.code === 200) {
				setData(list.data);
				setRowsCount(list.total);
			}
			user?.data && setUser(user.data);

			setLoading(false);
		}));
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		if (role === 'manager' && user?.plan) {
			setLimitReached(rowsCount >= user.plan.users_nb);
			setLimitText(`Wykorzystane miejsca: ${rowsCount}/${user?.plan?.users_nb}`);
		}
	}, [rowsCount, user]);

	return (
		<>
			{!limitReached && (
				<TopButtonsBox
					add_button={{
						label: 'Dodaj nowego użytkownika',
						path: PATHS.AdminUserCreate,
					}}
					exportHandler={handleInvite}
					exportText="Wyślij zaproszenia"
					exportTitle="do wszystkich niezaproszonych"
				/>
			)}

			<Breadcrumbs list={['Administracja', 'Użytkownicy']} extraText={limitText}/>

			<Table
				header={header}
				loading={loading}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}
				deleteMessage="Czy na pewno usunąć wybranego użytkownika?"
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}>
				{data?.map((row, index) => (
					<React.Fragment key={index}>
						<Row>
							<Cell>{row.id}</Cell>
							<Cell>{row.email}</Cell>
							<Cell>{row.first_name}</Cell>
							<Cell>{row.last_name}</Cell>
							{isSuperAdmin && <>
								<Cell>{row?.subscription?.plan?.name}</Cell>
								<Cell>{row?.subscription?.valid_until}</Cell>
								<Cell>{row?.parent?.full_name}</Cell>
								<Cell>
									<CheckMark checked={row.is_superadmin}/>
								</Cell>
								<Cell>
									<CheckMark checked={row.is_billable}/>
								</Cell>
								<Cell>
									<CheckMark checked={row.is_active}/>
								</Cell>
							</>}
							<Cell>
								<CheckMark checked={row.is_confirmed}/>
							</Cell>
							<Cell>
								<CheckMark checked={row.is_invited}/>
							</Cell>
							<Cell align="center" width={180}>
								<EditButton onClick={() => handleEdit(row.id)}/>
								<TrashButton onClick={() => setDeleteId(row.id)}/>
								{!row.is_confirmed && (
									<MailButton onClick={() => handleInvite(row.id)} title={row.is_invited ? 'Wyślij ponownie zaproszenie' : 'Wyślij zaproszenie'}/>
								)}
							</Cell>
						</Row>
					</React.Fragment>
				))}
			</Table>
		</>
	);
};

export default AdminUserList;
