/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, SingleDetail } from 'components';
// import useStyles from './style';
import WarehouseAPI from 'apis/WarehouseAPI';
import PATHS from 'routes/paths';
import Loader from '../../components/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';

const Warehouse = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({ active: true });

	useEffect(() => {
		axios.all([
			id ? WarehouseAPI.get(id) : null,
		]).then(axios.spread((item) => {
			item?.data && setData(item.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		setLoading(true);
		WarehouseAPI.updateOrCreate(data, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.WarehouseList), 100);
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		WarehouseAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.WarehouseList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Dane podstawowe', { label: 'Magazyny', path: PATHS.WarehouseList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.WarehouseList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane dotyczące magazynu"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Nazwa magazynu"
							name="name"
							type="input"
							value={data.name}
							handleChange={handleChange}
						/>
						<FormInput
							title="Opis"
							name="description"
							type="area"
							value={data.description}
							handleChange={handleChange}
						/>
						<FormInput
							title="Aktywny"
							name="active"
							type="check_box"
							value={data.active}
							handleChange={handleChange}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default Warehouse;
