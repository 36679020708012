import React from 'react';
import PropTypes from 'prop-types';

import './form-input.scss';

const FormInput = props => {
  const { extra_classes, box_style, name, label, onChange, type = 'text', id = name, value = '', ...rest } = props;

	const handleChange = e => onChange(name, e.target.value);

  return (
    <div
      className={`form-input ${extra_classes || ''}`}
      style={box_style || {}}
    >
      {label &&
        <label htmlFor={id}>
          {label}
        </label>
      }

      <input
        onChange={handleChange}
        type={type}
        {...rest}
				value={value}
      />

    </div>
  );
};

FormInput.propTypes = {
  box_style: PropTypes.object,
  extra_classes: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
	value: PropTypes.any,
};

export default FormInput;
