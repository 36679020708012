/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Collapse from 'components/Collapse/Collapse';
import AssortmentAmount from './AssortmentAmount';
import GroupAmount from './GroupAmount';
import uniqid from 'uniqid';

const InventoryMaintenanceCost = props => {
	return (
		<>
			<Collapse
				defaultOpen
				title="Analiza kosztu utrzymania zapasu - ujęcie asortymentowe"
				content={
					<AssortmentAmount
						chartId={uniqid()}
						data={props.data?.assortment_shot}
					/>
				}
			/>

			<Collapse
				defaultOpen
				title="Analiza kosztu utrzymania zapasu - ujęcie grupowe"
				content={
					<GroupAmount chartId={uniqid()} data={props.data?.group_shot} />
				}
			/>
		</>
	);
};

export default InventoryMaintenanceCost;
