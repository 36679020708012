import React from 'react';

import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import useGlobalStyles from '../../assets/style/styles';

const EditButton = props => {
	const globalClasses = useGlobalStyles();
	return (
		<IconButton
			className={globalClasses.iconButton}
			{...props}>
			<EditOutlinedIcon className={globalClasses.icon} />
		</IconButton>
	);
};

export default EditButton;
