import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import { Box, Typography } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import Collapse from 'components/Collapse/Collapse';
import Chart from 'components/Chart/PieChart';
import { sortData, exportToXlsx, exportToPng, numberStringFixed } from 'utils/functions';

const SeparatelyReleaseSize = props => {
	const header = [
		{ id: 'name', label: 'Nazwa' },
		{ id: 'index', label: 'Indeks' },
		{ id: 'assortment_group_name', label: 'Grupa' },
		{ id: 'measure_unit', label: 'Jedn.miary' },
		{ id: 'cumulated_release_number', label: 'Skumulowana wielkość wydań [j.m.]' },
		{ id: 'cumulated_percentage', label: 'Skumulowany procent udziału' },
		{ id: 'group', label: 'Klasyfikacja' }
	];
	const [data, setData] = useState([]);
	const [chartData, setChartData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};
	useEffect(() => {
		if(!props.chartGrouped) {
			setChartData(
				data.map(i => {
					return (
						i.percentage_value && {
							label: i.name,
							y: i.percentage_value
						}
					);
				})
			);
		} else {
			let gr = {};
			data && data.map((item, index) => {
				const allItems = data.length
				const group = item.group
				let groupText = [];
				gr[group] = gr[group] ? gr[group] + 1 : 1;
			})
			let chartDataTemp = [];
			Object.keys(gr).forEach((key, index) => {
				chartDataTemp.push(
					{
						label: key,
						y: gr[key]
					}
				)
			})
			setChartData(chartDataTemp);
		}
	}, [data]);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<Collapse
			defaultOpen
			title="Analiza ABC - wielkość wydań"
			content={
				<>
					<Button onClick={handleExport}>Eksportuj do XLS</Button>
					<Button
						onClick={() => exportToPng(props.chartId)}
						style={{ marginLeft: 20 }}>
						Pobierz wykres .PNG
					</Button>

					<Box style={{ paddingTop: 10 }}>
						<Table
							header={header}
							disablePagination={true}
							sortOnly={['cumulated_percentage']}
							onSort={o =>
								setData([...sortData(data, o.columnId, o.direction)])
							}>
							{data.map((row, index) => {
								return (
									<Row key={index}>
										<Cell>{row.name}</Cell>
										<Cell>{row.index}</Cell>
										<Cell>{row.assortment_group_name}</Cell>
										<Cell>{row.measure_unit}</Cell>
										<Cell align="right">{numberStringFixed(row.cumulated_release_number)}</Cell>
										<Cell align="right">{numberStringFixed(row.cumulated_percentage)}%</Cell>
										<Cell>{row.group}</Cell>
									</Row>
								);
							})}
						</Table>
					</Box>

					<Box style={{ marginTop: 10 }}>
						<Typography>
							Grupa A - pozycje, które w klasycznym podziale stanowią{' '}
							{props.diff.a}% udziału wartości kryterialnej,
						</Typography>
						<Typography>
							Grupa B - pozycje, które w klasycznym podziale stanowią{' '}
							{props.diff.b}% udziału wartości kryterialnej,
						</Typography>
						<Typography>
							Grupa C - pozycje, które w klasycznym podziale stanowią{' '}
							{props.diff.c}% udziału wartości kryterialnej.
						</Typography>
					</Box>
					<Box marginTop={2}>
						<Chart id={props.chartId} data={chartData} />
					</Box>
				</>
			}
		/>
	);
};

export default SeparatelyReleaseSize;
