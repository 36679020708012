/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

export default function useDidUpdate(fn, dependencies = []) {
	const [did_mount, setDidMount] = React.useState(false);

	React.useEffect(() => {
		did_mount ? fn() : setDidMount(true);
	}, dependencies);
}
