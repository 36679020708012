const assortment_header = [
	{ id: 'name', label: 'Nazwa' },
	{ id: 'index', label: 'Indeks' },
	{ id: 'gtin', label: 'GTIN' },
	{ id: 'measure_unit_name', label: 'Jednostka miary' },
	{ id: 'logistic_unit_name', label: 'Jednostka logistyczna' },
	{ id: 'active', label: 'Aktywny', type: 'bool' },
	{ id: 'purchase_price', label: 'Cena zakupu [PLN]', type: 'number' },
	{ id: 'sale_price', label: 'Cena sprzedaży [PLN]', type: 'number' },
	{ id: 'assortment_group_name', label: 'Grupa asortymentowa' },
	{ id: 'supplier', label: 'Dostawca' },
	{ id: 'recipient', label: 'Odbiorca' },
	{ id: 'service_demand', label: 'Prawdopodobieństwo \nObsługi popytu [%]', type: 'number' },
	{ id: 'refill_cycle_time', label: 'Czas cyklu \nUzupełniania [dni]', type: 'number' },
	{ id: 'cycle_time_deviations', label: 'Odchylenia czasu \nCyklu uzupełnienia [dni]', type: 'number' },
	{ id: 'inventory_cost_factor', label: 'Współczynnik kosztu \nUtrzymania zapasu [-]', type: 'number' },
];

const assortment_group_header = [
	{ id: 'name', label: 'Nazwa grupy' },
	{ id: 'is_main_group', label: 'Grupa główna', type: 'bool' },
	{ id: 'main_group_name', label: 'Nazwa grupy głównej' },
	{ id: 'code', label: 'Kod' },
	{ id: 'description', label: 'Opis' }
];

const warehouse_header = [
	{ id: 'name', label: 'Nazwa magazynu' },
	{ id: 'description', label: 'Opis' },
	{ id: 'active', label: 'Aktywny', type: 'bool' }
];

const warehouse_group_header = [
	{ id: 'name', label: 'Nazwa grupy' },
	{ id: 'warehouses', label: 'Przynależne magazyny' },
	{ id: 'active', label: 'Aktywny', type: 'bool' },
	{ id: 'description', label: 'Opis' }
];

const contractor_header = [
	{ id: 'name', label: 'Nazwa' },
	{ id: 'code', label: 'Kod' },
	{ id: 'GTIN', label: 'GLN' },
	{ id: 'regon', label: 'REGON' },
	{ id: 'nip', label: 'NIP' },
	{ id: 'supplier', label: 'Dostawca', type: 'bool' },
	{ id: 'recipient', label: 'Odbiorca', type: 'bool' },
	{ id: 'active', label: 'Aktywny', type: 'bool' },
	{ id: 'street', label: 'Ulica' },
	{ id: 'building_number', label: 'Numer budynku' },
	{ id: 'apartment_number', label: 'Numer apartamentu' },
	{ id: 'postal_code', label: 'Kod pocztowy' },
	{ id: 'city', label: 'Miasto' },
	{ id: 'supplier_transport', label: 'Dostawca pokrywa koszty transportu', type: 'bool' },
	{ id: 'description', label: 'Opis' },
	{ id: 'minimum_order_quantity', label: 'Minimalna wielkość zamówienia', type: 'number' },
	{ id: 'minimum_order_value', label: 'Minimalna wartość zamówienia [PLN]', type: 'number' },
];

const measure_unit_header = [
	{ id: 'type_name', label: 'Jednostka' },
	{ id: 'name', label: 'Nazwa' },
	{ id: 'description', label: 'Opis' }
];

const warehouse_operation_header = [
	{ id: 'date', label: 'Data', type: 'date', format: 'MM/DD/YYYY' },
	{ id: 'assortment_name', label: 'Nazwa asortymentu' },
	{ id: 'assortment_group_name', label: 'Grupa' },
	{ id: 'measure_unit_name', label: 'Jednostka miary' },
	{ id: 'logistic_unit_name', label: 'Jednostka logistyczna' },
	{ id: 'warehouse_name', label: 'Magazyn' },
	{ id: 'received', label: 'Wielkość przyjęć', type: 'number' },
	{ id: 'release', label: 'Wielkość wydań', type: 'number' },
	{ id: 'order', label: 'Wielkość zamówień', type: 'number' },
	{ id: 'stock', label: 'Zapas [j.m.]', type: 'number', },
	{ id: 'handling_delivery_cost', label: 'Koszt obsługi i dostawy', type: 'number' },
	{ id: 'received_number', label: 'Liczba przyjęć [-]', type: 'number' },
	{ id: 'received_value', label: 'Wartość przyjęć [PLN]', type: 'number', },
	{ id: 'stock_value', label: 'Wartość zapasu [PLN]', type: 'number' },
	{ id: 'release_number', label: 'Liczba wydań [-]', type: 'number' },
	{ id: 'releases_value', label: 'Wartość wydań [PLN]', type: 'number', },
	{ id: 'order_value', label: 'Wartość zamówień [PLN]', type: 'number', },
];

const order_header = [
	{ id: 'order_number', label: 'Numer zamówienia' },
	{ id: 'purchaser.name', label: 'Zamawiający' },
	{ id: 'document_of_date', label: 'Dokument z dnia' },
	{ id: 'regon', label: 'REGON' },
	{ id: 'payment_terms', label: 'Warunki płatności' },
	{ id: 'bank_account', label: 'Konto bankowe' },
	{ id: 'nip', label: 'NIP' },

	{ id: 'street', label: 'Ulica' },
	{ id: 'building_number', label: 'Numer budynku' },
	{ id: 'apartment_number', label: 'Numer mieszkania' },
	{ id: 'postal_code', label: 'Kod pocztowy' },
	{ id: 'city', label: 'Miasto' },

	{ id: 'warehouse.name', label: 'Magazyn realizujący' },
	{ id: 'comments', label: 'Uwagi' },

	{ id: 'item.index', label: 'Lp.' },
	{ id: 'item.assortment.name', label: 'Nazwa towaru' },
	{ id: 'item.assortment.gtin', label: 'Kod towaru' },
	{ id: 'item.assortment.measure_name', label: 'Jednostka m.' },
	{ id: 'item.quantity', label: 'Ilość' },
	{ id: 'item.delivery_date', label: 'Termin dost.' },
	{ id: 'item.status', label: 'Zrealizowano' },
];

const document_pz_header = [
	{ id: 'document_id', label: 'Numer dokumentu' },
	{ id: 'supplier.name', label: 'Dostawca' },
	{ id: 'shipment_date', label: 'Data wysyłki' },
	{ id: 'order.order_number', label: 'Numer zamówienia' },
	{ id: 'transport_type', label: 'Środek transportu' },
	{ id: 'purpose', label: 'Przeznaczenie' },
	{ id: 'receipt_date', label: 'Data przyjęcia' },
	{ id: 'cost_free_shipping', label: 'Wysyłka na koszt' },

	{ id: 'issue_date', label: 'Data wystawienia' },
	{ id: 'warehouse.name', label: 'Magazyn' },

	{ id: 'item.index', label: 'Lp.' },
	{ id: 'item.assortment.name', label: 'Nazwa towaru' },
	{ id: 'item.code', label: 'Kod towaru' },
	{ id: 'item.assortment.measure_name', label: 'Jednostka m.' },
	{ id: 'item.delivered_quantity', label: 'Dostarczono' },
	{ id: 'item.received_quantity', label: 'Przyjęto' },
	{ id: 'item.price', label: 'Cena' },
	{ id: 'item.value', label: 'Wartość' },
	{ id: 'item.stock', label: 'Zapas (il.)' },
	{ id: 'item.handling_delivery_cost', label: 'Koszt obsługi i dostawy' },
];

const document_wz_header = [
	{ id: 'document_id', label: 'Numer dokumentu' },
	{ id: 'recipient.name', label: 'Odbiorca' },
	{ id: 'shipment_date', label: 'Data wysyłki' },
	{ id: 'order.order_number', label: 'Numer zamówienia' },
	{ id: 'transport_type', label: 'Środek transportu' },
	{ id: 'purpose', label: 'Przeznaczenie' },
	{ id: 'release_date', label: 'Data wydania' },
	{ id: 'cost_free_shipping', label: 'Wysyłka na koszt' },

	{ id: 'issue_date', label: 'Data wystawienia' },
	{ id: 'warehouse.name', label: 'Magazyn' },
	{ id: 'invoice.invoice_number', label: 'Numer faktury' },
	{ id: 'invoice_date', label: 'Data faktury' },

	{ id: 'item.index', label: 'Lp.' },
	{ id: 'item.assortment.name', label: 'Nazwa towaru' },
	{ id: 'item.code', label: 'Kod towaru' },
	{ id: 'item.assortment.measure_name', label: 'Jednostka m.' },
	{ id: 'item.quantity_requested', label: 'Żądano' },
	{ id: 'item.quantity_released', label: 'Wydano' },
	{ id: 'item.price', label: 'Cena' },
	{ id: 'item.value', label: 'Wartość' },
	{ id: 'item.stock', label: 'Zapas (il.)' },
];

const invoice_header = [
	{ id: 'invoice_number', label: 'Numer FV' },
	{ id: 'buyer.name', label: 'Nabywca' },
	{ id: 'issue_date', label: 'Data wystawienia' },
	{ id: 'payment_due', label: 'Termin zapłaty' },
	{ id: 'total_value', label: 'Razem wart. towarów' },
	{ id: 'tax', label: 'Razem kw. podatku' },
	{ id: 'gross', label: 'Razem w. tow. z pod.' },
	{ id: 'order.order_number', label: 'Zamówienie' },
	{ id: 'user.company_name', label: 'Wystawca' },
	{ id: 'assortments_count', label: 'Pozycji na FV' },

	{ id: 'sale_date', label: 'Data sprzedaży' },
	{ id: 'payment_type', label: 'Sposób zapłaty' },
	{ id: 'annotations', label: 'Adnotacje' },

	{ id: 'item.index', label: 'Lp.' },
	{ id: 'item.assortment.name', label: 'Nazwa towaru' },
	{ id: 'item.assortment.gtin', label: 'Kod towaru' },
	{ id: 'item.symbol', label: 'Symbol klas.' },
	{ id: 'item.assortment.measure_name', label: 'Jednostka m.' },
	{ id: 'item.quantity', label: 'Ilość' },
	{ id: 'item.price_nett', label: 'Cena jedn.' },
	{ id: 'item.value_nett', label: 'Wartość' },
	{ id: 'item.tax_rate', label: 'Stawka podatku [%]' },
	{ id: 'item.tax_value', label: 'Kwota podatku' },
	{ id: 'item.price_gross', label: 'Wartość z podatkiem' },
];

export {
	assortment_header,
	assortment_group_header,
	warehouse_header,
	warehouse_group_header,
	contractor_header,
	measure_unit_header,
	warehouse_operation_header,
	order_header,
	document_pz_header,
	document_wz_header,
	invoice_header,
};
