import React from 'react';

import { IconButton } from '@material-ui/core';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';

import useGlobalStyles from 'assets/style/styles';

const MailButton = props => {
	const globalClasses = useGlobalStyles();
	return (
		<IconButton
			className={globalClasses.iconButton}
			{...props}>
			<MailIcon className={globalClasses.icon} />
		</IconButton>
	);
};

export default MailButton;
