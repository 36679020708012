/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import { numberString, numberStringFixed } from 'utils/functions';

const useStyles = makeStyles(theme => ({
	container: {
		border: '1px solid lightgray'
	}
}));

const PreviewTable = props => {
	const { assortments/*, accepted*/ } = props;
	const classes = useStyles();

	const header = [
		{ id: 'no', label: 'Lp.', disableSort: true },
		{ id: 'name', label: 'Nazwa towaru', disableSort: true },
		{ id: 'code', label: 'Kod towaru', disableSort: true },
		{ id: 'measure_unit', label: 'Jednostka m.', disableSort: true },
		{ id: 'accepted', label: 'Przyjęto', disableSort: true, align: 'right' },
		{ id: 'price', label: 'Cena', disableSort: true, align: 'right' },
		{ id: 'value', label: 'Wartość', disableSort: true, align: 'right' },
		{ id: 'stock', label: 'Zapas (il.)', disableSort: true, align: 'right' },
		{ id: 'cost_handling_delivery', label: 'Koszt obsługi i dostawy', disableSort: true, align: 'right' }
	];

	return (
		<div className={classes.container}>
			<Table rows={assortments} header={header} disablePagination={true}>
				{assortments.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{index + 1}</Cell>
								<Cell>{row.assortment?.name}</Cell>
								<Cell>{row.code}</Cell>
								<Cell>{row.assortment?.measure_name}</Cell>
								<Cell align="right">{numberString(row.received_quantity)}</Cell>
								<Cell align="right">{numberStringFixed(row.price)}</Cell>
								<Cell align="right">{numberStringFixed(row.value)}</Cell>
								<Cell align="right">{numberString(row.stock)}</Cell>
								<Cell align="right">{numberStringFixed(row.handling_delivery_cost)}</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</div>
	);
};

export default PreviewTable;
