import React, {useEffect, useState} from 'react';
import {
	AutocompleteCheckboxed,
	Breadcrumbs,
	FormInput,
	SingleDetail
} from 'components';
import {useToasts} from 'react-toast-notifications';
import useStyles from './style';
import PATHS from 'routes/paths';
import {Grid, Typography} from '@material-ui/core';
import AssortmentGroupAPI from 'apis/AssortmentGroupAPI';
import WarehouseGroupAPI from 'apis/WarehouseGroupAPI';
import AnalyzeAbcAPI from 'apis/AnalyzeAbcAPI';
import DatePicker from 'components/FormInput/MyDatePicker';
import Loader from 'components/Loader';
import WarehouseOperationAPI from 'apis/WarehouseOperationAPI';
import { useHistory } from 'react-router-dom';

const AnalizeXyz = () => {
	const history = useHistory();
	const {addToast} = useToasts();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		name: '',
		description: '',
		assortments_group: [],
		warehouses_group: [],
		range_from: '',
		range_to: '',
		abc_analysis: true,
		releases_size: true,
		releases_value: true,
		stock_value: true,
		stock_size: true,
		xyz_analysis: true,

		abc_analysis_details_category_a_min: 0,
		abc_analysis_details_category_a_max: 80,

		abc_analysis_details_category_b_min: 80,
		abc_analysis_details_category_b_max: 95,

		abc_analysis_details_category_c_min: 95,
		abc_analysis_details_category_c_max: 100,

		xyz_analysis_details_category_a_min: 0,
		xyz_analysis_details_category_a_max: 20,

		xyz_analysis_details_category_b_min: 20,
		xyz_analysis_details_category_b_max: 60,

		xyz_analysis_details_category_c_max: 60
	});
	const classes = useStyles();
	const [assortmentsGroups, setAssortmentsGroups] = useState([]);
	const [warehouseList, setWarehouseList] = useState([]);
	const [errors, setErrors] = useState({
		abc_analysis_details_category_a_max: false,
		abc_analysis_details_category_b_max: false,
		xyz_analysis_details_category_a_max: false,
		xyz_analysis_details_category_b_max: false
	});

	useEffect(() => {
		AssortmentGroupAPI.getAsTree().then(res => {
			if (res.code === 200) {
				setAssortmentsGroups(res.data.assortments_group);
			}
		});

		WarehouseGroupAPI.getInfo().then(res => {
			if (res.code === 200) {
				setWarehouseList(res.data.warehouse_list);
			}
		});

		WarehouseOperationAPI.getDates().then(res => {
			const dates = res.data.dates;
			if (res.code === 200) {
				setData({
					...data,
					range_from: dates.from,
					range_to: dates.to,
				});
			}
		});
	}, []);

	const handleSave = () => {
		if (!data.assortments_group?.length) {
			addToast('Nie wybrano grupy asortymentu.', {appearance: 'error'});
			return;
		}
		if (!data.warehouses_group?.length) {
			addToast('Nie wybrano grupy magazynu.', {appearance: 'error'});
			return;
		}

		setLoading(true);
		AnalyzeAbcAPI.create(data).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AnalyzeXyzResult(response.data?.id)), 100);
			} else {
				addToast(response.message, {appearance: 'error'});
			}
			setLoading(false);
		});
	};

	const handleChange = (name, value) => {
		if (name === 'abc_analysis_details_category_a_max') {
			setData({
				...data,
				abc_analysis_details_category_a_min: 0,
				abc_analysis_details_category_a_max: value,
				abc_analysis_details_category_b_min: value,
				abc_analysis_details_category_c_max: 100
			});
			setErrors({
				...errors,
				abc_analysis_details_category_a_max:
					+value < 0 || +value > +data.abc_analysis_details_category_b_max
			});
		} else if (name === 'abc_analysis_details_category_b_max') {
			setData({
				...data,
				abc_analysis_details_category_a_min: 0,
				abc_analysis_details_category_b_max: value,
				abc_analysis_details_category_c_min: value,
				abc_analysis_details_category_c_max: 100
			});
			setErrors({
				...errors,
				abc_analysis_details_category_b_max:
					+value > 100 || +value < data.abc_analysis_details_category_a_max
			});
		} else if (name === 'xyz_analysis_details_category_a_max') {
			setData({
				...data,
				xyz_analysis_details_category_a_min: 0,
				xyz_analysis_details_category_a_max: value,
				xyz_analysis_details_category_b_min: value
			});
			setErrors({
				...errors,
				xyz_analysis_details_category_a_max:
					+value < 0 || +value > +data.xyz_analysis_details_category_b_max
			});
		} else if (name === 'xyz_analysis_details_category_b_max') {
			setData({
				...data,
				xyz_analysis_details_category_a_min: 0,
				xyz_analysis_details_category_b_max: value,
				xyz_analysis_details_category_c_max: value
			});
			setErrors({
				...errors,
				xyz_analysis_details_category_b_max:
					+value > 100 || +value < +data.xyz_analysis_details_category_a_max
			});
		} else if (name === 'abc_analysis') {
			setData({
				...data,
				[name]: value,
				releases_size: value,
				releases_value: value,
				stock_size: value,
				stock_value: value
			});
		} else if (name === 'releases_size') {
			setData({
				...data,
				[name]: value,
				abc_analysis:
					value || data.releases_value || data.stock_size || data.stock_value
			});
		} else if (name === 'releases_value') {
			setData({
				...data,
				[name]: value,
				abc_analysis:
					value || data.releases_size || data.stock_size || data.stock_value
			});
		} else if (name === 'stock_size') {
			setData({
				...data,
				[name]: value,
				abc_analysis:
					value || data.releases_size || data.releases_value || data.stock_value
			});
		} else if (name === 'stock_value') {
			setData({
				...data,
				[name]: value,
				abc_analysis:
					value || data.releases_size || data.releases_value || data.stock_size
			});
		} else {
			setData({...data, [name]: value});
		}
	};

	return (
		<>
			<Breadcrumbs
				list={['Analizy', { label: 'Analiza ABC i XYZ', path: PATHS.AnalyzeXyzList }, 'Przeprowadź nową']}
				back_url={PATHS.AnalyzeXyzList}
			/>

			<Loader loading={loading}>
				<SingleDetail title="Dane dotyczące analizy" handleSave={handleSave}>
				<React.Fragment>
					<FormInput
						title="Nazwa analizy"
						name="name"
						type="input"
						value={data.name}
						handleChange={handleChange}
					/>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography style={{marginTop: 20}}>Zakres od</Typography>
							<DatePicker
								value={data.range_from}
								onChange={v => setData({...data, range_from: v})}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography style={{marginTop: 20}}>Zakres do</Typography>
							<DatePicker
								value={data.range_to}
								onChange={v => setData({...data, range_to: v})}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormInput
								title="Opis"
								name="description"
								type="area"
								value={data.description}
								handleChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography component="h4" className={classes.selectLabel}>
								Grupa asortymentowa
							</Typography>
							<AutocompleteCheckboxed
								data={assortmentsGroups}
								value={data.assortments_group}
								label="Wpisz nazwę grupy..."
								name="assortments_group"
								handleChange={v => setData({...data, assortments_group: v})}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography component="h4" className={classes.selectLabel}>
								Grupa magazynów
							</Typography>
							<AutocompleteCheckboxed
								data={warehouseList}
								value={data.warehouses_group}
								name="warehouse_list"
								label="Wybierz grupę"
								handleChange={v => setData({...data, warehouses_group: v})}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography component="h4" className={classes.analizesLabel}>
								Wybierz analizę, którą chcesz przeprowadzić:
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormInput
										title="Analiza ABC"
										name="abc_analysis"
										type="check_box"
										value={data.abc_analysis}
										handleChange={handleChange}
									/>
									<Grid container spacing={0}>
										<Grid item xs={2}>
											&nbsp;
										</Grid>
										<Grid item xs={10}>
											<FormInput
												title="Wielkość wydań"
												name="releases_size"
												type="check_box"
												value={data.releases_size}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={2}>
											&nbsp;
										</Grid>
										<Grid item xs={10}>
											<FormInput
												title="Wartość wydań"
												name="releases_value"
												type="check_box"
												value={data.releases_value}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={2}>
											&nbsp;
										</Grid>
										<Grid item xs={10}>
											<FormInput
												title="Wielkość zapasu"
												name="stock_size"
												type="check_box"
												value={data.stock_size}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={2}>
											&nbsp;
										</Grid>
										<Grid item xs={10}>
											<FormInput
												title="Wartość zapasu"
												name="stock_value"
												type="check_box"
												value={data.stock_value}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Grid
										container
										spacing={1}
										justifyContent="center"
										alignItems="baseline">
										<Grid item xs={4}>
											<Typography component="h4" className={classes.catLabel}>
												Kategoria A:
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Min [%]"
												name="abc_analysis_details_category_a_min"
												type="number_switch"
												disabled={true}
												min={0}
												max={data.abc_analysis_details_category_b_max}
												value={data.abc_analysis_details_category_a_min}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Max [%]"
												min={0}
												max={100}
												name="abc_analysis_details_category_a_max"
												type="number_switch"
												error={errors.abc_analysis_details_category_a_max}
												value={data.abc_analysis_details_category_a_max}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										justifyContent="center"
										alignItems="baseline">
										<Grid item xs={4}>
											<Typography component="h4" className={classes.catLabel}>
												Kategoria B:
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Min [%]"
												name="abc_analysis_details_category_b_min"
												type="number_switch"
												disabled={true}
												value={data.abc_analysis_details_category_b_min}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Max [%]"
												min={data.abc_analysis_details_category_b_min}
												max={100}
												error={errors.abc_analysis_details_category_b_max}
												name="abc_analysis_details_category_b_max"
												type="number_switch"
												value={data.abc_analysis_details_category_b_max}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										justifyContent="center"
										alignItems="baseline">
										<Grid item xs={4}>
											<Typography component="h4" className={classes.catLabel}>
												Kategoria C:
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Min [%]"
												disabled={true}
												type="number_switch"
												value={data.abc_analysis_details_category_c_min}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Max [%]"
												name="abc_analysis_details_category_c_max"
												type="number_switch"
												min={100}
												max={100}
												disabled={true}
												value={data.abc_analysis_details_category_c_max}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormInput
										title="Analiza XYZ"
										name="xyz_analysis"
										type="check_box"
										value={data.xyz_analysis}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<Grid
										container
										spacing={1}
										justifyContent="center"
										alignItems="baseline">
										<Grid item xs={4}>
											<Typography component="h4" className={classes.catLabel}>
												Kategoria X:
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Min [%]"
												name="xyz_analysis_details_category_a_min"
												type="number_switch"
												disabled={true}
												min={0}
												max={100}
												value={data.xyz_analysis_details_category_a_min}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Max [%]"
												min={0}
												max={data.xyz_analysis_details_category_b_max}
												name="xyz_analysis_details_category_a_max"
												type="number_switch"
												value={data.xyz_analysis_details_category_a_max}
												error={errors.xyz_analysis_details_category_a_max}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										justifyContent="center"
										alignItems="baseline">
										<Grid item xs={4}>
											<Typography component="h4" className={classes.catLabel}>
												Kategoria Y:
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Min [%]"
												min={data.xyz_analysis_details_category_a_max}
												max={100}
												name="xyz_analysis_details_category_b_min"
												type="number_switch"
												disabled={true}
												value={data.xyz_analysis_details_category_b_min}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Max [%]"
												name="xyz_analysis_details_category_b_max"
												type="number_switch"
												value={data.xyz_analysis_details_category_b_max}
												error={errors.xyz_analysis_details_category_b_max}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										justifyContent="center"
										alignItems="baseline">
										<Grid item xs={4}>
											<Typography component="h4" className={classes.catLabel}>
												Kategoria Z:
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography
												component="h4"
												className={classes.catLabel}
												style={{textAlign: 'right'}}>
												powyżej
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<FormInput
												title="Max [%]"
												name="xyz_analysis_details_category_c_max"
												type="number_switch"
												disabled={true}
												min={100}
												max={100}
												value={data.xyz_analysis_details_category_c_max}
												handleChange={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</React.Fragment>
			</SingleDetail>
			</Loader>
		</>
	);
};

export default AnalizeXyz;
