/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, Loader, SingleDetail } from 'components';
import { Grid } from '@material-ui/core';
import ContractorAPI from 'apis/ContractorAPI';
import PATHS from 'routes/paths';
import { useToasts } from 'react-toast-notifications';
import Barcoder from 'barcoder';
import { validateNIP, validateREGON } from 'helpers/validators';
import { getResponseMessage } from 'utils/functions';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

const Contractor = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		axios.all([
			id ? ContractorAPI.get(id) : null,
		]).then(axios.spread((item) => {
			item?.data && setData(item.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		if (!data.name.length) {
			addToast('Nazwa kontrahenta jest wymagana.', { appearance: 'error' });
			return;
		}
		if (data.GTIN.length) {
			Barcoder.version = 'ean13';
			if (!Barcoder.validate(data.GTIN)) {
				addToast('Nieprawidłowy numer GLN.', { appearance: 'error' });
				return;
			}
		}
		if (data.nip.length) {
			if (!validateNIP(data.nip)) {
				addToast('Nieprawidłowy numer NIP.', { appearance: 'error' });
				return;
			}
		}
		if (data.regon.length) {
			if (!validateREGON(data.regon)) {
				addToast('Nieprawidłowy numer REGON.', { appearance: 'error' });
				return;
			}
		}
		setLoading(true);
		ContractorAPI.updateOrCreate(data, id).then(response => {
			if ([200,201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.ContractorList), 100);
			} else {
				addToast(getResponseMessage(response), { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		ContractorAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.ContractorList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				back_url={PATHS.ContractorList}
				list={['Dane podstawowe', { label: 'Kontrahenci', path: PATHS.ContractorList }, id ? 'Edytuj' : 'Dodaj']}
			/>

			<Loader loading={loading}>
				<SingleDetail
					handleDelete={handleDelete}
					handleSave={handleSave}
					title="Dane dotyczące kontrahenta"
					type={id && 'edit'}>
					<React.Fragment>
						<FormInput
							handleChange={handleChange}
							name="name"
							title="Nazwa kontrahenta"
							type="input"
							value={data.name}
						/>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="code"
									title="Kod"
									type="input"
									value={data.code}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="GTIN"
									title="GLN"
									type="input"
									value={data.GTIN}
								/>
							</Grid>
						</Grid>
						<FormInput
							handleChange={handleChange}
							name="supplier"
							title="Dostawca"
							type="check_box"
							value={data.supplier}
						/>
						<FormInput
							handleChange={handleChange}
							name="recipient"
							title="Odbiorca"
							type="check_box"
							value={data.recipient}
						/>
						<FormInput
							handleChange={handleChange}
							name="active"
							title="Aktywny"
							type="check_box"
							value={data.active}
						/>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<FormInput
									handleChange={handleChange}
									name="street"
									title="Ulica"
									type="input"
									value={data.street}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									handleChange={handleChange}
									name="building_number"
									title="Numer budynku"
									type="input"
									value={data.building_number}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormInput
									handleChange={handleChange}
									name="apartment_number"
									title="Numer apartamentu"
									type="input"
									value={data.apartment_number}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									// error={error.postal_code}
									handleChange={handleChange}
									name="postal_code"
									title="Kod pocztowy"
									type="postal_code"
									value={data.postal_code}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="city"
									title="Miejscowość"
									type="input"
									value={data.city}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="regon"
									title="REGON"
									type="number"
									value={data.regon}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="nip"
									title="NIP"
									type="number"
									value={data.nip}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="minimum_order_quantity"
									title="Minimalna wielkość zamówienia"
									type="number"
									value={data.minimum_order_quantity}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									handleChange={handleChange}
									name="minimum_order_value"
									title="Minimalna wartość zamówienia [PLN] "
									type="number"
									value={data.minimum_order_value}
								/>
							</Grid>
						</Grid>
						<FormInput
							handleChange={handleChange}
							name="description"
							title="Opis"
							type="area"
							value={data.description}
						/>
						<FormInput
							handleChange={handleChange}
							name="supplier_transport"
							title="Dostawca pokrywa koszt transportu"
							type="check_box"
							value={data.supplier_transport}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default Contractor;
