export { default as SubscriptionPlans } from './SubscriptionPlans';

export const getPeriodName = period => {
	switch (period) {
		case 'month':
			return 'Miesięczny';
		case 'semester':
			return 'Semestralny';
		case 'year':
			return 'Roczny';
	}
};
export const getPeriod = period => {
	switch (period) {
		case 'month':
			return '1 miesiąc';
		case 'semester':
			return '5 miesięcy';
		case 'year':
			return '12 miesięcy';
	}
};
export const getTypeDescription = type => {
	switch (type) {
		case 'personal':
			return 'Pakiet indywidualny - oferta dla ucznia';
		case 'group':
			return 'Pakiety Grupowe - oferta dla nauczyciela/szkoły';
	}
};
export const getTypeName = type => {
	switch (type) {
		case 'personal':
			return 'indywidualny';
		case 'group':
			return 'grupowy';
	}
};
