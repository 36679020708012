/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: 'black',
		color: 'white',
		fontSize: theme.typography.pxToRem(12),
		borderRadius: 5
	}
}));

const useStyles = makeStyles(theme => ({
	root: { position: 'relative' },
	icon: {
		cursor: 'pointer',
		position: 'absolute',
		zIndex: 1,
		right: 4,
		top: -5,
		color: '#999',
		'&:hover': { color: '#44D62C' }
	}
}));

const InfoBox = props => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<HtmlTooltip
				placement="top"
				title={
					<>
						{props.data.map((txt, i) => (
							<Typography key={i} style={{ color: 'white' }}>
								{txt}
							</Typography>
						))}
					</>
				}>
				<InfoOutlinedIcon className={classes.icon} />
			</HtmlTooltip>
		</Box>
	);
};

export default InfoBox;
