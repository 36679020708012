/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumbs, FormInput, Loader, SingleDetail } from 'components';
import WarehouseGroupApi from 'apis/WarehouseGroupAPI';
import WarehouseAPI from 'apis/WarehouseAPI';
import PATHS from 'routes/paths';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';

const WarehouseGroup = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({ active: true });
	const [listInfo, setListInfo] = useState([]);

	useEffect(() => {
		axios.all([
			id ? WarehouseGroupApi.get(id) : null,
			WarehouseAPI.getAll(),
		]).then(axios.spread((item, info) => {
			if (item?.data) {
				const warehouses = item.data.warehouses.map(item => item.warehouse_id);
				setData({ ...item.data, warehouses });
			}
			info?.data && setListInfo(info.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		data.warehouses = data.warehouses.filter(v => !!v);
		if (!data.name.length) {
			addToast('Nazwa grupy nie może być pusta.', { appearance: 'error' });
			return;
		}
		if (!data.warehouses.length) {
			addToast('Wymagane jest wybranie co najmniej 1 magazynu przynależnego.', { appearance: 'error' });
			return;
		}
		setLoading(true);
		WarehouseGroupApi.updateOrCreate(data, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.WarehouseGroupList), 100);
			}
		});
	};

	const handleDelete = () => {
		setLoading(true);
		WarehouseGroupApi.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.WarehouseGroupList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Dane podstawowe', { label: 'Grupy magazynów', path: PATHS.WarehouseGroupList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.WarehouseGroupList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane dotyczące grupy magazynów"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Nazwa grupy"
							name="name"
							type="input"
							value={data.name}
							handleChange={handleChange}
						/>
						<FormInput
							title="Przynależne magazyny"
							name="warehouses"
							type="several_single"
							value={data?.warehouses || []}
							handleChange={handleChange}
							list={listInfo}
							button_title="Dodaj kolejny magazyn"
						/>
						<FormInput
							title="Opis"
							name="description"
							type="area"
							value={data.description}
							handleChange={handleChange}
						/>
						<FormInput
							title="Aktywny"
							name="active"
							type="check_box"
							value={data.active}
							handleChange={handleChange}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default WarehouseGroup;
