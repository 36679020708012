import React from 'react';
import { SubscriptionPlans } from 'views/SubscriptionPlans';
import { WelcomeDetails, WelcomeExpired } from 'views/Welcome';
import { getRole, isSubscriptionExpiring, isSubscriptionValid } from 'apis/AuthAPI';

const Welcome = () => {
	const role = getRole();
	if (role === 'managed_user') {
		return isSubscriptionValid() ? <WelcomeDetails/> : <WelcomeExpired/>;
	} else {
		return isSubscriptionValid() && !isSubscriptionExpiring() ? <WelcomeDetails/> : <SubscriptionPlans/>
	}
};

export default Welcome;
