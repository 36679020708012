import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

Sentry.init({
	dsn: 'https://2f5b16945a034a8eb38da1a18557d1fc@o286660.ingest.sentry.io/5884772',
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
