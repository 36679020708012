class constants {
	CHECK_ALL_FIELDS = 'Proszę sprawdzić wszystkie pola.';
	ENTER_VALID_EMAIL = 'Proszę wprowadzić poprawny adres e-mail.';
	ENTER_SPEC_PASSWORD = 'Hasło musi zawierać minimum 8 znaków, małe i wielkie litery oraz cyfry, a dodatkowo posiadać minimum jeden znak specjalny: !,@,#,?';
	ENTER_SAME_PASSWORD = 'Hasła muszą być takie same.';
	CHECK_POLICY = 'Proszę zaakceptować regulamin.';
	WRONG_XLSX_HEADER = 'Importowany plik xls(x) zawiera nieobsługiwane kolumny: ';
	WRONG_XLSX_HEADER_LENGTH = 'Importowany plik xls(x) ma nieprawidłową liczbę kolumn.';
	WRONG_DATA = 'Musisz wypełnić formularz.';
	VAT = 23;
}

export default new constants();

