/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';

import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#fff',
		border: '1px solid #c5c5c5!important',
		borderRadius: 4,
		height: 35,
		paddingLeft: 5,
		boxSizing: 'initial',
		width: 'calc(100% - 10px)'
	}
}));

const MyDateRangePicker = props => {
	const classes = useStyles();
	const { value, ...other } = props;

	const [startDate, setStartDate] = useState();
	const handleChange = ymd => {
		setStartDate(ymd);
		props.onChange(ymd ? moment(ymd).format('YYYY-MM-DD') : '');
	};

	useEffect(() => {
		setStartDate(value ? moment(value)?.toDate() : null);
	}, [value]);

	return (
		<DatePicker
			locale={pl}
			className={classes.root}
			selected={startDate}
			dateFormat="dd/MM/yyyy"
			startDate={startDate}
			// disabled={props.disabled}
			// showMonthDropdown
			showYearDropdown
			// isClearable={props.isClearable}
			placeholderText="Wpisz datę..."
			showPopperArrow={false}
			{...other}
			onChange={handleChange}
		/>
	);
};

export default MyDateRangePicker;
