import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	catLabel: {
		fontSize: 16,
		top: 32,
		position: 'relative'
	},
	selectLabel: {
		fontSize: 16,
		marginBottom: 10
	},
	analizesLabel: {
		fontSize: 16,
		marginTop: 20
	},
	table: {

	},
}));

export default useStyles;
