import API from './API';
import { getParamsByOptions } from '../utils/functions';

const WarehouseAPI = {
	get: id => API.get(`/warehouses/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/warehouses/${id}`, data) : API.post('/warehouses', data),
	delete: id => API.delete(`/warehouses/${id}`),
	index: options => API.get('/warehouses' + getParamsByOptions(options)),
	getAll: params => API.get('/warehouses/all', { params }),
	export: options => API.get('/warehouses/export' + getParamsByOptions(options)),
	create_list: data => API.post('/warehouses/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/warehouses/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default WarehouseAPI;
