import React from 'react';


export default function DocumentTableCell(props) {
	return (
		<div
			style={{ flex: `0 0 ${props.size}px`, maxWidth: `${props.size}px` }}
			className='document-table__td'
		>
			{props.children}
		</div>
	);
}

