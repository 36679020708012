import constants from '../utils/constants';

export const getValue = data => name => {
	return name in data ? data[name] : '';
};

export const validatePassword = password => {
	const pattern = new RegExp(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/);
	if (!pattern.test(password)) {
		return constants.ENTER_SPEC_PASSWORD;
	}
	return '';
}
