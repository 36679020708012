/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { Box } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import { sortData, exportToXlsx, numberStringFixed } from 'utils/functions';

const AssortmentAmount = props => {
	const header = [
		{ id: 'name', label: 'Nazwa' },
		{ id: 'index', label: 'Indeks' },
		{ id: 'assortment_group_name', label: 'Grupa' },
		{ id: 'measure_name', label: 'Jedn.miary' },
		{
			id: 'rotation_value',
			label: 'Rotacja ilościowo',
			info: ['Wr = P/Z', 'P - popyt', 'Z - średni zapas stanu']
		},
		{
			id: 'coverage_ratio_value',
			label: 'Wskaźnik pokrycia ilościowo [dni]',
			info: [
				'WP = (Z/P) * liczba dni w badanym okresie',
				'P - popyt',
				'Z - średni zapas stanu'
			]
		},
		{
			id: 'sir_value',
			label: 'SIR ilościowo',
			info: ['SIR = [(PR - NB)/PR] * 100%', 'PR - popyt', 'NB - liczba braków']
		}
	];
	const [data, setData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<>
			<Box mb={2}>
				<Button onClick={handleExport}>Eksportuj do XLS</Button>
			</Box>
			<Box mb={2}>
				<Table
					header={header}
					disablePagination={true}
					onSort={o => setData([...sortData(data, o.columnId, o.direction)])}>
					{data.map((row, index) => {
						return (
							<Row key={index}>
								<Cell>{row.name}</Cell>
								<Cell>{row.index}</Cell>
								<Cell>{row.assortment_group_name}</Cell>
								<Cell>{row.measure_name}</Cell>
								<Cell align="right">{numberStringFixed(row.rotation_value)}</Cell>
								<Cell align="right">{numberStringFixed(row.coverage_ratio_value)}</Cell>
								<Cell align="right">{numberStringFixed(row.sir_value)}%</Cell>
							</Row>
						);
					})}
				</Table>
			</Box>
		</>
	);
};

export default AssortmentAmount;
