/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from 'components';
import useStyles from './style';
import { Table, Row, Cell } from 'components/MyTable';
import { useToasts } from 'react-toast-notifications';
import PATHS from 'routes/paths';
import AdminSubscriptionAPI from 'apis/AdminSubscriptionAPI';
import SubscriptionPlanAPI from 'apis/SubscriptionPlanAPI';

const AdminSubscriptionList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const rowsPerPage = 20;
	const [header, setHeader] = useState([
		{ id: 'id', label: 'ID', filter: 'text' },
		{ id: 'user_name', label: 'Użytkownik', filter: 'text', disableSort: true },
		{ id: 'user_email', label: 'Email', filter: 'text', disableSort: true },
		{ id: 'plan_id', label: 'Plan', filter: 'select', list: [], disableSort: true },
		{ id: 'valid_until', label: 'Wygasa', filter: 'date', align: 'center' },
		{ id: 'created_at', label: 'Utworzony', filter: 'date', align: 'center' },
		{ id: 'updated_at', label: 'Zaktualizowany', filter: 'date', align: 'center' },
	]);
	const [loadingInfo, setLoadingInfo] = useState(true);

	const getData = () => {
		setLoading(true);
		AdminSubscriptionAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		setLoadingInfo(true);
		SubscriptionPlanAPI.getInfo().then(response => {
			let newHeader = header;
			const index = newHeader.findIndex(i => i.id === 'plan_id');
			if (index > -1) {
				newHeader[index].list = response.data || [];
				setHeader(newHeader);
			}
			setLoadingInfo(false);
		});
	}, []);

	return (
		<>
			<Breadcrumbs list={['Administracja', 'Abonamenty']}/>

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading || loadingInfo}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{row.id}</Cell>
								<Cell>{row.user?.full_name}</Cell>
								<Cell>{row.user?.email}</Cell>
								<Cell>{row.plan?.name}</Cell>
								<Cell>{row.valid_until}</Cell>
								<Cell>{row.created_at}</Cell>
								<Cell>{row.updated_at}</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default AdminSubscriptionList;
