import React from 'react';
import Button from 'components/Button';
import './document.scss';

const Document = props => {

	const { extra_classes, style, onSaveAndGenerate } = props;

	return (
		<div className='document-wrap'>
			<div className={`document ${extra_classes || ''}`} style={style}>
				{props.children}
			</div>

			<div className='document-bottom'>

				<Button onClick={onSaveAndGenerate}>
					Zapisz i generuj dokument
				</Button>

			</div>
		</div>
	);
};


export default Document;
