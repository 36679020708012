/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Autocomplete from 'components/FormInput/Autocomplete';
import { DocumentTableTr } from 'components/document/DocumentTable';
import FormSelect from 'components/form/FormSelect';
import { groupByFirstLetter, getPriceZlAndGr } from 'utils/functions';

export default function InvoiceCreateTableRow(props) {
	const {
		row,
		index,
		assortments_options,
		assortmentList,
		taxesOptions,
		column_sizes
	} = props;

	const { changeRow, removeRow } = props;

	const [price_nett_zl, price_nett_gr] = getPriceZlAndGr(row.price_nett);
	const [value_nett_zl, value_nett_gr] = getPriceZlAndGr(row.value_nett);
	const [tax_value_zl, tax_value_gr] = getPriceZlAndGr(row.tax_value);
	const [price_gross_zl, price_gross_gr] = getPriceZlAndGr(row.price_gross);

	useEffect(() => {
		if (row.price_nett && +row.quantity >= 0) {
			const value_nett = String((+row.price_nett * 100 * +row.quantity) / 100);
			changeRow(index, { value_nett });
		}
	}, [row.quantity, row.assortment_id]);

	useEffect(() => {
		let price_gross = 0, tax_value = 0;

		if (row.tax_value >= 0 && row.value_nett >= 0) {
			price_gross = String(
				(+row.tax_value * 100 + +row.value_nett * 100) / 100
			);
		}

		const tax = taxesOptions.find(v => parseInt(v.tax_id) === parseInt(row.tax_id));
		const tax_rate = tax ? tax.value_double : 0;
		if (+row.value_nett >= 0 && tax_rate >= 0) {
			tax_value = String((+row.value_nett * 100 * tax_rate) / 100);
		}
		changeRow(index, { tax_value, tax_rate: tax ? tax.value_int : 0, price_gross });
	}, [row.tax_value, row.value_nett, row.tax_id]);

	const cells = [
		index + 1,
		<Autocomplete
			options={groupByFirstLetter(assortmentList)}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
			value={assortments_options.find(i => +i.id === +row.assortment_id)}
			onChange={val => {
				changeRow(
					index,
					val
						? {
								assortment_id: val.id,
								code: val.gtin,
								price_nett: val.sale_price,
								measurement_unit_id: val.measure_unit,
								measure_name: val.measure_name,
								tax_id: taxesOptions.length ? taxesOptions[taxesOptions.length - 1].tax_id : null,
						  }
						: null
				);
			}}
		/>,
		row?.code ? row.code : '---',
		<input
			type="text"
			name="symbol"
			value={row.symbol || ''}
			disabled={!row.assortment_id}
			onChange={e => {
				changeRow(index, { symbol: e.target.value });
			}}
		/>,
		<input
			type="number"
			name="quantity"
			min={0}
			value={row.quantity || ''}
			disabled={!row.assortment_id}
			onChange={e => {
				changeRow(index, { quantity: e.target.value });
			}}
		/>,
		row.measure_name,
		price_nett_zl,
		price_nett_gr || '0',
		value_nett_zl,
		value_nett_gr || '0',
		<FormSelect
			name="tax_id"
			disabled={!row.assortment_id}
			defaultValue={taxesOptions.length ? taxesOptions[0].tax_id : null}
			value={row.tax_id}
			options={taxesOptions}
			onChange={e => {
				changeRow(index, {
					tax_id: e.target.value,
				});
			}}
		/>,
		tax_value_zl,
		tax_value_gr || '0',
		price_gross_zl,
		price_gross_gr || '0'
	];

	return (
		<DocumentTableTr
			cells={cells}
			column_sizes={column_sizes}
			removeRow={removeRow}
			index={index}
		/>
	);
}
