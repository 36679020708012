/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { Box } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import ChartButton from 'components/misc/ChartButton';
import Chart from 'components/Chart/BarChart';
import { sortData, exportToXlsx, exportToPng, numberStringFixed } from 'utils/functions';

const GroupAmount = props => {
	const header = [
		{ id: 'assortment_group_name', label: 'Grupa' },
		{ id: 'measure_name', label: 'Jedn.miary' },
		{
			id: 'rotation_value',
			label: 'Rotacja ilościowo',
			info: ['Wr = P/Z', 'P - popyt', 'Z - średni zapas stanu']
		},
		{
			id: 'coverage_ratio_value',
			label: 'Wskaźnik pokrycia ilościowo [dni]',
			info: [
				'WP = (Z/P) * liczba dni w badanym okresie',
				'P - popyt',
				'Z - średni zapas stanu'
			]
		},
		{
			id: 'sir_value',
			label: 'SIR ilościowo',
			info: ['SIR = [(PR - NB)/PR] * 100%', 'PR - popyt', 'NB - liczba braków']
		},
		{ id: 'action', label: '' }
	];
	const [data, setData] = useState([]);
	const [activeRow, setActiveRow] = useState(false);
	const [chartData, setChartData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};

	const handleDrawChart = row => {
		setChartData(Object.entries(row.chart_data).map(([k, v]) => ({
			label: k,
			y: v
		})));
		setActiveRow(row);
	};

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<>
			<Box mb={2}>
				<Button onClick={handleExport}>Eksportuj do XLS</Button>
				<Button
					onClick={() => exportToPng(props.chartId)}
					style={{ marginLeft: 20 }}>
					Pobierz wykres .PNG
				</Button>
			</Box>
			<Box mb={2}>
				<Table
					header={header}
					disablePagination={true}
					onSort={o => setData([...sortData(data, o.columnId, o.direction)])}>
					{data.map((row, index) => {
						return (
							<Row key={index}>
								<Cell>{row.assortment_group_name}</Cell>
								<Cell>{row.measure_name}</Cell>
								<Cell align="right">{numberStringFixed(row.rotation_value)}</Cell>
								<Cell align="right">{numberStringFixed(row.coverage_ratio_value)}</Cell>
								<Cell align="right">{numberStringFixed(row.sir_value)}%</Cell>
								<Cell>
									<ChartButton
										active={activeRow === row}
										onClick={() => handleDrawChart(row)}
									/>
								</Cell>
							</Row>
						);
					})}
				</Table>
			</Box>
			{activeRow && (
				<Box id={props.chartId} mb={2}>
					<Chart data={chartData}/>
				</Box>
			)}
		</>
	);
};

export default GroupAmount;
