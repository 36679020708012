import API from './API';
import { getParamsByOptions } from 'utils/functions';

const AdminPlanAPI = {
	index: options => API.get('/admin/plans' + getParamsByOptions(options)),
	get: id => API.get(`/admin/plans/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/admin/plans/${id}`, data) : API.post('/admin/plans', data),
	delete: id => API.delete(`/admin/plans/${id}`),
};

export default AdminPlanAPI;
