import React, { useEffect, useState } from 'react';
import {
	AutocompleteCheckboxed,
	Breadcrumbs,
	FormInput,
	SingleDetail
} from 'components';
import { useToasts } from 'react-toast-notifications';
import PATHS from 'routes/paths';
import { Box, Grid, Typography } from '@material-ui/core';
import Loader from 'components/Loader';
import StockAnalyzes from 'apis/StockAnalyzeAPI';
import DatePicker from 'components/FormInput/MyDatePicker';
import AssortmentGroupAPI from 'apis/AssortmentGroupAPI';
import WarehouseGroupAPI from 'apis/WarehouseGroupAPI';
import WarehouseOperationAPI from 'apis/WarehouseOperationAPI';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const StockAnalyze = () => {
	const history = useHistory();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		name: '',
		description: '',
		assortments_group: [],
		warehouses_group: [],
		range_from: '',
		range_to: '',
		structure_analysis: true,
		structure_quantitative_analysis: true,
		structure_value_analysis: true,
		ratio_analysis: true,
		ratio_quantitative_analysis: true,
		ratio_value_analysis: true,
		cost_analysis: true,
	});
	const [assortmentsGroups, setAssortmentsGroups] = useState([]);
	const [warehouseList, setWarehouseList] = useState([]);

	useEffect(() => {
		axios.all([
			AssortmentGroupAPI.getAsTree(),
			WarehouseGroupAPI.getInfo(),
			WarehouseOperationAPI.getDates(),
		]).then(axios.spread((assGroup, warehouses, operations) => {
			assGroup?.data && setAssortmentsGroups(assGroup.data?.assortments_group);
			warehouses?.data && setWarehouseList(warehouses.data?.warehouse_list);
			operations?.data && setData({...data,
				range_from: operations.data?.dates?.from,
				range_to: operations.data?.dates?.to,
			});

			setLoading(false);
		}));
	}, []);

	const handleSave = () => {
		if (!data.name.length) {
			addToast('Nazwa analizy nie może być pusta.', {
				appearance: 'error'
			});
			return;
		}
		if (!data.assortments_group.length) {
			addToast('Grupa asortymentowa jest wymagana.', {
				appearance: 'error'
			});
			return;
		}
		if (!data.warehouses_group.length) {
			addToast('Grupa magazynowa jest wymagana.', {
				appearance: 'error'
			});
			return;
		}

		setLoading(true);
		StockAnalyzes.create(data).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.StockAnalyzeResult(response.data?.id)), 100);
			} else {
				addToast(response.message, {appearance: 'error'});
			}
			setLoading(false);
		});
	};

	const handleChange = (name, value) => {
		if (name === 'structure_analysis') {
			setData({
				...data,
				[name]: value,
				structure_quantitative_analysis: value,
				structure_value_analysis: value
			});
		} else if (name === 'ratio_analysis') {
			setData({
				...data,
				[name]: value,
				ratio_quantitative_analysis: value,
				ratio_value_analysis: value
			});
		} else if (name === 'structure_quantitative_analysis') {
			setData({
				...data,
				[name]: value,
				structure_analysis: value || data.structure_value_analysis
			});
		} else if (name === 'structure_value_analysis') {
			setData({
				...data,
				[name]: value,
				structure_analysis: value || data.structure_quantitative_analysis
			});
		} else if (name === 'ratio_quantitative_analysis') {
			setData({
				...data,
				[name]: value,
				ratio_analysis: value || data.ratio_value_analysis
			});
		} else if (name === 'ratio_value_analysis') {
			setData({
				...data,
				[name]: value,
				ratio_analysis: value || data.ratio_quantitative_analysis
			});
		} else {
			setData({ ...data, [name]: value });
		}
	};

	const assortment = value => {
		setData({ ...data, assortments_group: value });
	};

	const warehouse = value => {
		setData({ ...data, warehouses_group: value });
	};

	return (
		<>
			<Breadcrumbs
				list={['Analizy', { label: 'Analiza zapasów', path: PATHS.StockAnalyzeList }, 'Przeprowadź nową']}
				back_url={PATHS.StockAnalyzeList}
			/>

			<Loader loading={loading}>
				<SingleDetail title="Dane dotyczące analizy" handleSave={handleSave}>
					<React.Fragment>
						<FormInput
							title="Nazwa analizy"
							name="name"
							type="input"
							value={data.name}
							handleChange={handleChange}
						/>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography style={{ marginTop: 20 }}>Zakres od</Typography>
								<DatePicker
									value={data.range_from}
									onChange={v => setData({ ...data, range_from: v })}
								/>
							</Grid>
							<Grid item xs={6}>
								<Typography style={{ marginTop: 20 }}>Zakres do</Typography>
								<DatePicker
									value={data.range_to}
									onChange={v => setData({ ...data, range_to: v })}
								/>
							</Grid>
						</Grid>
						<FormInput
							title="Opis"
							name="description"
							type="area"
							value={data.description}
							handleChange={handleChange}
						/>

						<Typography style={{ marginTop: 20 }}>Grupa asortymentowa</Typography>
						<AutocompleteCheckboxed
							data={assortmentsGroups}
							value={data.assortments_group}
							label="Wpisz nazwę grupy..."
							name="assortments_group"
							handleChange={assortment}
						/>

						<Typography style={{ marginTop: 20 }}>Grupa magazynów</Typography>
						<AutocompleteCheckboxed
							data={warehouseList}
							value={data.warehouses_group}
							handleChange={warehouse}
							name="warehouse_list"
							label="Wybierz grupę"
						/>

						<FormInput
							title="Analiza struktury"
							name="structure_analysis"
							type="check_box"
							value={data.structure_analysis}
							handleChange={handleChange}
						/>
						<Box style={{ paddingLeft: 50 }}>
							<FormInput
								title="Analiza ilościowa"
								name="structure_quantitative_analysis"
								type="check_box"
								value={data.structure_quantitative_analysis}
								handleChange={handleChange}
							/>
							<FormInput
								title="Analiza wartościowa"
								name="structure_value_analysis"
								type="check_box"
								value={data.structure_value_analysis}
								handleChange={handleChange}
							/>
						</Box>
						<FormInput
							title="Analiza wskaźnikowa"
							name="ratio_analysis"
							type="check_box"
							value={data.ratio_analysis}
							handleChange={handleChange}
						/>
						<Box style={{ paddingLeft: 50 }}>
							<FormInput
								title="Analiza ilościowa"
								name="ratio_quantitative_analysis"
								type="check_box"
								value={data.ratio_quantitative_analysis}
								handleChange={handleChange}
							/>
							<FormInput
								title="Analiza wartościowa"
								name="ratio_value_analysis"
								type="check_box"
								value={data.ratio_value_analysis}
								handleChange={handleChange}
							/>
						</Box>
						<FormInput
							title="Analiza kosztów"
							name="cost_analysis"
							type="check_box"
							value={data.cost_analysis}
							handleChange={handleChange}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default StockAnalyze;
