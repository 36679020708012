/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import PATHS from './paths';

import {
	AnalizeXyz, AnalizeXyzList, AnalizeXyzResult,
	Assortment,AssortmentList,
	AssortmentGroup, AssortmentGroupList,
	Contractor, ContractorList,
	DocumentPZ,	DocumentPZList,
	DocumentWZ,	DocumentWZList,
	Invoice, InvoiceList,
	Forgot,
	MeasurementUnit, MeasurementUnitList,
	MyCompany,
	NotFound,
	Order, OrderList,
	Profile,
	ProfileInvoiceList,
	ResetPassword,
	SignIn,
	SignUp,
	StockAnalize,	StockAnalizeList, StockAnalizeResult,
	SubscriptionPlans, SubscriptionOrder,
	ValidateUser,
	Warehouse, WarehouseList,
	WarehouseGroup, WarehouseGroupList,
	WarehouseOperation, WarehouseOperationList,
	WarehouseOperationGenerateGraph,
	WarehouseOperationGraph,
	Welcome,
	PaymentStatus,
	PriceList,
	AdminUser, AdminUserList,
	AdminPaymentList,
	AdminSubscriptionList,
	AdminPlan, AdminPlanList,
	AdminInvoice, AdminInvoiceList,
} from 'views';

const LOGGED_IN_ROUTES = [
	{
		component: PaymentStatus,
		path: PATHS.PaymentStatus,
	},
	{
		component: Welcome,
		title: 'Kokpit',
		path: PATHS.Welcome,
	},
	{
		component: SubscriptionPlans,
		title: 'Plany abonamentowe',
		path: PATHS.SubscriptionPlanList,
	},
	{
		component: SubscriptionOrder,
		title: 'Wybierz abonament',
		path: PATHS.SubscriptionPlan(),
	},
	{
		component: AdminUserList,
		title: 'Użytkownicy',
		path: PATHS.AdminUserList,
	},
	{
		component: AdminUser,
		title: 'Użytkownicy',
		path: PATHS.AdminUserCreate,
	},
	{
		component: AdminUser,
		title: 'Użytkownicy',
		path: PATHS.AdminUserEdit(),
	},
	{
		component: AdminInvoiceList,
		title: 'Faktury',
		path: PATHS.AdminInvoiceList,
	},
	{
		component: AdminInvoice,
		title: 'Edycja faktury',
		path: PATHS.AdminInvoiceEdit(),
	},
	{
		component: AdminInvoice,
		title: 'Dodawanie faktury',
		path: PATHS.AdminInvoiceCreate,
	},
	{
		component: AdminPlanList,
		title: 'Plany abonamentowe',
		path: PATHS.AdminPlanList,
	},
	{
		component: AdminPlan,
		title: 'Dodawanie planu abonamentowego',
		path: PATHS.AdminPlanCreate,
	},
	{
		component: AdminPlan,
		title: 'Edycja planu abonamentowego',
		path: PATHS.AdminPlanEdit(),
	},

	{
		component: AdminSubscriptionList,
		title: 'Abonamenty',
		path: PATHS.AdminSubscriptionList,
	},
	{
		component: AdminPaymentList,
		title: 'Płatności',
		path: PATHS.AdminPaymentList,
	},
];

const SUBSCRIBER_ROUTES = [
	{
		component: MyCompany,
		title: 'Moja firma',
		path: PATHS.MyCompany,
	},
	{
		component: AssortmentList,
		title: 'Lista Asortymentów',
		path: PATHS.AssortmentList,
	},
	{
		component: Assortment,
		title: 'Dodawanie Asortymentu',
		path: PATHS.AssortmentCreate,
	},
	{
		component: Assortment,
		title: 'Edycja Asortymentu',
		path: PATHS.AssortmentEdit(),
	},
	{
		component: AssortmentGroupList,
		title: 'Lista Grup Asortymentowych',
		path: PATHS.AssortmentGroupList,
	},
	{
		component: AssortmentGroup,
		title: 'Dodawanie Grupy Asortymentów',
		path: PATHS.AssortmentGroupCreate,
	},
	{
		component: AssortmentGroup,
		title: 'Edycja Grupy Asortymentów',
		path: PATHS.AssortmentGroupEdit(),
	},
	{
		component: WarehouseList,
		title: 'Lista Magazynów',
		path: PATHS.WarehouseList,
	},
	{
		component: Warehouse,
		title: 'Dodawanie magazynu',
		path: PATHS.WarehouseCreate,
	},
	{
		component: Warehouse,
		title: 'Edycja magazynu',
		path: PATHS.WarehouseEdit(),
	},
	{
		component: WarehouseGroupList,
		title: 'Lista Grup Magazynów',
		path: PATHS.WarehouseGroupList,
	},
	{
		component: WarehouseGroup,
		title: 'Dodawanie Grupy Magazynów',
		path: PATHS.WarehouseGroupCreate,
	},
	{
		component: WarehouseGroup,
		title: 'Edycja Grupy Magazynów',
		path: PATHS.WarehouseGroupEdit(),
	},
	{
		component: ContractorList,
		title: 'Lista Kontrahentów',
		path: PATHS.ContractorList,
	},
	{
		component: Contractor,
		title: 'Dodawanie Kontrahenta',
		path: PATHS.ContractorCreate,
	},
	{
		component: Contractor,
		title: 'Edycja Kontrahenta',
		path: PATHS.ContractorEdit(),
	},
	{
		component: MeasurementUnitList,
		title: 'Lista Jednostek Miary',
		path: PATHS.MeasurementUnitList,
	},
	{
		component: MeasurementUnit,
		title: 'Dodawanie Jednostki Miary',
		path: PATHS.MeasurementUnitCreate,
	},
	{
		component: MeasurementUnit,
		title: 'Edycja Jednostki Miary',
		path: PATHS.MeasurementUnitEdit(),
	},
	{
		component: WarehouseOperationList,
		title: 'Lista Operacji Magazynowych',
		path: PATHS.WarehouseOperationList,
	},
	{
		component: WarehouseOperation,
		title: 'Dodawanie operacji magazynowej',
		path: PATHS.WarehouseOperationCreate,
	},
	{
		component: WarehouseOperation,
		title: 'Edycja operacji magazynowej',
		path: PATHS.WarehouseOperationEdit(),
	},
	{
		component: WarehouseOperationGenerateGraph,
		title: 'Generuj wykres',
		path: PATHS.WarehouseOperationGenerate,
	},
	{
		component: WarehouseOperationGraph,
		title: 'Generuj wykres',
		path: PATHS.WarehouseOperationGraph,
	},
	{
		component: OrderList,
		title: 'Lista Zamówień',
		path: PATHS.OrderList,
	},
	{
		component: Order,
		title: 'Dodawanie dokumentu',
		path: PATHS.OrderCreate,
	},
	{
		component: Order,
		title: 'Edycja dokumentu',
		path: PATHS.OrderEdit(),
	},
	{
		component: DocumentPZList,
		title: 'Lista Przyjęć',
		path: PATHS.DocumentPZList,
	},
	{
		component: DocumentPZ,
		title: 'Dodawanie dokumentu',
		path: PATHS.DocumentPZCreate,
	},
	{
		component: DocumentPZ,
		title: 'Edycja dokumentu',
		path: PATHS.DocumentPZEdit(),
	},
	{
		component: DocumentWZList,
		title: 'Lista Wydań',
		path: PATHS.DocumentWZList,
	},
	{
		component: DocumentWZ,
		title: 'Dodawanie dokumentu',
		path: PATHS.DocumentWZCreate,
	},
	{
		component: DocumentWZ,
		title: 'Edycja dokumentu',
		path: PATHS.DocumentWZEdit(),
	},
	{
		component: InvoiceList,
		title: 'Lista Faktur',
		path: PATHS.InvoiceList,
	},
	{
		component: Invoice,
		title: 'Dodawanie dokumentu',
		path: PATHS.InvoiceCreate,
	},
	{
		component: Invoice,
		title: 'Edycja dokumentu',
		path: PATHS.InvoiceEdit(),
	},
	{
		component: AnalizeXyzList,
		title: 'Analizy ABC i XYZ',
		path: PATHS.AnalyzeXyzList,
	},
	{
		component: AnalizeXyz,
		title: 'Dodawanie analizy ABC i XYZ',
		path: PATHS.AnalyzeXyzCreate,
	},
	{
		component: AnalizeXyzResult,
		title: 'Wyniki analizy ABC i XYZ',
		path: PATHS.AnalyzeXyzResult(),
	},
	{
		component: StockAnalizeList,
		title: 'Analizy zapasów',
		path: PATHS.StockAnalyzeList,
	},
	{
		component: StockAnalize,
		title: 'Dodawanie analizy zapasów',
		path: PATHS.StockAnalyzeCreate,
	},
	{
		component: StockAnalizeResult,
		title: 'Wyniki analizy zapasów',
		path: PATHS.StockAnalyzeResult(),
	},
	{
		component: Profile,
		title: 'Profil',
		path: PATHS.Profile,
	},
	{
		component: ProfileInvoiceList,
		title: 'Moje faktury',
		path: PATHS.ProfileInvoiceList,
	},
];

const UNLOGGED_ROUTES = [
	{
		component: SignIn,
		title: 'Zaloguj się',
		path: PATHS.Login,
	},
	{
		component: Forgot,
		title: 'Odzyskaj hasło',
		path: PATHS.ForgotPassword,
	},
	{
		component: ResetPassword,
		title: 'Zresetuj hasło',
		path: PATHS.ResetPassword,
	},
	{
		component: SignUp,
		title: 'Zarejestruj się',
		path: PATHS.Registration,
	},
	{
		component: ValidateUser,
		path: PATHS.Verification,
	},
	{
		component: PriceList,
		path: PATHS.PriceList,
	},
];

const getRoutes = arr => arr.map((route, index) => <Route exact key={index} {...route} />);

export const getPageTitle = path => SUBSCRIBER_ROUTES.concat(LOGGED_IN_ROUTES).find(page => page.path === path)?.title;

export const LoggedRoutes = () => {
	// TODO: ADD SOME KID OF GLOBAL STATE OF USER DETAILS
	// const subscriptionValid = JSON.parse(storage.getStorage('subscriptionValid'));
	// const history = useHistory();
	// const location = useLocation();
	//
	// useEffect(() => {
	// 	if (!subscriptionValid) {
	// 		if (location.pathname.indexOf('/subscriptions') < 0) {
	// 			history.push(PATHS.SubscriptionPlans);
	// 		}
	// 	}
	// }, [location]);

	return (
		<Switch>
			<Redirect exact from="/" to={PATHS.Welcome} />
			<Redirect exact from="/login" to={PATHS.Welcome} />
			{getRoutes(SUBSCRIBER_ROUTES.concat(LOGGED_IN_ROUTES))}
			<Route component={NotFound} />
		</Switch>
	);
};

export const UnLoggedRoutes = () => {
	return <Switch>
		{getRoutes(UNLOGGED_ROUTES)}
		<Redirect to={PATHS.Login}/>
	</Switch>
};
