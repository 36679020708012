import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	main: {
		marginTop: theme.spacing(3),
	},
	title: {
		fontSize: '1.2em',
		lineHeight: '1.2',
		fontWeight: '700',
	},
	card: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(3),
		overflow: 'visible',
	},
	content: {
		paddingTop: 150,
		paddingBottom: 150,
		textAlign: 'center'
	},
}));

export default useStyles;
