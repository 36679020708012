/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, SingleDetail } from 'components';
import useStyles from './style';
import AdminPlanAPI from 'apis/AdminPlanAPI';
import PATHS from 'routes/paths';
import Loader from 'components/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import { getResponseMessage } from 'utils/functions';

const AdminPlan = () => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		axios.all([
			id ? AdminPlanAPI.get(id) : null,
		]).then(axios.spread((item) => {
			item?.data && setData(item.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		if (!data.type) {
			return addToast('Nie wybrano typu.', { appearance: 'error' });
		}
		if (!data.period) {
			return addToast('Nie wybrano okresu.', { appearance: 'error' });
		}
		if (!data.users_nb) {
			return addToast('Nie podano liczby użytkowników.', { appearance: 'error' });
		}
		if (!data.price) {
			return addToast('Nie podano ceny.', { appearance: 'error' });
		}
		if (! /^\d+$/.test(data.users_nb)) {
			return addToast('Liczba użytkowników musi być liczbą całkowitą.', { appearance: 'error' });
		}
		if (! /^\d+$/.test(data.price)) {
			return addToast('Cena musi być liczbą całkowitą.', { appearance: 'error' });
		}

		setLoading(true);
		AdminPlanAPI.updateOrCreate(data, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AdminPlanList), 100);
			} else {
				addToast(getResponseMessage(response), { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		AdminPlanAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AdminPlanList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Administracja', { label: 'Plany abonamentowe', path: PATHS.AdminPlanList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.AdminPlanList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane planu"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Typ"
							name="type"
							type="single"
							value={data.type}
							list={[
								{ id: 'personal', name: 'indywidualny' },
								{ id: 'group', name: 'grupowy' },
							]}
							handleChange={handleChange}
						/>
						<FormInput
							title="Okres"
							name="period"
							type="single"
							value={data.period}
							list={[
								{ id: 'month', name: '1 miesiąc' },
								{ id: 'semester', name: '5 miesięcy' },
								{ id: 'year', name: '12 miesięcy' },
							]}
							handleChange={handleChange}
						/>
						<FormInput
							title="Liczba użytkowników"
							name="users_nb"
							type="number"
							value={data.users_nb}
							handleChange={handleChange}
						/>
						<FormInput
							title="Cena [PLN]"
							name="price"
							type="number"
							value={data.price}
							handleChange={handleChange}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default AdminPlan;
