/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { Box } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import ChartButton from 'components/misc/ChartButton';
import Chart from 'components/Chart/ConditionalChart';
import { sortData, exportToXlsx, exportToPng, numberStringFixed } from 'utils/functions';

const GroupAmount = props => {
	const header = [
		{ id: 'assortment_group_name', label: 'Grupa' },
		{ id: 'measure_name', label: 'Jedn.miary' },
		{
			id: 'cyclical_stock',
			label: 'Zapas cykliczny ilościowo (ZC)',
			info: ['ZC=0,5*WD', 'WD - średnia dostawa']
		},
		{
			id: 'excess_stock',
			label: 'Zapas nadmieny ilościowo (ZN)',
			info: [
				'ZN=ZŚ-ZC-ZZ',
				'ZŚ - zapas średni',
				'ZC - zapas cykliczny',
				'ZZ - zapas zabezpieczający'
			]
		},
		{
			id: 'safety_stock',
			label: 'Zapas zabezp. ilościowo (ZB)',
			info: [
				'ZB = ω√(Ts2+P2*σT2)',
				'ω - wskaźnik bezpieczeństwa',
				'T - czas cyklu uzupełniania zapasu',
				's = σP - odchylenie standardowe popytu',
				'σT - odchylenie standardowe czasu uzupełnienia zapasu'
			]
		},
		{
			id: 'average_stock',
			label: 'Zapas średni całk. ilościowo (ZŚ)',
			info: ['ZŚ = Z', 'Z - średni stan zapasu']
		},
		{ id: 'action', label: '' }
	];
	const [data, setData] = useState([]);
	const [activeRow, setActiveRow] = useState({});
	const [chartData, setChartData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};

	const handleDrawChart = row => {
		setChartData([
			{ label: `ZC`, y: row.cyclical_stock },
			{ label: `ZN`, y: row.excess_stock },
			{ label: `ZB`, y: row.safety_stock }
		]);
		setActiveRow(row);
	};

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<>
			<Box mb={2}>
				<Button onClick={handleExport}>Eksportuj do XLS</Button>
				<Button
					onClick={() => exportToPng(props.chartId)}
					style={{ marginLeft: 20 }}>
					Pobierz wykres .PNG
				</Button>
			</Box>
			<Box mb={2}>
				<Table
					header={header}
					disablePagination={true}
					onSort={o => setData([...sortData(data, o.columnId, o.direction)])}>
					{data.map((row, index) => {
						return (
							<Row key={index}>
								<Cell>{row.assortment_group_name}</Cell>
								<Cell>{row.measure_name}</Cell>
								<Cell align="right">{numberStringFixed(row.cyclical_stock)}</Cell>
								<Cell align="right">{numberStringFixed(row.excess_stock)}</Cell>
								<Cell align="right">{numberStringFixed(row.safety_stock)}</Cell>
								<Cell align="right">{numberStringFixed(row.average_stock)}</Cell>
								<Cell>
									<ChartButton
										active={activeRow === row}
										onClick={() => handleDrawChart(row)}
									/>
								</Cell>
							</Row>
						);
					})}
				</Table>
			</Box>
			<Box id={props.chartId} mb={2}>
				<Chart data={chartData} />
			</Box>
		</>
	);
};

export default GroupAmount;
