import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AppBar, Button, IconButton, Menu, MenuItem, Typography} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './style';
import {useHistory} from 'react-router-dom';
import AuthContext from 'context/AuthContext';
import PATHS from 'routes/paths';

const Topbar = props => {

	const classes = useStyles();
	const history = useHistory();
	const logOut = useContext(AuthContext).logOut;

	const {className, title, onSidebarOpen, onSidebarClose, openSidebar, ...rest} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [avatarOpen, setAvatarOpen] = useState(Boolean(anchorEl));


	const onMaxTopbar = () => {
		if (openSidebar === false)
			onSidebarOpen();
		else
			onSidebarClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setAvatarOpen(false);
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
		setAvatarOpen(true);
	};

	const handleLogout = () => {
		handleClose();
		logOut();
	};

	const changeToAdmin = () => {
		history.push(PATHS.AdminContractors);
	}

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
		>
			<div className={classes.toolbar}>
				<div className={classes.titlebar}>
					<Button className={classes.close_drawer_icon} onClick={onMaxTopbar}
									aria-label={!openSidebar ? 'Rozwiń menu' : 'Zwiń menu'}
									title={!openSidebar ? 'Rozwiń menu' : 'Zwiń menu'}>
						<MenuIcon/>
					</Button>
					<Typography variant='h1' className={classes.title}>
						{title}
					</Typography>
				</div>
				<div className={classes.rightControllerArea}>
					{/*<Button size="small" color="inherit" onClick={() => changeToAdmin()}>ADMIN</Button>*/}
					<div className={classes.avatar}>
						<IconButton
							aria-label='Profil użytkownika'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleMenu}
						>
							<AccountCircle className={classes.avataricon}/>
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							open={avatarOpen}
							onClose={handleClose}
						>
							<MenuItem onClick={() => history.push(PATHS.Profile)}>Edytuj profil</MenuItem>
							<MenuItem onClick={() => history.push(PATHS.MyCompany)}>Moja firma</MenuItem>
							<MenuItem onClick={() => history.push(PATHS.ProfileInvoiceList)}>Moje faktury</MenuItem>
							<MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
						</Menu>
					</div>
				</div>
			</div>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar;
