import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import pl from "date-fns/locale/pl";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#fafafa',
		border: '1px solid #c5c5c5',
		borderRadius: 4,
		height: 35,
		paddingLeft: 5,
		boxSizing: 'initial',
		width: 'calc(100% - 10px)'
	}
}));

const MyDateRangePicker = props => {
	const classes = useStyles();
	const [dateRange, setDateRange] = useState([null, null]);

	const handleChange = update => {
		setDateRange(update);
		if (update[0] && update[1]) {
			props.onChange([
				moment(update[0]).format('YYYY-MM-DD'),
				moment(update[1]).format('YYYY-MM-DD')
			]);
		}
		if (!update[0] && !update[1]) {
			props.onChange(null);
		}
	};

	return (
		<DatePicker
			locale={pl}
			className={classes.root}
			selectsRange={true}
			dateFormat="dd/MM/yyyy"
			startDate={dateRange[0]}
			endDate={dateRange[1]}
			onChange={handleChange}
			// onChangeRaw={handleInput}
			isClearable={props.isClearable}
			placeholderText="Podaj zakres dat..."
			showPopperArrow={false}
		/>
	);
};

export default MyDateRangePicker;
