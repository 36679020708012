import API from './API';
import { getParamsByOptions } from '../utils/functions';

const AssortmentGroupAPI = {
	getInfo: () => API.get('/info', { params: { 'type': 'assortments_group' } }),
	getAsTree: () => API.get('/info', { params: { 'type': 'assortments_group_tree' } }),
	getFullInfo: () => API.get('/info', { params: { 'type': 'assortments_group_all' } }),
	get: id => API.get(`/assortments_group/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/assortments_group/${id}`, data) : API.post('/assortments_group', data),
	delete: id => API.delete(`/assortments_group/${id}`),
	index: options => API.get(`/assortments_group${getParamsByOptions(options)}`),
	export: options => API.get(`/assortments_group/export${getParamsByOptions(options)}`),
	create_list: data => API.post('/assortments_group/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/assortments_group/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default AssortmentGroupAPI;
