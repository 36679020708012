import API from './API';

const AnalyzeAbcAPI = {
	index: () => API.get('/analyzes'),
	get: id => API.get(`/analyzes/${id}`),
	create: data => API.post('/analyzes', data),
	delete: id => API.delete(`/analyzes/${id}`)
};

export default AnalyzeAbcAPI;
