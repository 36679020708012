import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { FormInput, Loader, SingleDetail } from 'components';
import { Grid } from '@material-ui/core';
import UserAPI from 'apis/UserAPI';

const MyCompany = () => {
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		company_name: '',
		street: '',
		building_number: '',
		apartment_number: '',
		post_office: '',
		postal_code: '',
		city: '',
		country: 'Polska',
		nip: '',
		regon: '',
		bank_name: '',
		bank_account_number: ''
	});

	useEffect(() => {
		UserAPI.getCompany().then(res => {
			res.code === 200 && setData(res.data);
			setLoading(false);
		});
	}, []);

	const handleSave = () => {
		setLoading(true);
		UserAPI.updateCompany(data).then(res => {
			res.code === 200 &&
			res.message &&
			addToast(res.message, {
				appearance: 'success',
				autoDismissTimeout: 1500,
				autoDismiss: true
			});
			setLoading(false);
		});
	};

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	return (
		<Loader loading={loading}>
			Moja firma
			<SingleDetail title="" handleSave={handleSave}>
				<React.Fragment>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<h4>Dane</h4>
						</Grid>
						<Grid item xs={9}>
							<FormInput
								title="Nazwa firmy"
								name="company_name"
								type="input"
								value={data.company_name}
								handleChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<h4>Adres</h4>
						</Grid>
						<Grid item xs={9}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormInput
										title="Ulica"
										name="street"
										type="input"
										value={data.street}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={3}>
									<FormInput
										title="Nr budynku"
										name="building_number"
										type="input"
										value={data.building_number}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={3}>
									<FormInput
										title="Nr lokalu"
										name="apartment_number"
										type="input"
										value={data.apartment_number}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										title="Poczta"
										name="post_office"
										type="input"
										value={data.post_office}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={3}>
									<FormInput
										title="Kod pocztowy"
										name="postal_code"
										type="input"
										value={data.postal_code}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										title="Miejscowość"
										name="city"
										type="input"
										value={data.city}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										title="Państwo"
										name="country"
										type="input"
										value={data.country}
										handleChange={handleChange}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={3}>
							<h4>Inne</h4>
						</Grid>
						<Grid item xs={9}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormInput
										title="NIP"
										name="nip"
										type="input"
										value={data.nip}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										title="REGON"
										name="regon"
										type="input"
										value={data.regon}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										title="Bank"
										name="bank_name"
										type="input"
										value={data.bank_name}
										handleChange={handleChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										title="Numer podstawowego rachunku bankowego"
										name="bank_account_number"
										type="input"
										value={data.bank_account_number}
										handleChange={handleChange}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</React.Fragment>
			</SingleDetail>
		</Loader>
	);
};

export default MyCompany;
