import API from './API';
import { getParamsByOptions } from '../utils/functions';

const InvoiceAPI = {
	getInfo: () => API.get('/info', { params: { type: 'document' } }),
	index: params => API.get('/invoices', { params }),
	index: options => API.get('/invoices' + getParamsByOptions(options)),
	getAll: () => API.get('/invoices/all'),
	get: id => API.get(`/invoices/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/invoices/${id}`, data) : API.post('/invoices', data),
	delete: id => API.delete(`/invoices/${id}`),
	export: options => API.get('/invoices/export' + getParamsByOptions(options)),
	import: data => API.post('/invoices/list', data)
};

export default InvoiceAPI;
