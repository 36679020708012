import React from 'react';

import { IconButton } from '@material-ui/core';
// import DonutSmallOutlinedIcon from '@material-ui/icons/DonutSmallOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';

import useGlobalStyles from '../../assets/style/styles';

const ChartButton = props => {
	const { active, ...rest } = props;
	const globalClasses = useGlobalStyles();
	return (
		<IconButton
			className={
				active ? globalClasses.greenIconButton : globalClasses.iconButton
			}
			{...rest}
			style={{ width: 40, height: 40 }}>
			<EqualizerOutlinedIcon className={globalClasses.icon} />
		</IconButton>
	);
};

export default ChartButton;
