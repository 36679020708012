import React from 'react';

import { IconButton } from '@material-ui/core';
import { ReactComponent as AnalyticsIcon } from './../../svg/icons/analytics.svg';
import useGlobalStyles from '../../assets/style/styles';

const DetailButton = props => {
	const globalClasses = useGlobalStyles();
	return (
		<IconButton className={globalClasses.iconButton} {...props}>
			<AnalyticsIcon />
		</IconButton>
	);
};

export default DetailButton;
