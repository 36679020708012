/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumbs } from 'components';
import PATHS from 'routes/paths';
import { ProfileForm } from 'views/Profile';
import { useParams } from 'react-router-dom';

const AdminUser = () => {
	const { id } = useParams();

	return <>
		<Breadcrumbs
			list={['Admin', { label: 'Użytkownicy', path: PATHS.AdminUserList }, id ? 'Edytuj' : 'Dodaj']}
			back_url={PATHS.AdminUserList}
		/>
		<ProfileForm forAdmin />
	</>
};

export default AdminUser;
