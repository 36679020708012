/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import uniqid from 'uniqid';
import { Box } from '@material-ui/core';
import Collapse from 'components/Collapse/Collapse';
import StockTableTabs from 'components/misc/StockTableTabs';
import AssortmentAmount from './AssortmentAmount';
import AssortmentPercent from './AssortmentPercent';
import GroupAmount from './GroupAmount';
import GroupPercent from './GroupPercent';

const QuantitativeStructure = props => {
	const [view, setView] = useState({ assortment: 'amount', group: 'amount' });

	return (
		<>
			<Collapse
				defaultOpen
				title="Analiza struktury zapasu ilościowa - ujęcie asortymentowe"
				content={
					<>
						<StockTableTabs
							view={view.assortment}
							onChange={v => setView({ ...view, assortment: v })}
						/>
						<Box style={{ paddingTop: 10 }}>
							{view.assortment === 'amount' && (
								<AssortmentAmount
									chartId={uniqid()}
									data={props.data?.assortment_shot}
								/>
							)}
							{view.assortment === 'percent' && (
								<AssortmentPercent
									chartId={uniqid()}
									data={props.data?.assortment_shot}
								/>
							)}
						</Box>
					</>
				}
			/>

			<Collapse
				defaultOpen
				title="Analiza struktury zapasu ilościowa - ujęcie grupowe"
				content={
					<>
						<StockTableTabs
							view={view.group}
							onChange={v => setView({ ...view, group: v })}
						/>
						<Box style={{ paddingTop: 10 }}>
							{view.group === 'amount' && (
								<GroupAmount chartId={uniqid()} data={props.data?.group_shot} />
							)}
							{view.group === 'percent' && (
								<GroupPercent
									chartId={uniqid()}
									data={props.data?.group_shot}
								/>
							)}
						</Box>
					</>
				}
			/>
		</>
	);
};

export default QuantitativeStructure;
