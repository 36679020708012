import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import OutlineButton from 'components/OutlineButton';
import useGlobalStyles from 'assets/style/styles';
import useStyles from './style';

const SingleDetail = props => {
	const { title, type, handleSave, handleDelete, children, hideButton = false, fullWidth = false } = props;
	const global_classes = useGlobalStyles();

	const classes = useStyles();
	return (
		<Grid container spacing={3} className={classes.main}>
			<Grid item xs={12} md={fullWidth ? 12 : 9}>
				<Card className={classes.card}>
					<Grid container spacing={2}>
						{title && (
							<Grid item xs={12} md={3}>
								<Typography variant="h2" className={classes.title}>
									{title}
								</Typography>
							</Grid>
						)}
						<Grid item xs={12} md={title ? 9 : 12}>
							{React.cloneElement(children)}
						</Grid>
					</Grid>
				</Card>
			</Grid>
			{!hideButton && (
				<Grid item xs={12} md={3}>
					<Card className={classes.card}>
						<Grid
							container
							spacing={2}
							justifyContent={type === 'edit' ? 'space-around' : 'center'}>
							<Grid item xs={type === 'edit' ? 6 : 12}>
								<OutlineButton
									title={type === 'edit' ? 'Zapisz zmiany' : 'Zapisz'}
									onClick={handleSave}
									parent_class={global_classes.full_fill}
								/>
							</Grid>
							{type === 'edit' && (
								<Grid item xs={6}>
									<OutlineButton
										title="Usuń"
										onClick={handleDelete}
										parent_class={global_classes.full_fill}
									/>
								</Grid>
							)}
						</Grid>
					</Card>
				</Grid>
			)}
		</Grid>
	);
};

export default SingleDetail;
