import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGlobalStyles from 'assets/style/styles';
import { OutlineButton, SingleDetail, FormInput } from 'components';
import {
	Grid,
	Typography,
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import ProfileDetails, { getMissingFields } from './ProfileDetails';
import UserAPI from 'apis/UserAPI';
import SubscriptionPlan from 'apis/SubscriptionPlanAPI';
import useStyles from './style';
import Loader from 'components/Loader';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import AdminUserAPI from 'apis/AdminUserAPI';
import { getResponseMessage } from 'utils/functions';
import DatePicker from 'components/FormInput/MyDatePicker';
import PATHS from 'routes/paths';
import { validatePassword } from 'helpers/form';
import { getRole } from 'apis/AuthAPI';

const ProfileForm = props => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);

	const role = getRole();
	const isSuperAdmin = role === 'superadmin';
	const isManagedUser = role === 'managed_user';

	const [data, setData] = useState(isSuperAdmin ? {
		is_active: true,
		plan_id: '',
	} : { plan_id: '' });

	const classes = useStyles();
	const global_classes = useGlobalStyles();

	const { profileEdit = false, forAdmin = false } = props;

	const [password, setPassword] = useState({ old: '', new1: '', new2: '' });
	const [pwdError, setPwdError] = useState(false);

	const [plans, setPlans] = useState([]);

	useEffect(() => {
		axios.all([
			forAdmin ? (id ? AdminUserAPI.get(id) : null) : UserAPI.get(),
			SubscriptionPlan.getInfo(),
		]).then(axios.spread((user, plans) => {
			user?.data && setData({ ...user.data, valid_until: user.data.subscription?.valid_until, plan_id: user.data.plan_id || '' });
			plans?.data && setPlans(plans.data); // .map(v => ({ id: v.id, value: v.id, label: v.name }))

			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		const missing = getMissingFields(data, profileEdit);
		if (missing) {
			missing.forEach(f => {
				addToast('Pole "' + f + '" jest wymagane.', {
					appearance: 'error',
					autoDismissTimeout: 10000,
					autoDismiss: true,
				});
			});
			return;
		}

		if (isSuperAdmin && data.plan_id && !data.valid_until) {
			addToast('Należy podać termin ważności abonamentu.', {
				appearance: 'error',
				autoDismissTimeout: 10000,
				autoDismiss: true,
			});
			return;
		}

		if ((password.new1 || password.new2) && !validatePasswords()) {
			return;
		}

		setLoading(true);
		(forAdmin ? AdminUserAPI.updateOrCreate(data, id) : UserAPI.update(data)).then(response => {
			addToast(getResponseMessage(response) || 'Nieoczekiwany błąd', {
				appearance: [200, 201].includes(response.code) ? 'success' : 'error',
				autoDismissTimeout: 10000,
				autoDismiss: true
			});
			if (forAdmin && [200, 201].includes(response.code)) {
				setTimeout(() => history.push(PATHS.AdminUserList), 100);
			}
			setLoading(false);
		});
	};

	// const handleDelete = okBtn => {
	// 	if (okBtn) {
	// 		UserAPI.sendUserEmailToRemoveSubscription(toRemove).then(res => {
	// 			if (res.code === 200) window.location.reload();
	// 		});
	// 	} else {
	// 		setDeleteId(false);
	// 	}
	// };

	const validatePasswords = () => {
		const error = validatePassword(password.new1);
		if (error) {
			addToast(error, { appearance: 'error' });
			setPwdError(true);
			return false;
		}
		if (password.new1 !== password.new2) {
			addToast('Źle powtórzone hasło.', { appearance: 'error' });
			setPwdError(true);
			return false;
		}
		return true;
	};

	const changePassword = () => {
		if (
			(profileEdit && !password.old.length) ||
			!password.new1.length ||
			!password.new2.length
		) {
			setPwdError(true);
			addToast('Należy wypełnić wszystkie pola z hasłem.', { appearance: 'error' });
			return;
		}
		if (!validatePasswords()) {
			return;
		}

		(id ? AdminUserAPI.updatePassword({ password: password.new1 }, id) : UserAPI.updatePassword({
			old_password: password.old,
			password: password.new1
		})).then(response => {
			addToast(getResponseMessage(response), { appearance: response.code === 200 ? 'success' : 'error' });
			if (response.code === 200) {
				setPassword({ old: '', new1: '', new2: '' });
			}
		});
	};

	return (
		<Loader loading={loading}>
			<SingleDetail handleSave={handleSave}>
				<>
					<Grid container spacing={2}>
						<Grid item xs={3} style={{ paddingTop: 10 }}>
							<Typography variant="h4">Konto</Typography>
						</Grid>
						<Grid item xs={9}>
							<Grid item container xs={6} spacing={1}>
								<Grid item xs={12}>
									<FormInput
										disabled={profileEdit}
										title="E-mail"
										name="email"
										type="input"
										value={data.email}
										handleChange={handleChange}
										autoComplete="off"
									/>
								</Grid>
							</Grid>

							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormInput
										required
										title="Imię"
										name="first_name"
										type="input"
										value={data.first_name}
										handleChange={handleChange}
										autoComplete="given-name"
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInput
										required
										title="Nazwisko"
										name="last_name"
										type="input"
										value={data.last_name}
										handleChange={handleChange}
										autoComplete="family-name"
									/>
								</Grid>
							</Grid>

							{isSuperAdmin && (
								<Grid container spacing={2}>
									<Grid item xs={3}>
										<FormInput
											title="Admin"
											name="is_superadmin"
											type="check_box"
											value={data.is_superadmin}
											handleChange={handleChange}
											autoComplete="given-name"
										/>
									</Grid>
									<Grid item xs={3}>
										<FormInput
											disabled={!!data.parent_id}
											title="Abonament"
											name="is_billable"
											type="check_box"
											value={data.is_billable}
											handleChange={handleChange}
											autoComplete="family-name"
										/>
									</Grid>
									<Grid item xs={3}>
										<FormInput
											title="Aktywny"
											name="is_active"
											type="check_box"
											value={data.is_active}
											handleChange={handleChange}
											autoComplete="family-name"
										/>
									</Grid>
									<Grid item xs={3}>
										<FormInput
											title="Email potwierdzony"
											name="is_confirmed"
											type="check_box"
											value={data.is_confirmed}
											handleChange={handleChange}
											autoComplete="family-name"
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>

					{!isManagedUser && (
						<Grid container spacing={2} className={classes.lineTop}>
							<Grid item xs={3} style={{ paddingTop: 10 }}>
								<Typography variant="h4">Abonament</Typography>
							</Grid>
							{isSuperAdmin && data.parent && (<>
								<Grid item xs={9}>
									<Typography>
										Zarządzający grupą:
										     
										<strong>{data.parent?.full_name}</strong>
										     
										{data.parent?.email}
									</Typography>
								</Grid>
								<Grid item xs={3}></Grid>
							</>)}
							<Grid item xs={4}>
								<Select
									isDisabled={!isSuperAdmin || !data.is_billable || data.parent_id}
									placeholder={data.is_billable ? 'Nie opłacony' : 'Nie dotyczy'}
									value={plans.find(v => v.id === data.plan_id)}
									options={plans}
									getOptionLabel={option => option.name}
									getOptionValue={option => option.id}
									isClearable={true}
									menuPlacement="auto"
									onChange={(v, field) => handleChange('plan_id', v?.id || '')}
								/>
							</Grid>
							{data.plan_id && <>
								<Grid item xs={5} style={{ display: 'flex' }}>
									<Typography className={classes.dateHint}>ważny do:</Typography>
									<DatePicker
										dateFormat="yyyy-MM-dd"
										disabled={!isSuperAdmin || !data.is_billable || data.parent_id}
										style={{width: 'auto'}}
										// selected={data.subscription?.valid_until ? undefined : null}
										isClearable={isSuperAdmin && data.is_billable && !data.parent_id}
										placeholderText="Termin ważności"
										value={data.valid_until}
										onChange={v => handleChange('valid_until', v)}
									/>
								</Grid>
							</>}
						</Grid>
					)}

					{data.id && (<>
						<Grid container spacing={2} className={classes.lineTop}>
							<Grid item xs={3} style={{ paddingTop: 10 }}>
								<Typography variant="h4">Hasło</Typography>
							</Grid>
							<Grid item xs={9}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										{profileEdit && <>
											<input type="text" value="" style={{ display: 'none' }} onChange={() => {}}/> {/* browser autocomplete workaround */}
											<FormInput
												title="Obecne hasło"
												type="password"
												error={pwdError}
												autoComplete="old-pwd"
												onFocus={() => setPwdError(false)}
												onChange={e =>
													setPassword({ ...password, old: e.target.value })
												}
												value={password.old}
											/>
										</>}
									</Grid>
									<Grid item container xs={6} alignItems="flex-end" justifyContent="flex-end">
										<OutlineButton
											title="Zmień hasło"
											onClick={changePassword}
											parent_class={global_classes.full_fill}
											style={{ maxWidth: 150 }}
										/>
									</Grid>
									<Grid item xs={6}>
										<FormInput
											title="Nowe hasło"
											type="password"
											error={pwdError}
											onFocus={() => setPwdError(false)}
											onChange={e => setPassword({ ...password, new1: e.target.value })}
											value={password.new1}
										/>
									</Grid>
									<Grid item xs={6}>
										<FormInput
											title="Powtórz nowe hasło"
											type="password"
											error={pwdError}
											onFocus={() => setPwdError(false)}
											onChange={e => setPassword({ ...password, new2: e.target.value })}
											value={password.new2}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>)}

					{/*{deleteId && (*/}
					{/*	<Dialog*/}
					{/*		className={global_classes.dialog}*/}
					{/*		open={deleteId}*/}
					{/*		onClose={() => handleDelete(false)}*/}
					{/*		aria-labelledby="alert-dialog-title"*/}
					{/*		aria-describedby="alert-dialog-description">*/}
					{/*		<DialogTitle id="alert-dialog-title">Potwierdź!</DialogTitle>*/}
					{/*		<DialogContent>*/}
					{/*			<DialogContentText id="alert-dialog-description">*/}
					{/*				Czy na pewno chcesz tego użytkownika usunąć?*/}
					{/*			</DialogContentText>*/}
					{/*		</DialogContent>*/}
					{/*		<DialogActions>*/}
					{/*			<Button*/}
					{/*				variant="contained"*/}
					{/*				color="primary"*/}
					{/*				onClick={() => handleDelete(true)}>*/}
					{/*				Tak*/}
					{/*			</Button>*/}
					{/*			<Button variant="outlined" onClick={() => handleDelete(false)}>*/}
					{/*				Nie*/}
					{/*			</Button>*/}
					{/*		</DialogActions>*/}
					{/*	</Dialog>*/}
					{/*)}*/}
				</>
			</SingleDetail>

			{(isSuperAdmin || profileEdit) && (
				<ProfileDetails data={data} handleChange={handleChange} hideUsernames forAdmin={forAdmin} />
			)}
		</Loader>
	);
};

ProfileForm.propTypes = {
	profileEdit: PropTypes.bool,
}

export default ProfileForm;
