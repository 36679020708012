/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import { numberString, numberStringFixed } from 'utils/functions';

const useStyles = makeStyles(theme => ({
	container: {
		border: '1px solid lightgray',
	}
}));

const PreviewTable = props => {
	const { assortments } = props;

	const classes = useStyles();

	const header = [
		{ id: 'no', label: 'Lp.', disableSort: true },
		{ id: 'name', label: 'Nazwa towaru', disableSort: true },
		{ id: 'code', label: 'Kod towaru', disableSort: true },
		{ id: 'symbol', label: 'Symbol klas.', disableSort: true },
		{ id: 'measure_unit', label: 'Jednostka m.', disableSort: true },
		{ id: 'quantity', label: 'Ilość', disableSort: true, align: 'right' },
		{ id: 'price', label: 'Cena jedn.', disableSort: true, align: 'right' },
		{ id: 'value', label: 'Wartość', disableSort: true, align: 'right' },
		{ id: 'tax_rate', label: 'Stawka podatku [%]', disableSort: true, align: 'right' },
		{ id: 'tax_amount', label: 'Kwota podatku', disableSort: true, align: 'right' },
		{ id: 'value', label: 'Wartość z podatkiem', disableSort: true, align: 'right' },
	];

	return (
		<div className={classes.container}>
			<Table rows={assortments} header={header} disablePagination={true}>
				{assortments.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{index + 1}</Cell>
								<Cell>{row.assortment?.name}</Cell>
								<Cell>{row.assortment?.gtin}</Cell>
								<Cell>{row.symbol}</Cell>
								<Cell>{row.assortment?.measure_name}</Cell>
								<Cell align="right">{numberString(row.quantity)}</Cell>
								<Cell align="right">{numberStringFixed(row.price_nett)}</Cell>
								<Cell align="right">{numberStringFixed(row.value_nett)}</Cell>
								<Cell align="right">{numberString(row.tax_rate)}</Cell>
								<Cell align="right">{numberStringFixed(row.tax_value)}</Cell>
								<Cell align="right">{numberStringFixed(row.price_gross)}</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</div>
	);
};

export default PreviewTable;
