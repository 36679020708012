import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = props => {
	const { data } = props;

	const colors = [
		'#44d62c',
		'#7fc2e4',
		'#0085ca',
		'#ef3340',
		'#000000',
		'#008578',
		'#f7999f',
		'#963cbd',
		'#d0df00',
		'#ff7fcb'
	];

	const chartData = {
		labels: data.map(i => i.label),
		datasets: [
			{
				label: '',
				data: data.map(i => i.y),
				backgroundColor: data.map(
					(item, index) => colors[index % colors.length]
				),
				borderColor: data.map((item, index) => colors[index % colors.length]),
				borderWidth: 1
			}
		]
	};

	return (
		<>
			{data.length > 0 && (
				<div
					id={props.id}
					style={{
						height: 400,
						margin: '0 auto',
						backgroundColor: 'white'
					}}>
					<Pie
						data={chartData}
						options={{
							responsive: true,
							maintainAspectRatio: false
						}}
					/>
				</div>
			)}
		</>
	);
};

export default Chart;
