/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, Loader, SingleDetail } from 'components';
import { Grid } from '@material-ui/core';
import AssortmentGroupAPI from 'apis/AssortmentGroupAPI';
import PATHS from 'routes/paths';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';

const AssortmentGroup = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [listInfo, setListInfo] = useState({});

	useEffect(() => {
		axios.all([
			id ? AssortmentGroupAPI.get(id) : null,
			AssortmentGroupAPI.getInfo(),
		]).then(axios.spread((item, info) => {
			item?.data && setData(item.data);
			info?.data && setListInfo(info.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		setLoading(true);
		AssortmentGroupAPI.updateOrCreate(data, id).then(response => {
			if ([200,201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AssortmentGroupList), 100);
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		AssortmentGroupAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AssortmentGroupList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Dane podstawowe', { label: 'Grupy asortymentowe', path: PATHS.AssortmentGroupList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.AssortmentGroupList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane dotyczące grupy asortymentowej"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Nazwa grupy"
							name="name"
							type="input"
							value={data.name}
							handleChange={handleChange}
						/>
						<FormInput
							title="Grupa główna"
							name="is_main_group"
							type="check_box"
							value={data.is_main_group}
							handleChange={handleChange}
						/>
						<Grid container spacing={2}>
							{data.is_main_group !== true && data.is_main_group !== 1 && (
								<Grid item xs={6}>
									<FormInput
										title="Grupa główna"
										name="main_group"
										type="single"
										value={data.main_group}
										list={listInfo?.assortments_groups}
										handleChange={handleChange}
									/>
								</Grid>
							)}
							<Grid item xs={6}>
								<FormInput
									title="Kod"
									name="code"
									type="input"
									value={data.code}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<FormInput
							title="Opis"
							name="description"
							type="area"
							value={data.description}
							handleChange={handleChange}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default AssortmentGroup;
