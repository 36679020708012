/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Table, Row, Cell } from 'components/MyTable';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import CheckMark from 'components/misc/CheckMark';

import WarehouseGroupAPI from 'apis/WarehouseGroupAPI';
import { warehouse_group_header } from 'utils/xlsx_headers';
import { exportToXlsx } from 'utils/functions';
import { useToasts } from 'react-toast-notifications';
import useStyles from './style';

const WarehouseGroupList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const header = [
		{ id: 'name', label: 'Nazwa grupy', filter: 'text' },
		{ id: 'associated_warehouse', label: 'Przynależne magazyny', disableSort: true },
		{ id: 'description', label: 'Opis', filter: 'text' },
		{
			id: 'active',
			filter: 'select',
			label: 'Aktywny',
			align: 'center',
			list: [
				{ id: 0, name: 'Nie' },
				{ id: 1, name: 'Tak' }
			]
		},
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	];
	const handleEdit = id => {
		history.push(PATHS.WarehouseGroupEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			WarehouseGroupAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		WarehouseGroupAPI.create_list(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		WarehouseGroupAPI.export(options).then(response => {
			if (response.code === 200) {
				exportToXlsx(warehouse_group_header, response.data, 'Grupy magazynów');
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		WarehouseGroupAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nową grupę',
					path: PATHS.WarehouseGroupCreate
				}}
				importHandler={handleImport}
				exportHandler={handleExport}
				header_list={warehouse_group_header}
			/>

			<Breadcrumbs list={['Dane podstawowe', 'Grupy Magazynów']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell width={250}>{row.name}</Cell>
								<Cell width={300}>{row.warehouses.map(i=>i.warehouse?.name).join(', ')}</Cell>
								<Cell>{row.description}</Cell>
								<Cell width={100} align="center">
									<CheckMark checked={row.active} />
								</Cell>
								<Cell width={120} align="center">
									<EditButton onClick={() => handleEdit(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default WarehouseGroupList;
