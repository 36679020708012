/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Autocomplete from 'components/FormInput/Autocomplete';
import { DocumentTableTr } from 'components/document/DocumentTable';
import { getPriceZlAndGr } from 'utils/functions';
import { groupByFirstLetter } from 'utils/functions';
import { useToasts } from 'react-toast-notifications';

export default function DocumentWZCreateTableRow(props) {
	const { addToast } = useToasts();
	const {
		WZ = {},
		row,
		index,
		assortments_options,
		assortmentList,
		column_sizes,
		changeRow,
		removeRow
	} = props;

	const WZQuantity = +WZ?.assortments?.find(i => i.assortment_id === row.assortment_id)?.quantity_released || 0;
	const assortment = assortments_options.find(i => i.id === row.assortment_id);

	const [data, setData] = useState({
		price: row.price,
		quantity: assortment?.quantity,
		quantity_requested: row.quantity_requested,
		quantity_released: row.quantity_released,
		stock: +assortment?.stock + WZQuantity,
	});

	const [price_zl, price_gr] = getPriceZlAndGr(row.price);
	const [value_zl, value_gr] = getPriceZlAndGr(row.value);

	useEffect(() => {
		changeRow(index, {
			...data,
			value: data.price * data.quantity_released,
			stock: data.quantity - data.quantity_released
		});
	}, [data]);

	const cells = [
		index + 1,
		row?.code ? row.code : '---',
		<Autocomplete
			options={groupByFirstLetter(assortmentList)}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
			value={assortments_options.find(i => +i.id === +row.assortment_id)}
			onChange={val => {
				setData({
					...data,
					assortment_id: val.id,
					code: val.gtin,
					price: val.sale_price,
					quantity: val.quantity,
					measurement_unit_id: val.measure_unit,
					measure_name: val.measure_name,
					stock_quantity: 0,
					released: 0,
					stock: +val.stock + WZQuantity,
				});
			}}
		/>,
		<input
			type="number"
			min={0}
			name="quantity_requested"
			value={row.quantity_requested}
			disabled={!row.assortment_id}
			onChange={e => {
				setData({ ...data, quantity_requested: e.target.value.replace('-', '') });
			}}
		/>,
		row?.measure_name || '---',
		<input
			type="number"
			min={0}
			name="quantity_released"
			value={row.quantity_released}
			disabled={!row.assortment_id}
			onChange={e => {
				let quantity_released = +e.target.value.replace('-', '');
				if (data.stock - quantity_released < 0) {
					addToast('Wydana ilość nie może być większa niż zapas w magazynie.', { appearance: 'warning' });
					quantity_released = data.stock;
				}
				setData({ ...data, quantity_released });
			}}
		/>,
		price_zl,
		price_gr || '0',
		value_zl,
		value_gr || '0',
		(data.stock - data.quantity_released) || 0,
	];

	return (
		<DocumentTableTr
			cells={cells}
			column_sizes={column_sizes}
			removeRow={removeRow}
			index={index}
		/>
	);
}
