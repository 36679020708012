import axios from 'axios';
import storage from 'utils/storage';

const API = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL
});

API.interceptors.request.use(config => {
	config.headers.Authorization = 'Bearer ' + storage.getStorage('token');
	if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_XDEBUG_SESSION) {
		config.params = config.params || {};
		config.params.XDEBUG_SESSION = process.env.REACT_APP_XDEBUG_SESSION;
	}
	return config;
});

API.interceptors.response.use(res => res.data, error => {
	if (error.response.data?.message) {
		error.response.message = error.response.data.message
	}
	if (error.response.status === 404) {
		return Promise.reject(error);
	} else if ([401, 500].includes(error.response.status)) {
		return Promise.reject(error);
	}
	return error?.response || error;
});

export default API;
