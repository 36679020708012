import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link, useHistory, useParams } from 'react-router-dom';
import PATHS from 'routes/paths';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardActions from '@material-ui/core/CardActions';
import SubscriptionPlanAPI from 'apis/SubscriptionPlanAPI';
import { getPeriod, getPeriodName, getTypeDescription } from '../SubscriptionPlans';
import Loader from 'components/Loader';
import PaymentAPI from 'apis/PaymentAPI';
import { useToasts } from 'react-toast-notifications';

const label = (option) => {
	return option.price + ' zł - ' + (
		option.users_nb === 1 ? 'indywidualny' : `dla grupy do ${option.users_nb} osób`
	);
};

const SubscriptionOrder = () => {
	const history = useHistory();
	const { addToast } = useToasts();
	const { type, period } = useParams();
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [toPay, setToPay] = useState(0);
	const [planIndex, setPlanIndex] = useState(null);
	const [planId, setPlanId] = useState(null);

	useEffect(() => {
		SubscriptionPlanAPI.get(type, period).then(response => {
			setData(response.data);
			setLoading(false);
		});
	}, []);

	const handleChange = event => {
		const index = parseInt(event.target.value);
		setPlanIndex(index);
		setPlanId(data[index].id);
		setToPay(parseInt(data[index].price));
	};

	const handlePayment = () => {
		if (!planId) {
			return;
		}
		setLoading(true);
		PaymentAPI.register({ plan_id: planId }).then(response => {
			addToast(response?.message || 'Nieoczekiwany błąd', {
				appearance: response?.code === 200 ? 'success' : 'error',
				autoDismissTimeout: 10000,
				autoDismiss: true,
			});
			if (response.code === 200) {
				setTimeout(() => document.location.href = response.url, 500);
			}
			setLoading(false);
		});
	};

	return <Loader loading={loading}>
		<Grid container
					spacing={4}
					direction='row'
					justifyContent='center'>
			<Grid item xs={8}>
				<Typography variant='h1' component='h2' className={classes.welcomeIntro}>
					Witaj w naszym systemie!
				</Typography>
				<Typography component='p' className={classes.welcomeText}>
					Aby korzystać z aplikacji należy wykupić abonament.
					Wybierz abonament z listy, a następnie przejdź do płatności.
				</Typography>
			</Grid>
			<Grid container
						spacing={4}
						direction='row'
						justifyContent='center'>
				<Grid item xs={12} md={8}>
					<Card className={classes.root} variant='outlined'>
						<CardContent>
							<Card className={classes.rootOffer} variant="outlined">
								<CardContent>
									<Typography className={classes.title} color="textSecondary" gutterBottom>
										{getPeriodName(period)}
										<span className={classes.details}>{getPeriod(period)}</span>
									</Typography>

									<div className={classes.offerWrapper}>
										<Typography component='p' className={classes.text}>
											{getTypeDescription(type)}
										</Typography>
										<Typography component='p' className={classes.textInfo}>
											płatność wyłącznie za pomocą PayPro
										</Typography>
										<div className={classes.offerDetailsSelectorWrapper}>
											<FormControl component='fieldset'>
												<FormLabel component='legend'>Wybierz pakiet</FormLabel>
												<br/>
												<RadioGroup value={planIndex}
																		onChange={handleChange}>
													{data.map((p, index) =>
														<FormControlLabel
															key={index}
															value={index}
															control={<Radio/>}
															label={label(p)}
														/>
													)}
												</RadioGroup>
											</FormControl>
										</div>

										<br/>
										<br/>

										<h1>Do zapłaty: {toPay} zł</h1>

										<br/>
										<br/>

										<Grid container
													spacing={4}
													direction='row'
													justifyContent='center'>
											<Grid item xs={6}>
												<Button target='_blank'
																onClick={handlePayment}
																className={classes.filled_button}
																disabled={!planId}
																variant='outlined'>
													Przejdź do płatności
												</Button>
											</Grid>
										</Grid>

									</div>

								</CardContent>
								<CardActions>
									<Button component={Link}
													size="small"
													to={PATHS.SubscriptionPlanList}>
										Wróć
									</Button>
								</CardActions>
							</Card>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Grid>
	</Loader>
};

export default SubscriptionOrder;
