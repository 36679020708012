import API from './API';
import { getParamsByOptions } from '../utils/functions';

const DocumentPZAPI = {
	getInfo: () => API.get('/info', { params: { type: 'document' } }),
	index: options => API.get('/documents_pz' + getParamsByOptions(options)),
	get: id => API.get(`/documents_pz/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/documents_pz/${id}`, data) : API.post('/documents_pz', data),
	delete: id => API.delete(`/documents_pz/${id}`),
	export: options => API.get('/documents_pz/export' + getParamsByOptions(options)),
	import: data => API.post('/documents_pz/list', data)
};

export default DocumentPZAPI;
