import API from './API';
import { getParamsByOptions } from 'utils/functions';

const AdminInvoiceAPI = {
	index: options => API.get('/admin/invoices' + getParamsByOptions(options)),
	get: id => API.get(`/admin/invoices/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/admin/invoices/${id}`, data) : API.post('/admin/invoices', data),
	delete: id => API.delete(`/admin/invoices/${id}`),
	print: id => API.get(`/admin/invoices/${id}/print`, { responseType: 'blob' }),
};

export default AdminInvoiceAPI;
