import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import moment from 'moment';
import { numberString } from "../../utils/functions";

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid lightgray'
  }
}));

const PreviewTable = ({ assortments }) => {
  const classes = useStyles();
  const header = [
    { id: 'no', label: 'Lp.', disableSort: true },
    { id: 'product_name', label: 'Nazwa towaru', disableSort: true },
    { id: 'bar_code', label: 'Kod towaru', disableSort: true },
    { id: 'measure_unit', label: 'Jednostka m.', disableSort: true },
    { id: 'quantity', label: 'Ilość', disableSort: true },
    { id: 'delivery_date', label: 'Termin dost.', disableSort: true },
    { id: 'status', label: 'Zrealizowano', disableSort: true }
  ];

  return (
    <div className={classes.container}>
      <Table
        disablePagination
        header={header}
      >
        {assortments.map((row, index) => (
          <Row key={index}>
            <Cell>{(index + 1)}</Cell>
            <Cell>{row.assortment?.name}</Cell>
            <Cell>{row.assortment?.gtin}</Cell>
            <Cell>{row.assortment?.measure_name}</Cell>
            <Cell>{numberString(row.quantity)}</Cell>
            <Cell>{moment(row.delivery_date).format('DD/MM/YYYY')}</Cell>
            <Cell>{row.status}</Cell>
          </Row>
        ))}
      </Table>
    </div>
  );
};

PreviewTable.propTypes = {
  assortments: PropTypes.array
}

export default PreviewTable;
