/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { Box } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import ChartButton from 'components/misc/ChartButton';
import Chart from 'components/Chart/PieChart';
import { sortData, exportToXlsx, exportToPng, numberStringFixed } from 'utils/functions';

const AssortmentAmount = props => {
	const header = [
		{ id: 'name', label: 'Nazwa' },
		{ id: 'index', label: 'Indeks' },
		{ id: 'assortment_group_name', label: 'Grupa' },
		{ id: 'measure_name', label: 'Jedn.miary' },
		{
			id: 'stock_maintenance_cost',
			label: 'Koszt utrzymania zapasu całkowitego',
			info: [
				'Kut = u*C*Z',
				'u - współczynnik kosztu utrzymania zapasu',
				'C - cena jednostkowa',
				'Z - średni stan zapasu'
			]
		},
		{
			id: 'cost_of_replenishing_the_total_stock',
			label: 'Koszt uzupełnienia zapasu całkowitego',
			info: [
				'Kuz = (P/WD) * Kz',
				'P - popyt',
				'WD - średnia dostawa',
				'Kz - koszt zamawiania (uzupełniania) zapasów'
			]
		},
		{
			id: 'total_cost_of_stockpiling_and_maintaining',
			label: 'Całkowity koszt gromadzenia i utrzymania zapasów',
			info: [
				'K = Kut + Kuz',
				'Kut - koszt utrzymania zapasu',
				'Kuz - koszt uzupełniania zapasu'
			]
		},
		{ id: 'action', label: '' }
	];
	const [data, setData] = useState([]);
	const [activeRow, setActiveRow] = useState({});
	const [chartData, setChartData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};

	const handleDrawChart = row => {
		setChartData([
			{
				label: 'Koszt utrzymania zapasu całkowitego',
				y: row.stock_maintenance_cost
			},
			{
				label: 'Koszt uzupełnienia zapasu całkowitego',
				y: row.cost_of_replenishing_the_total_stock
			},
			{
				label: 'Całkowity koszt gromadzenia i utrzymania zapasów',
				y: row.total_cost_of_stockpiling_and_maintaining
			}
		]);
		setActiveRow(row);
	};

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<>
			<Box mb={2}>
				<Button onClick={handleExport}>Eksportuj do XLS</Button>
				<Button
					onClick={() => exportToPng(props.chartId)}
					style={{ marginLeft: 20 }}>
					Pobierz wykres .PNG
				</Button>
			</Box>
			<Box mb={2}>
				<Table
					header={header}
					disablePagination={true}
					onSort={o => setData([...sortData(data, o.columnId, o.direction)])}>
					{data.map((row, index) => {
						return (
							<Row key={index}>
								<Cell>{row.name}</Cell>
								<Cell>{row.index}</Cell>
								<Cell>{row.assortment_group_name}</Cell>
								<Cell>{row.measure_name}</Cell>
								<Cell align="right">{numberStringFixed(row.stock_maintenance_cost)}</Cell>
								<Cell align="right">{numberStringFixed(row.cost_of_replenishing_the_total_stock)}</Cell>
								<Cell align="right">{numberStringFixed(row.total_cost_of_stockpiling_and_maintaining)}</Cell>
								<Cell>
									<ChartButton
										active={activeRow === row}
										onClick={() => handleDrawChart(row)}
									/>
								</Cell>
							</Row>
						);
					})}
				</Table>
			</Box>
			<Box id={props.chartId} mb={2}>
				<Chart data={chartData} />
			</Box>
		</>
	);
};

export default AssortmentAmount;
