/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from 'components';
import useStyles from './style';
import { Table, Row, Cell } from 'components/MyTable';
import { useToasts } from 'react-toast-notifications';
import PATHS from 'routes/paths';
import AdminInvoiceAPI from 'apis/AdminInvoiceAPI';
import TopButtonsBox from 'components/TopButtonsBox';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import PrintButton from 'components/misc/PrintButton';

const AdminInvoiceList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const header = [
		// { id: 'id', label: 'ID', filter: 'text' },
		{ id: 'user_name', label: 'Użytkownik', filter: 'text' },
		{ id: 'user_email', label: 'Email', filter: 'text' },
		{ id: 'document_number', label: 'Nr faktury', filter: 'text' },
		{ id: 'first_name', label: 'Imię', filter: 'text' },
		{ id: 'last_name', label: 'Nazwisko', filter: 'text' },
		{ id: 'company_name', label: 'Firma', filter: 'text' },
		{ id: 'nip', label: 'NIP', filter: 'text' },
		{ id: 'sale_date', label: 'Data sprzedaży', filter: 'text' },
		{ id: 'issue_date', label: 'Data wykonania', filter: 'text' },
		{ id: 'vat', label: 'VAT', filter: 'text' },
		{ id: 'total_netto', label: 'Netto', filter: 'text' },
		// { id: 'total_taxes', label: 'Podatki', filter: 'text' },
		{ id: 'total_brutto', label: 'Brutto', filter: 'text' },
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	];

	const handleEdit = id => {
		history.push(PATHS.AdminPlanEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			AdminInvoiceAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handlePrint = id => {
		setLoading(true);
		AdminInvoiceAPI.print(id).then((response) => {
			const url = window.URL.createObjectURL(response);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Faktura.pdf');
			document.body.appendChild(link);
			setLoading(false);
			link.click();
		}).catch(error => {
			setLoading(false);
			addToast('Błąd pobierania pliku.', { appearance: 'error' });
		});
	};

	const getData = () => {
		setLoading(true);
		AdminInvoiceAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	return (
		<>
			{/*<TopButtonsBox*/}
			{/*	add_button={{*/}
			{/*		label: 'Dodaj nową fakturę',*/}
			{/*		path: PATHS.AdminInvoiceCreate,*/}
			{/*	}}*/}
			{/*/>*/}

			<Breadcrumbs list={['Administracja', 'Faktury']}/>

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								{/*<Cell>{row.id}</Cell>*/}
								<Cell>{row.user?.full_name}</Cell>
								<Cell>{row.user?.email}</Cell>
								<Cell>{row.document_number}</Cell>
								<Cell>{row.first_name}</Cell>
								<Cell>{row.last_name}</Cell>
								<Cell>{row.company_name}</Cell>
								<Cell>{row.nip}</Cell>
								<Cell>{row.sale_date}</Cell>
								<Cell>{row.issue_date}</Cell>
								<Cell>{row.vat}%</Cell>
								<Cell>{row.total_netto} PLN</Cell>
								{/*<Cell>{row.total_taxes} PLN</Cell>*/}
								<Cell>{row.total_brutto} PLN</Cell>
								<Cell align="center" width={120}>
									{/*<EditButton onClick={() => handleEdit(row.id)}/>*/}
									{/*<TrashButton onClick={() => setDeleteId(row.id)}/>*/}
									<PrintButton onClick={() => handlePrint(row.id)}/>
								</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default AdminInvoiceList;
