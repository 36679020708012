import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	welcomeIntro: {
		textAlign: 'center'
	},
	welcomeText: {
		textAlign: 'center',
		fontSize: 18,
		marginTop: 20,
		marginBottom: 50
	},
	filled_button: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		'& .MuiButton-label': {
			textTransform: 'none',
			fontSize: '1em'
		},
		'&:hover': {
			border: `1px solid ${theme.palette.green}`,
			backgroundColor: theme.palette.green,
			color: theme.palette.black
		},
		fontSize: 15,
		padding: theme.spacing(.9, 2),
		borderRadius: '0px',
		fontWeight: '300',
		color: theme.palette.black,
		border: `1px solid ${theme.palette.green}`,
		[theme.breakpoints.up('xs')]: {
			marginRight: '0px'
		},
		[theme.breakpoints.up('md')]: {
			marginRight: '0px'
		}
	},

	root: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	buttonContainer: {
		flexShrink: 1,
		marginTop: theme.spacing(6),
		maxWidth: 300,
		width: '100%',
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	note: {
		fontSize: '90%',
		color: theme.palette.gray_light,
	},
	priceList: {
		'#price-list &': {
			maxWidth: 1500,
		},
		marginTop: theme.spacing(4),
		width: '100%',
		flexGrow: 1,
		justifyContent: 'center',
	},
	itemTitle: {
		fontSize: '120%',
	},
	itemTime: {
		color: theme.palette.green,
	},
	price: {
		fontWeight: 'bold',
	},
	priceRow: {
		display: 'flex',
		marginTop: theme.spacing(1),
		justifyContent: 'space-between'
	},
	list: {
		position: 'relative',
		marginLeft: theme.spacing(3),
		'&::before': {
			content: '"■"',
			position: 'absolute',
			left: -10,
			top: 11,
			fontSize: 9,
			color: theme.palette.green,
		}
	},
}));

export default useStyles;
