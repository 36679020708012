/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Autocomplete from 'components/FormInput/Autocomplete';
import { DocumentTableTr } from 'components/document/DocumentTable';
import { getPriceZlAndGr } from 'utils/functions';
import { groupByFirstLetter } from 'utils/functions';

export default function DocumentPZCreateTableRow(props) {
	const {
		PZ = {},
		row,
		index,
		assortments_options,
		assortmentList,
		column_sizes,
		changeRow,
		removeRow,
	} = props;

	const PZQuantity = +PZ?.assortments?.find(i => i.assortment_id === row.assortment_id)?.received_quantity || 0;
	const assortment = assortments_options.find(i => i.id === row.assortment_id);

	const [data, setData] = useState({
		assortment_id: row.assortment_id,
		price: row.price,
		quantity: assortment?.quantity,
		delivered_quantity: row.delivered_quantity,
		received_quantity: row.received_quantity,
		stock: +assortment?.stock - PZQuantity,
	});

	const [price_zl, price_gr] = getPriceZlAndGr(row.price);
	const [value_zl, value_gr] = getPriceZlAndGr(row.value);

	useEffect(() => {
		changeRow(index, {
			...data,
			value: +data.price * data.received_quantity,
			stock: +data.quantity + data.received_quantity
			// stock_value: data.price * (data.quantity + +data.received_quantity)
		});
	}, [data]);

	const cells = [
		index + 1,
		row?.code ? row.code : '---',
		<Autocomplete
			options={groupByFirstLetter(assortmentList)}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
			value={assortments_options.find(i => +i.id === +row.assortment_id)}
			onChange={val => {
				setData({
					...data,
					assortment_id: val.id,
					code: val.gtin,
					price: val.sale_price,
					quantity: val.quantity,
					measurement_unit_id: val.measure_unit,
					measure_name: val.measure_name,
					stock: +val.stock - PZQuantity,
				});
			}}
		/>,
		<input
			type="number"
			min={0}
			name="delivered_quantity"
			value={row.delivered_quantity}
			disabled={!data.assortment_id}
			onChange={e => {
				setData({ ...data, delivered_quantity: e.target.value.replace('-', '') });
			}}
		/>,

		row?.measure_name || '---',

		<input
			type="number"
			min={0}
			name="received_quantity"
			value={row.received_quantity}
			disabled={!data.assortment_id}
			onChange={e => {
				setData({ ...data, received_quantity: +e.target.value.replace('-', '') });
			}}
		/>,

		price_zl,
		price_gr || '0',

		value_zl,
		value_gr || '0',

		(data.stock + data.received_quantity) || 0,
		'',
		<input
			type="number"
			min={0}
			name="handling_delivery_cost"
			value={row.handling_delivery_cost}
			disabled={!data.assortment_id}
			onChange={e => {
				setData({ ...data, handling_delivery_cost: e.target.value });
			}}
		/>
	];

	return (
		<DocumentTableTr
			cells={cells}
			column_sizes={column_sizes}
			removeRow={removeRow}
			index={index}
		/>
	);
}
