import API from './API';

const UserAPI = {
	get: () => API.get('/user'),
	update: data => API.put('/user', data),
	getCompany: () => API.get('/user/company'),
	updateCompany: data => API.put('/user/company', data),
	updatePassword: data => API.put('/user/password', data),
	// getInfo: () => API.get('/user'),
	sendUserInvite: data => API.post('/user/invite', data),
	sendUserEmailToRemoveSubscription: email => API.delete(`/user/invite/${email}`)
};

export default UserAPI;
