import API from './API';
import { getParamsByOptions } from 'utils/functions';

const MeasurementUnitAPI = {
	getInfo: () => API.get('/info', { params: { 'type': 'measurement_units' } }),
	index: options => API.get('/measurement_units' + getParamsByOptions(options)),
	export: options => API.get('/measurement_units/export' + getParamsByOptions(options)),
	get: id => API.get(`/measurement_units/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/measurement_units/${id}`, data) : API.post('/measurement_units', data),
	delete: id => API.delete(`/measurement_units/${id}`),
	create_list: data => API.post('/measurement_units/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/measurement_units/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default MeasurementUnitAPI;
