import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import MuiCollapse from '@material-ui/core/Collapse';

const Collapse = props => {
	const [open, setOpen] = useState(props.defaultOpen);
	const handleToggleClick = () => {
		setOpen(!open);
	};
	return (
		<>
			<Box
				display="flex"
				justifyContent="space-between"
				style={{ cursor: 'pointer', padding: 10, backgroundColor: '#f0f0f0' }}
				onClick={() => handleToggleClick()}>
				<Typography variant="h5">{props.title}</Typography>
				<Typography variant="h5" style={{ color: '#44D62C' }}>
					{open ? '-' : '+'}
				</Typography>
			</Box>
			<Box style={{ padding: 10 }}>
				<MuiCollapse in={open}>{props.content}</MuiCollapse>
			</Box>
		</>
	);
};

export default Collapse;
