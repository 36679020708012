import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'components/FormInput/Autocomplete';
import DatePicker from 'components/FormInput/MyDatePicker';
import { DocumentTableTr } from 'components/document/DocumentTable';
import { groupByFirstLetter } from 'utils/functions';

const OrderCreateTableRow = props => {
	const {
		index,
		row,
		assortments_options,
		assortmentList,
		column_sizes,
		changeRow,
		removeRow
	} = props;

	const hanldeAssortmenChange = val => {
		changeRow(
			index,
			val
				? {
						assortment_id: val.id,
						measure_unit: val.measure_unit,
						measure_name: val.measure_name,
						index: val.index
				  }
				: null
		);
	};

	const cells = [
		index + 1,
		<Autocomplete
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
			onChange={hanldeAssortmenChange}
			options={groupByFirstLetter(assortmentList)}
			value={assortments_options.find(i => +i.id === +row.assortment_id)}
		/>,
		row?.index || row?.assortment?.index || '---',
		row?.measure_name || row?.assortment?.measure_name || '---',
		<input
			type="number"
			disabled={!row.assortment_id}
			min={0}
			onChange={e => changeRow(index, { quantity: e.target.value })}
			value={row.quantity || 0}
		/>,
		<div style={{ width: 200 }}>
			<DatePicker
				disabled={!row.assortment_id}
				onChange={delivery_date => changeRow(index, { delivery_date })}
				value={row.delivery_date}
			/>
		</div>
	];

	return (
		<DocumentTableTr
			index={index}
			cells={cells}
			column_sizes={column_sizes}
			removeRow={removeRow}
		/>
	);
};

OrderCreateTableRow.propTypes = {
	assortmentList: PropTypes.array,
	assortments_options: PropTypes.array,
	changeRow: PropTypes.func,
	column_sizes: PropTypes.array,
	index: PropTypes.number,
	removeRow: PropTypes.func,
	row: PropTypes.object
};

export default OrderCreateTableRow;
