/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, OutlineButton } from 'components';
import {
	Typography,
	CardContent,
	Grid,
	Card,
	Box,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button
} from '@material-ui/core';
import uniqid from 'uniqid';
import moment from 'moment';
import Select from 'components/FormInput/Autocomplete';
import Loader from 'components/Loader';
import AnalyzeAbcAPI from 'apis/AnalyzeAbcAPI';
import PATHS from 'routes/paths';
import useGlobalStyles from 'assets/style/styles';
import CrossReleaseSize from './tables/CrossReleaseSize';
import CrossReleaseValue from './tables/CrossReleaseValue';
import CrossStockSize from './tables/CrossStockSize';
import CrossStockValue from './tables/CrossStockValue';
import SeparatelyReleaseSize from './tables/SeparatelyReleaseSize';
import SeparatelyReleaseValue from './tables/SeparatelyReleaseValue';
import SeparatelyStockSize from './tables/SeparatelyStockSize';
import SeparatelyStockValue from './tables/SeparatelyStockValue';
import XyzAnalyze from './tables/XyzAnalyze';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';

const AnalizeXyzResult = () => {
	const history = useHistory();
	const { id } = useParams();
	const globalClasses = useGlobalStyles();
	const { addToast } = useToasts();

	const [loading, setLoading] = useState(true);
	const [displayMethod, setDisplayMethod] = useState('separately'); // separately : collectively
	// const [crossDisplayBy, setCrossDisplayBy] = useState('releases_size');
	const crossCates = [
		{ id: 1, value: 'releases_sizes', label: 'Wielkość wydań' },
		{ id: 2, value: 'releases_value', label: 'Wartość wydań' },
		{ id: 3, value: 'stock_size', label: 'Wielkość zapasu' },
		{ id: 4, value: 'stock_value', label: 'Wartość zapasu' }
	];
	const [selectedCrossCate, setSelectedCrossCate] = useState(crossCates[0]);
	const [analyzeVisible, setAnalyzeVisible] = useState(true);
	const [diff, setDiff] = useState({});

	const [data, setData] = useState({
		abc_analysis: false,
		releases_size: false,
		releases_value: false,
		stock_size: false,
		stock_value: false,
		xyz_analysis: false
	});
	// const [calculations, setCalculations] = useState([]);

	useEffect(() => {
		setLoading(true);
		AnalyzeAbcAPI.get(id).then(res => {
			if (res.code === 200) {
				setData(res.data);
				setDiff({
					a:
						res.data.abc_analysis_details_category_a_max -
						res.data.abc_analysis_details_category_a_min,
					b:
						res.data.abc_analysis_details_category_b_max -
						res.data.abc_analysis_details_category_b_min,
					c:
						res.data.abc_analysis_details_category_c_max -
						res.data.abc_analysis_details_category_c_min
				});
			} else if (res.status === 404) {
				addToast(res.message || 'Błąd 404', { appearance: 'error' });
				history.push(PATHS.AnalyzeXyzList);
			}
			setLoading(false);
		});
	}, []);

	return (
		<>
			<Breadcrumbs
				list={['Analizy', { label: 'Analiza ABC i XYZ', path: PATHS.AnalyzeXyzList }, 'Wyniki analizy']}
				back_url={PATHS.AnalyzeXyzList}
			/>

			<Loader loading={loading}>
				<Grid container spacing={2}>
				<Grid item xs={12} md={9}>
					<Card style={{ marginBottom: '1em' }}>
						<CardContent>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h4">Dane dotyczące analizy</Typography>
								<Button
									variant="outlined"
									onClick={() => setAnalyzeVisible(!analyzeVisible)}>
									{!analyzeVisible && <ExpandMoreIcon />}
									{analyzeVisible && <ExpandLessIcon />}
								</Button>
							</Box>
							{analyzeVisible && (
								<Box>
									<Grid container spacing={2}>
										<Grid item xs={2}>
											<Typography>Nazwa analizy</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">{data.name}</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Zakres od</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.range_from
													? moment(data.range_from).format('DD/MM/YYYY')
													: ''}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Zakres do</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.range_to
													? moment(data.range_to).format('DD/MM/YYYY')
													: ''}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Opis</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">{data.description}</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Asortyment</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.assortment_groups_children_names}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Grupy asortymentowe</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.assortment_groups_main_names}
											</Typography>
										</Grid>
									</Grid>
								</Box>
							)}
						</CardContent>
					</Card>

					<Card>
						<CardContent>
							<Box style={{ display: 'flex' }}>
								<Typography variant="h4">Wyniki analizy</Typography>
								<Box style={{ marginLeft: 20, paddingTop: 5 }}>
									<Typography>Wyświetlaj wyniki:</Typography>
									<Box style={{ paddingLeft: 50 }}>
										<RadioGroup
											aria-label="gender"
											value={displayMethod}
											onChange={e => setDisplayMethod(e.target.value)}>
											<FormControlLabel
												value="collectively"
												control={<Radio />}
												label="Krzyżowo"
											/>
											{displayMethod === 'collectively' && (
												<Box
													style={{
														width: 300,
														paddingLeft: 50,
														marginBottom: 10
													}}>
													<Typography style={{ marginBottom: 5 }}>
														Klasyfikacja ABC według
													</Typography>
													<Select
														placeholder="Wybierz"
														noOptionsMessage={() => 'Brak opcji'}
														options={crossCates}
														value={selectedCrossCate}
														getOptionValue={option => option.value}
														getOptionLabel={option => option.label}
														onChange={v => setSelectedCrossCate(v)}
													/>
												</Box>
											)}

											<FormControlLabel
												value="separately"
												control={<Radio />}
												label="Oddzielnie"
											/>
											{displayMethod === 'separately' && (
												<Box style={{ paddingLeft: 50 }}>
													<FormInput
														title="Analiza ABC"
														name="abc_analysis"
														type="check_box"
														value={data.abc_analysis}
														handleChange={(n, v) => {
															setData({
																...data,
																abc_analysis: v,
																releases_size: v,
																releases_value: v,
																stock_size: v,
																stock_value: v
															});
														}}
													/>
													<Box style={{ paddingLeft: 50 }}>
														<FormInput
															title="Wielkość wydań"
															type="check_box"
															value={data.releases_size}
															handleChange={(n, v) => {
																setData({
																	...data,
																	releases_size: v,
																	abc_analysis:
																		v ||
																		data.releases_value ||
																		data.stock_size ||
																		data.stock_value
																});
															}}
														/>
														<FormInput
															title="Wartość wydań"
															type="check_box"
															value={data.releases_value}
															handleChange={(n, v) => {
																setData({
																	...data,
																	releases_value: v,
																	abc_analysis:
																		v ||
																		data.releases_size ||
																		data.stock_size ||
																		data.stock_value
																});
															}}
														/>
														<FormInput
															title="Wielkość zapasu"
															type="check_box"
															value={data.stock_size}
															handleChange={(n, v) => {
																setData({
																	...data,
																	stock_size: v,
																	abc_analysis:
																		v ||
																		data.releases_size ||
																		data.releases_value ||
																		data.stock_value
																});
															}}
														/>
														<FormInput
															title="Wartość zapasu"
															type="check_box"
															value={data.stock_value}
															handleChange={(n, v) => {
																setData({
																	...data,
																	stock_value: v,
																	abc_analysis:
																		v ||
																		data.releases_size ||
																		data.releases_value ||
																		data.stock_size
																});
															}}
														/>
													</Box>
													<FormInput
														title="Analiza XYZ"
														type="check_box"
														value={data.xyz_analysis}
														handleChange={(n, v) =>
															setData({ ...data, xyz_analysis: v })
														}
													/>
												</Box>
											)}
										</RadioGroup>
									</Box>
								</Box>
							</Box>

							{displayMethod === 'collectively' && (
								<Box>
									{selectedCrossCate.value === 'releases_sizes' && (
										<CrossReleaseSize
											chartId={uniqid()}
											category={selectedCrossCate}
											diff={diff}
											data={
												data.calculations?.abcxyz_crossed[
													selectedCrossCate.value
												]
											}
										/>
									)}
									{selectedCrossCate.value === 'releases_value' && (
										<CrossReleaseValue
											chartId={uniqid()}
											category={selectedCrossCate}
											diff={diff}
											data={
												data.calculations?.abcxyz_crossed[
													selectedCrossCate.value
												]
											}
										/>
									)}
									{selectedCrossCate.value === 'stock_size' && (
										<CrossStockSize
											chartId={uniqid()}
											category={selectedCrossCate}
											diff={diff}
											data={
												data.calculations?.abcxyz_crossed[
													selectedCrossCate.value
												]
											}
										/>
									)}
									{selectedCrossCate.value === 'stock_value' && (
										<CrossStockValue
											chartId={uniqid()}
											category={selectedCrossCate}
											diff={diff}
											data={
												data.calculations?.abcxyz_crossed[
													selectedCrossCate.value
												]
											}
										/>
									)}
								</Box>
							)}

							{displayMethod === 'separately' && (
								<Box>
									{!!data.releases_size && (
										<SeparatelyReleaseSize
											chartId={uniqid()}
											diff={diff}
											data={data.calculations?.abc_analyze?.releases_sizes}
											chartGrouped={true}
										/>
									)}
									{!!data.releases_value && (
										<SeparatelyReleaseValue
											chartId={uniqid()}
											diff={diff}
											data={data.calculations?.abc_analyze?.releases_value}
											chartGrouped={true}
										/>
									)}
									{!!data.stock_size && (
										<SeparatelyStockSize
											chartId={uniqid()}
											diff={diff}
											data={data.calculations?.abc_analyze?.stock_size}
											chartGrouped={true}
										/>
									)}
									{!!data.stock_value && (
										<SeparatelyStockValue
											chartId={uniqid()}
											diff={diff}
											data={data.calculations?.abc_analyze?.stock_value}
											chartGrouped={true}
										/>
									)}
									{!!data.xyz_analysis && (
										<XyzAnalyze
											chartId={uniqid()}
											valA={data.xyz_analysis_details_category_a_min}
											valB={data.xyz_analysis_details_category_b_min}
											valC={data.xyz_analysis_details_category_c_min}
											data={data.calculations?.xyz_analyze}
											chartGrouped={true}
										/>
									)}
								</Box>
							)}

						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={3}>
					<Card>
						<CardContent style={{ paddingBottom: 16 }}>
							<OutlineButton
								title="Utwórz nową analizę"
								onClick={() => history.push(PATHS.AnalyzeXyzCreate)}
								parent_class={globalClasses.full_fill}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			</Loader>
		</>
	);
};

export default AnalizeXyzResult;
