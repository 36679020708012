import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Loader } from 'components';
import { Card, Grid, Typography } from '@material-ui/core';
import PaymentAPI from 'apis/PaymentAPI';
import PATHS from 'routes/paths';
import useStyles from './style';
import { useHistory } from 'react-router-dom';
import storage from 'utils/storage';
import { useToasts } from 'react-toast-notifications';

const PaymentStatus = () => {
	const history = useHistory();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('Oczekiwanie na płatność...');
	const [reload, setReload] = useState(2000);
	const classes = useStyles();

	useEffect(() => {
		setLoading(true);
		PaymentAPI.check().then(response => {
			console.info({ response });
			switch (response.status) {
				case 'success':
					setMessage('Płatność przebiegła pomyślnie.');
					setLoading(false);

					storage.setStorage('role', response.data?.role);
					storage.setStorage('subscription', JSON.stringify(response.data?.subscription || '{}'));

					setTimeout(() => history.push(PATHS.Welcome), 2000);
					break;
				case 'error':
					setMessage('Błąd płatności.');
					setLoading(false);
					break;
				case 'await':
					setTimeout(() => setLoading(false), 1000);
					setTimeout(() => setReload(reload + 500), reload);
					break;
				default:
					setMessage('Brak płatności.');
					setLoading(false);
					setTimeout(() => history.push(PATHS.Welcome), 2000);
			}
		});
	}, [reload]);

	return <>
		<Breadcrumbs list={['Płatność', 'Status']}/>

		<Loader loading={loading}>
			<Card className={classes.card}>
				<Grid container justifyContent='center' spacing={4}>
					<Grid item xs={12}>
						<div className={classes.content}>
							<Typography variant='h1'>{message}</Typography>
						</div>
					</Grid>
				</Grid>
			</Card>
		</Loader>
	</>
};

export default PaymentStatus;
