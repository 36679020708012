const PATHS = {
	SubscriptionPlanList: '/subscriptions',
	SubscriptionPlan: (type = ':type', period = ':period') => `/subscriptions/${type}/${period}`,

	AdminUserList: '/admin/users',
	AdminUserCreate: '/admin/users/create',
	AdminUserEdit: (id = ':id') => `/admin/users/edit/${id}`,

	AdminSubscriptionList: '/admin/subscriptions',
	AdminSubscriptionCreate: '/admin/subscriptions/create',
	AdminSubscriptionEdit: (id = ':id') => `/admin/subscriptions/edit/${id}`,

	AdminPlanList: '/admin/plans',
	AdminPlanCreate: '/admin/plans/create',
	AdminPlanEdit: (id = ':id') => `/admin/plans/edit/${id}`,

	AdminInvoiceList: '/admin/invoices',
	AdminInvoiceCreate: '/admin/invoices/create',
	AdminInvoiceEdit: (id = ':id') => `/admin/invoices/edit/${id}`,

	AdminPaymentList: '/admin/payments',

	PaymentStatus: '/payment',

	Welcome: '/welcome',
	MyCompany: '/my-company',

	AssortmentList: '/assortments',
	AssortmentCreate: '/assortments/create',
	AssortmentEdit: (id = ':id') => `/assortments/edit/${id}`,

	AssortmentGroupList: '/assortment-groups',
	AssortmentGroupCreate: '/assortment-groups/create',
	AssortmentGroupEdit: (id = ':id') => `/assortment-groups/edit/${id}`,

	WarehouseList: '/warehouses',
	WarehouseCreate: '/warehouses/create',
	WarehouseEdit: (id = ':id') => `/warehouses/edit/${id}`,

	WarehouseGroupList: '/warehouse-groups',
	WarehouseGroupCreate: '/warehouse-groups/create',
	WarehouseGroupEdit: (id = ':id') => `/warehouse-groups/edit/${id}`,

	ContractorList: '/contractors',
	ContractorCreate: '/contractors/create',
	ContractorEdit: (id = ':id') => `/contractors/edit/${id}`,

	MeasurementUnitList: '/measure-units',
	MeasurementUnitCreate: '/measure-units/create',
	MeasurementUnitEdit: (id = ':id') => `/measure-units/edit/${id}`,

	WarehouseOperationList: '/warehouse-operations',
	WarehouseOperationCreate: '/warehouse-operations/create',
	WarehouseOperationEdit: (id = ':id') => `/warehouse-operations/edit/${id}`,
	WarehouseOperationGenerate: '/warehouse-operations/generate',
	WarehouseOperationGraph: '/warehouse-operations/graph',

	OrderList: '/orders',
	OrderCreate: '/orders/create',
	OrderEdit: (id = ':id') => `/orders/edit/${id}`,

	DocumentPZList: '/documents-pz',
	DocumentPZCreate: '/documents-pz/create',
	DocumentPZEdit: (id = ':id') => `/documents-pz/edit/${id}`,

	DocumentWZList: '/documents-wz',
	DocumentWZCreate: '/documents-wz/create',
	DocumentWZEdit: (id = ':id') => `/documents-wz/edit/${id}`,

	InvoiceList: '/invoices',
	InvoiceCreate: '/invoices/create',
	InvoiceEdit: (id = ':id') => `/invoices/edit/${id}`,

	AnalyzeXyzList: '/analyze-abc-xyz',
	AnalyzeXyzCreate: '/analyze-abc-xyz/create',
	AnalyzeXyzResult: (id = ':id') => `/analyze-abc-xyz/result/${id}`,

	StockAnalyzeList: '/stock-analyzes',
	StockAnalyzeCreate: '/stock-analyzes/create',
	StockAnalyzeResult: (id = ':id') => `/stock-analyzes/result/${id}`,

	Profile: '/profile',
	ProfileInvoiceList: '/profile-invoices',

	Login: '/login',
	ForgotPassword: '/forgot-password',
	ResetPassword: '/reset-password',
	Registration: '/registration',
	Verification: '/verification',

	PriceList: '/price-list',

	NotFound: '/not-found'
};

export default PATHS;
