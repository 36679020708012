import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

const StockTableTabs = props => {
	const { onChange, amountTabLabel = 'Zapas ilościowo', percentTabLabel = 'Udział procentowy' } = props;
	const [view, setView] = useState(props.view);

	const handleClick = v => {
		setView(v);
		onChange(v);
	};

	return (
		<Box mt={2} mb={1} display="flex" justifyContent="space-around">
			<Typography
				variant="h5"
				underline="none"
				style={{
					cursor: 'pointer',
					color: view === 'amount' ? '#44d62c' : '#000'
				}}
				onClick={e => handleClick('amount')}>
				{amountTabLabel}
			</Typography>
			<Typography
				variant="h5"
				underline="none"
				style={{
					cursor: 'pointer',
					color: view === 'percent' ? '#44d62c' : '#000'
				}}
				onClick={e => handleClick('percent')}>
				{percentTabLabel}
			</Typography>
		</Box>
	);
};

export default StockTableTabs;
