import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	progressContainer: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: 'rgba(255,255,255,0.8)',
	},
	progress: {
		color: theme.palette.green
	}
}));

const ProgressBar = props => {
	const { progressStatus, children } = props;

	const classes = useStyles();

	return (
		progressStatus &&
		<div className={classes.progressContainer}>
			<CircularProgress className={classes.progress} />
			{!!children && (
				children
			)}
		</div>
	);
};

export default ProgressBar;
