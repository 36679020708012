import API from 'apis/API';
import { getParamsByOptions } from 'utils/functions';

const AdminUserAPI = {
	get: id => API.get(`/admin/users/${id}`),
	index: options => API.get('/admin/users' + getParamsByOptions(options)),
	updateOrCreate: (data, id) => id ? API.put(`/admin/users/${id}`, data) : API.post('/admin/users', data),
	delete: id => API.delete(`/admin/users/${id}`),
	updatePassword: (data, id) => API.put(`/admin/users/${id}/password`, data),
	invite: id => id ? API.post(`/admin/users/${id}/invite`) : API.post('/admin/users/invite-all'),
};

export default AdminUserAPI;
