import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	checkIcon: {
		color: '#44D62C'
	},
	closeIcon: {
		color: '#EB2B2B'
	}
}));

const CheckMark = props => {
	const { checked, active } = props;
	const classes = useStyles();
	return (
		<>
			{checked || active ? (
				<CheckIcon className={classes.checkIcon} />
			) : (
				<CloseIcon className={classes.closeIcon} />
			)}
		</>
	);
};

export default CheckMark;
