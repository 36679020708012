import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	welcomeIntro: {
		textAlign: 'center'
	},
	welcomeText: {
		textAlign: 'center',
		fontSize: 18,
		marginTop: 20,
		marginBottom: 50
	},
	root: {
		padding: 10
	},
	rootOffer: {
		padding: 10
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
		color: theme.palette.green.black,
		marginBottom: 20
	},
	details: {
		fontSize: 13,
		fontWeight: 'bold',
		color: '#48d730',
		marginLeft: 20
	},
	offerDetailsWrapper: {},
	offerWrapper: {
		paddingLeft: 20,
		position: 'relative',
		marginBottom: 30,
		'&::before': {
			width: 10,
			height: 10,
			top: 5,
			left: 0,
			backgroundColor: '#48d730',
			display: 'block',
			content: '""',
			position: 'absolute'
		}
	},
	text: {
		fontSize: 16
	},
	offerDetailsSelectorWrapper: {
		backgroundColor: 'gold',
		marginTop: 20,
		padding: 20,
		color: '#FFFFFF'
	},
	textInfo: {
		fontSize: 12,
		margin: '6px 0'
	},
	textPriceDetails: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	textPrice: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	textPriceRight: {
		fontSize: 16,
		fontWeight: 'bold',
		textAlign: 'right'
	},
	pos: {
		marginBottom: 12
	},
	filled_button: {
		width: '100%',
		'& .MuiButton-label': {
			textTransform: 'none',
			fontSize: '1em'
		},
		'&:hover': {
			border: `1px solid ${theme.palette.green}`,
			backgroundColor: theme.palette.green,
			color: theme.palette.black
		},
		fontSize: 15,
		padding: theme.spacing(.9, 2),
		borderRadius: '0px',
		fontWeight: '300',
		color: theme.palette.black,
		border: `1px solid ${theme.palette.green}`,
		[theme.breakpoints.up('xs')]: {
			marginRight: '0px'
		},
		[theme.breakpoints.up('md')]: {
			marginRight: '0px'
		}
	}
}));

export default useStyles;
