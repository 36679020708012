import axios from 'axios';
import authHeader from './auth-header';
import storage from '../utils/storage';
import API from './API';
import moment from 'moment';

const getSubscriptionValue = value => {
	const subscription = JSON.parse(storage.getStorage('subscription') || '{}') || {};

	return subscription[value];
};
export const isSubscriptionValid = () => {
	return getSubscriptionValue('is_valid');
};
export const subscriptionValidUntil = () => {
	return getSubscriptionValue('valid_until');
};
export const isSubscriptionExpiring = () => {
	const validUntil = subscriptionValidUntil();
	if (typeof validUntil === 'string') {
		if (moment().add(2, 'weeks').isSameOrAfter(validUntil)) {
			return true;
		}
	}
	return false;
};

export const getRole = () => {
	return storage.getStorage('role');
};

const AuthAPI = {
	login: (email, password) => {
		return API.post('/auth/login', { email, password }).then(response => {
			if (response.code === 200) {
				storage.setStorage('token', response.data.token);
				storage.setStorage('role', response.data.role);
				storage.setStorage('subscription', JSON.stringify(response.data.subscription));
			}

			return response;
		});
	},

	reload: () => {
		return API.post('/auth/reload').then(response => {
			if (response.code === 200) {
				storage.setStorage('role', response.data.role);
				storage.setStorage('subscription', JSON.stringify(response.data.subscription));
			}

			return response;
		});
	},

	register: (email, password, profile) => API.post('/auth/register', { email, password, profile }),

	validate: token => {
		return axios
			.post(process.env.REACT_APP_BACKEND_URL + '/auth/verify', {}, {
				headers: authHeader(token)
			})
			.then(response => response.data)
			.catch(err => err);
	},

	forgot: email => API.post('/auth/forgot', { email }),

	reset_password: (password, token) => {
		return axios
			.post(process.env.REACT_APP_BACKEND_URL + '/auth/reset_password', { password }, { headers: authHeader(token) })
			.then(response => response.data)
			.catch(err => err);
	},

	validateToken: () => API.get('/user/validate_token')
		.catch(error => {
			return { 'code': error?.response?.status };
		}),
};

export default AuthAPI;
