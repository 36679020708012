import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ReactComponent as ViewIcon } from './../../svg/icons/view.svg';
import useGlobalStyles from './../../assets/style/styles';

const PreviewButton = props => {
  const { active, ...rest } = props;
  const globalClasses = useGlobalStyles();
  
  return (
    <IconButton
      className={active ? globalClasses.greenIconButton : globalClasses.iconButton}
      {...rest}
    >
      <ViewIcon />
    </IconButton>
  );
};

PreviewButton.propTypes = {
  active: PropTypes.bool
}

export default PreviewButton;
