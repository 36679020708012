import React from 'react';
import useStyles from './style';
import useGlobalStyles from '../../assets/style/styles';
import clsx from 'clsx';
import { Link, Typography } from '@material-ui/core';

const ContactFooter = () => {
	const classes = useStyles();
	const global_classes = useGlobalStyles();

	return (
		<div className={classes.footer}>
			<Typography className={clsx(global_classes.normal_font, classes.footerText)}>
				Sieć Badawcza Łukasiewicz - Poznański Instytut Technologiczny
			</Typography>
			<Link
				className={clsx(classes.btnRegister, classes.footerLink)}
				href="mailto:kontakt@monza.pit.lukasiewicz.gov.pl">
				kontakt@monza.pit.lukasiewicz.gov.pl
			</Link>
		</div>
	);
};
export default ContactFooter;
