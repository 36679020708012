import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import { Box, Typography } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import Collapse from 'components/Collapse/Collapse';
import CompareTable from 'components/CompareTable/CompareTable';
import { sortData, exportToXlsx, exportToPng, numberStringFixed } from 'utils/functions';

const CrossStockValue = props => {
	const header = [
		{ id: 'name', label: 'Nazwa' },
		{ id: 'index', label: 'Indeks' },
		{ id: 'measure_unit', label: 'Jedn.miary' },
		{ id: 'percentage', label: 'Procent udziału' },
		{ id: 'coefficient_of_variation', label: 'Wsp.zmien.' },
		{ id: 'group', label: 'Grupa krzyżowo' }
	];
	const [data, setData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<Collapse
			defaultOpen
			title={props.category.label}
			content={
				<>
					<Button onClick={handleExport}>Eksportuj do XLS</Button>
					<Button
						onClick={() => exportToPng(props.chartId)}
						style={{ marginLeft: 20 }}>
						Pobierz wykres .PNG
					</Button>

					<Box style={{ paddingTop: 10 }}>
						<Table
							header={header}
							disablePagination={true}
							onSort={o =>
								setData([...sortData(data, o.columnId, o.direction)])
							}>
							{data.map((row, index) => {
								return (
									<Row key={index}>
										<Cell>{row.name}</Cell>
										<Cell>{row.index}</Cell>
										<Cell>{row.measure_unit}</Cell>
										<Cell>{numberStringFixed(row.percentage)}</Cell>
										<Cell>{numberStringFixed(row.coefficient_of_variation)}</Cell>
										<Cell>{row.group}</Cell>
									</Row>
								);
							})}
						</Table>
					</Box>

					<Box>
						<Typography>
							Grupa A - pozycje, które w klasycznym podziale stanowią{' '}
							{props.diff.a}% udziału wartości kryterialnej,
						</Typography>
						<Typography>
							Grupa B - pozycje, które w klasycznym podziale stanowią{' '}
							{props.diff.b}% udziału wartości kryterialnej,
						</Typography>
						<Typography>
							Grupa C - pozycje, które w klasycznym podziale stanowią{' '}
							{props.diff.c}% udziału wartości kryterialnej.
						</Typography>
						<Typography>
							Grupa X - pozycje wydawane w dużych ilościach, o masowym
							charakterze,
						</Typography>
						<Typography>
							Grupa Y - pozycje o średniej wielkości wydań,
						</Typography>
						<Typography>
							Grupa Z - pozycje wydawane sporadycznie, jednostkowo.
						</Typography>
					</Box>

					<Box id={props.chartId}>
						<CompareTable data={data} />
					</Box>
				</>
			}
		/>
	);
};

export default CrossStockValue;
