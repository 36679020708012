import React from 'react';

import { DocumentTableCell, DocumentTableRemove } from './index';

export default function DocumentTableTr(props) {

	const { cells, index, column_sizes, removeRow } = props;

	return (
		<div className='document-table__tr df-ac'>

			{cells.map((cell, i) => (
				<DocumentTableCell key={i} size={column_sizes?.[i]}>
					{cell}
				</DocumentTableCell>
			))}

			<DocumentTableRemove onClick={() => removeRow(index)} />
		</div>
	);
}

