import API from './API';
import { getParamsByOptions } from '../utils/functions';

const WarehouseOperationAPI = {
	getInfo: () => API.get('/info', { params: { 'type': 'warehouse_operations' } }),
	getDates: () => API.get('/info', { params: { 'type': 'warehouse_operations_dates' } }),
	index: options => API.get('/warehouse_operations' + getParamsByOptions(options)),
	export: (options, offset) => API.get('/warehouse_operations/export' + getParamsByOptions(options), { params: { offset } }),
	get: id => API.get(`/warehouse_operations/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/warehouse_operations/${id}`, data) : API.post('/warehouse_operations', { data }),
	delete: id => API.delete(`/warehouse_operations/${id}`),
	create_list: data => API.post('/warehouse_operations/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/warehouse_operations/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option,
		}),
	getChartData: (assortment, warehouse, date_from, date_to, value_quantity, duration) =>
		API.post('/warehouse_operations/chart_data', {
			assortment: assortment,
			warehouse: warehouse,
			date_from: date_from,
			date_to: date_to,
			value_quantity: value_quantity,
			duration: duration,
		}),
};

export default WarehouseOperationAPI;
