import API from './API';
import { getParamsByOptions } from 'utils/functions';

const AssortmentAPI = {
	getInfo: () => API.get('/info', { params: { type: 'assortment' } }),
	getAll: () => API.get('/assortments/all'),
	get: id => API.get(`/assortments/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/assortments/${id}`, data) : API.post('/assortments', data),
	delete: id => API.delete(`/assortments/${id}`),
	index: options => API.get('/assortments' + getParamsByOptions(options)),
	export: options => API.get('/assortments/export' + getParamsByOptions(options), { responseType: 'blob' }),
	create_list: data => API.post('/assortments/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/assortments/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default AssortmentAPI;
