import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	table: {},
	h6right: {
		display: 'flex',
		justifyContent: 'end',
		alignItems: 'center',
		height: '100%',
	},
}));

export default useStyles;
