import API from './API';
import { getParamsByOptions } from './../utils/functions';

const DocumentWZAPI = {
	getInfo: () => API.get('/info', { params: { type: 'document' } }),
	get: id => API.get(`/documents_wz${id ? `/${id}` : ''}`),
	index: options => API.get('/documents_wz' + getParamsByOptions(options)),
	updateOrCreate: (data, id) => id ? API.put(`/documents_wz/${id}`, data) : API.post('/documents_wz', data),
	delete: id => API.delete('/documents_wz/' + id),
	export: options => API.get('/documents_wz/export' + getParamsByOptions(options)),
	import: data => API.post('/documents_wz/list', data)
};

export default DocumentWZAPI;
