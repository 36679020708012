import API from './API';
import { getParamsByOptions } from 'utils/functions';

const OrderAPI = {
	getInfo: () => API.get('/info', { params: { type: 'document' } }),
	get: id => API.get(`/orders${id ? `/${id}` : ''}`),
	getAll: () => API.get('/orders/all'),
	index: options => API.get('/orders' + getParamsByOptions(options)),
	delete: id => API.delete('/orders/' + id),
	updateOrCreate: (data, id) => id ? API.put(`/orders/${id}`, data) : API.post('/orders', data),
	export: options => API.get('/orders/export' + getParamsByOptions(options)),
	import: data => API.post('/orders/list', data)
};

export default OrderAPI;
