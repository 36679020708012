/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Grid, Typography } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import PreviewButton from 'components/misc/PreviewButton';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import WarehouseOperationApi from 'apis/WarehouseOperationAPI';
import mainUtil from 'utils/main';
import moment from 'moment';
import { warehouse_operation_header } from 'utils/xlsx_headers';
import useGlobalStyles from 'assets/style/styles';
import { getParamsByOptions, numberString, numberStringFixed } from 'utils/functions';
import API from 'apis/API';
import { useToasts } from 'react-toast-notifications';
import useStyles from './style';

const WarehouseOperationList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const [header, setHeader] = useState([
		{ id: 'date', label: 'Data', filter: 'period' },
		{
			id: 'assortment',
			label: 'Nazwa asortymentu',
			filter: 'select',
			disableSort: true
		},
		{
			id: 'assortment_group',
			label: 'Grupa',
			filter: 'select',
			disableSort: true
		},
		{
			id: 'measure_unit',
			label: 'Jednostka miary',
			filter: 'select',
			disableSort: true
		},
		{
			id: 'logistic_unit',
			label: 'Jednostka logistyczna',
			filter: 'select',
			disableSort: true
		},
		{
			id: 'warehouse_id',
			label: 'Magazyn',
			filter: 'select',
			disableSort: true
		},
		{ id: 'received', label: 'Wielkość przyjęć', filter: 'text', align: 'right' },
		{ id: 'release', label: 'Wielkość wydań', filter: 'text', align: 'right' },
		{ id: 'order', label: 'Wielkość zamówień', filter: 'text', align: 'right' },
		{ id: 'stock', label: 'Zapas [j.m.]', filter: 'text', align: 'right' },
		{ id: 'handling_delivery_cost', label: 'Koszt obsługi i dostawy', filter: 'text', align: 'right' },
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	]);
	const globalClasses = useGlobalStyles();
	// const [listInfo, setListInfo] = useState({});
	const [MeasurementUnits, setMeasurementUnits] = useState({});
	const [logisticUnits, setLogisticUnits] = useState({});
	const [loadingInfo, setLoadingInfo] = useState(true);

	const handleEdit = id => {
		history.push(PATHS.WarehouseOperationEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			WarehouseOperationApi.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleExport = () => {
		setLoading(true);
		API.get('/warehouse_operations/export' + getParamsByOptions(options), {
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Operacje magazynowe.xlsx');
			document.body.appendChild(link);
			setLoading(false);
			link.click();
		}).catch(error => {
			setLoading(false);
			addToast('Błąd pobierania pliku.', { appearance: 'error' });
		});
	};

	const handlePreview = index => {
		let rows = JSON.parse(JSON.stringify(data));
		rows[index].preview = !rows[index].preview;
		setData(rows);
	};

	const getData = () => {
		setLoading(true);
		WarehouseOperationApi.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		getFilters();
	}, []);

	const getFilters = () => {
		setLoadingInfo(true);
		WarehouseOperationApi.getInfo().then(res => {
			setHeader([
				...header.slice(0, 1),
				{ ...header[1], list: mainUtil.sortObjArray(res.data.assortment, 'name') },
				{ ...header[2], list: mainUtil.sortObjArray(res.data.assortment_group, 'name') },
				{ ...header[3], list: mainUtil.sortObjArray(res.data.measure_unit, 'name') },
				{ ...header[4], list: mainUtil.sortObjArray(res.data.logistic_unit, 'name') },
				{ ...header[5], list: mainUtil.sortObjArray(res.data.warehouse, 'name') },
				...header.slice(6)
			]);

			let items = {};

			for (let item of res.data.measure_unit) {
				items[item.id] = item.name;
			}
			setMeasurementUnits(items);

			items = {};
			for (let item of res.data.logistic_unit) {
				items[item.id] = item.name;
			}
			setLogisticUnits(items);

			setLoadingInfo(false);
		});
	};

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nową operację',
					// path: PATHS.WarehouseOperationCreate
					path: false
				}}
				generate_button={{
					label: 'Generuj wykres',
					handler: () => history.push(PATHS.WarehouseOperationGenerate)
				}}
				// importHandler={handleImport}
				exportHandler={handleExport}
				header_list={warehouse_operation_header}
			/>

			<Breadcrumbs list={['Dane podstawowe', 'Operacje magazynowe']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading || loadingInfo}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{moment(row.date).format('DD.MM.YYYY')}</Cell>
								<Cell>{row.assortment_item?.name}</Cell>
								<Cell>{row.assortment_item?.assortment_group_name}</Cell>
								<Cell>{MeasurementUnits[row.measure_unit]}</Cell>
								<Cell>{logisticUnits[row.logistic_unit]}</Cell>
								<Cell>{row.warehouse_name}</Cell>
								<Cell align="right">{numberString(row.received)}</Cell>
								<Cell align="right">{numberString(row.release)}</Cell>
								<Cell align="right">{numberString(row.order)}</Cell>
								<Cell align="right">{numberString(row.stock)}</Cell>
								<Cell align="right">{numberStringFixed(row.handling_delivery_cost)}</Cell>
								<Cell align="center" width={180}>
									<PreviewButton
										active={!!row.preview}
										onClick={() => handlePreview(index)}
									/>
									{/*<EditButton onClick={() => handleEdit(row.id)} />*/}
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>
							{row.preview && (
								<Row>
									<Cell colSpan={14}>
										<Grid
											container
											spacing={2}
											justifyContent="space-around"
											style={{ padding: '10px 50px' }}>
											<Grid item xs={6}>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															className={classes.h6right}>
															Liczba przyjęć [-]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={row.received_number}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															className={classes.h6right}>
															Wartość przyjęć [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={numberStringFixed(row.received_value)}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															className={classes.h6right}>
															Wartość zapasu [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={numberStringFixed(row.stock_value)}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6}>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															className={classes.h6right}>
															Liczba wydan [-]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={row.release_number}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															className={classes.h6right}>
															Wartość wydań [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={numberStringFixed(row.releases_value)}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															className={classes.h6right}>
															Wartość zamówień [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={numberString(row.order_value)}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Cell>
								</Row>
							)}
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default WarehouseOperationList;
