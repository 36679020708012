/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Breadcrumbs, FormInput, Loader, SingleDetail } from 'components';
// import useStyles from './style';
import { Grid } from '@material-ui/core';
import AssortmentApi from 'apis/AssortmentAPI';
import PATHS from 'routes/paths';
import { useToasts } from 'react-toast-notifications';
import { commaToDot, dotToComma } from 'utils/functions';
import { useHistory, useParams } from 'react-router-dom';

const Assortment = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [listInfo, setListInfo] = useState({});

	useEffect(() => {
		axios.all([
			id ? AssortmentApi.get(id) : null,
			AssortmentApi.getInfo(),
		]).then(axios.spread((item, info) => {
			item?.data && setData(item.data);
			info?.data && setListInfo(info.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		switch (name){
			case 'inventory_cost_factor':
			case 'purchase_price':
			case 'sale_price':
				value = commaToDot(value);
				break;
		}
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		if (data.gtin?.length) {
			if (data.gtin.length < 8) {
				addToast('Zbyt krótki numer GTIN - minimum 8 cyfr.', {
					appearance: 'error'
				});
				return;
			}
			if (data.gtin.length > 13) {
				addToast('Zbyt długi numer GTIN - max 13 cyfr.', {
					appearance: 'error'
				});
				return;
			}
		}
		if (!data.name?.length) {
			addToast('Nazwa asortymentu nie może być pusta.', {
				appearance: 'error'
			});
			return;
		}
		if (!data.index?.length) {
			addToast('Indeks nie może być pusty.', {
				appearance: 'error'
			});
			return;
		}
		setLoading(true);
		AssortmentApi.updateOrCreate(data, id).then(response => {
			if ([200,201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AssortmentList), 100);
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		AssortmentApi.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AssortmentList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Dane podstawowe', { label: 'Asortyment', path: PATHS.AssortmentList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.AssortmentList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane dotyczące asortymentu"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Nazwa asortymentu"
							name="name"
							type="input"
							value={data.name}
							handleChange={handleChange}
						/>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Indeks"
									name="index"
									type="input"
									value={data.index}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="GTIN"
									name="gtin"
									type="input"
									value={data.gtin}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Jednostka miary"
									name="measure_unit"
									type="single"
									value={data.measure_unit}
									list={listInfo.measurement_unit_list?.filter(i => i.type === 2)}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Jednostka logistyczna"
									name="logistic_unit"
									type="single"
									value={data.logistic_unit}
									list={listInfo.measurement_unit_list?.filter(i => i.type === 1)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<FormInput
							title="Aktywny"
							name="active"
							type="check_box"
							value={data.active}
							handleChange={handleChange}
						/>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Cena zakupu [PLN]"
									name="purchase_price"
									type="number"
									value={dotToComma(data.purchase_price)}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Cena sprzedaży [PLN]"
									name="sale_price"
									type="number"
									value={dotToComma(data.sale_price)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormInput
									title="Grupa asortymentowa"
									name="assortment_group"
									type="single"
									value={data.assortment_group}
									list={listInfo.assortment_group_list}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Prawdopodobieństwo Obsługi popytu [%]"
									name="service_demand"
									type="number"
									value={data.service_demand}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Czas cyklu uzupełniania [dni]"
									name="refill_cycle_time"
									type="number"
									value={data.refill_cycle_time}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Odchylenie czasu cyklu uzupełnienia [dni]"
									name="cycle_time_deviations"
									type="number"
									value={data.cycle_time_deviations}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Współczynnik kosztu utrzymania zapasu [-]"
									name="inventory_cost_factor"
									type="number"
									value={dotToComma(data.inventory_cost_factor)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Dostawca"
									name="supplier"
									type="single"
									value={data.supplier}
									list={listInfo.supplier_list}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Odbiorca"
									name="recipient"
									type="single"
									value={data.recipient}
									list={listInfo.recipient_list}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default Assortment;
