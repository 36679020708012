/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	makeStyles,
	Box,
	Table,
	Checkbox,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	TableFooter,
	TextField,
	Select,
	FormControl,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button, debounce
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import DatePicker from 'components/FormInput/MyDatePicker';
import DateRangePicker from 'components/FormInput/MyDateRangePicker';
import Loader from 'components/Loader';
import InfoBox from './InfoBox';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiTableCell-root': {
			padding: 8
		},
		'& .MuiTableCell-footer, & .MuiPaginationItem-root': {
			fontSize: '1em'
		},
		'& .MuiPagination-ul': {
			justifyContent: 'flex-end'
		}
	},
	dialog: {
		'& .MuiDialog-paper': {
			width: 400,
			'& .MuiDialogActions-root': {
				paddingBottom: 20,
				justifyContent: 'space-evenly',
				'& .MuiButton-root': {
					paddingLeft: 30,
					paddingRight: 30
				}
			}
		}
	}
}));

const MyTable = props => {
	const classes = useStyles();
	const {
		header,
		children,
		rowsCount,
		rowsPerPage,
		onChange,
		onSort,
		sortOnly,
		deleteId,
		onDelete,
		deleteMessage = 'Czy na pewno usunąć wybraną pozycję?',
		loading,
		disablePagination
	} = props;

	const [sort, setSort] = useState({});
	const [page, setPage] = useState(1);
	const [hasFilter, setHasFilter] = useState(false);

	let initialFilters = { id: '' };
	for (let head of header) {
		if (head.filter) {
			initialFilters[head.id] = '';
			if (!hasFilter) {
				setHasFilter(true);
			}
		}
	}
	const [filters, setFilters] = useState(initialFilters);

	const handleChange = () => {
		if (onChange) {
			onChange({ sort: sort, page: page, filters: filters });
		}
	};
	const handleDelete = okBtn => {
		onDelete(okBtn);
	};
	const handleSort = index => {
		let option = {
			column: index,
			columnId: header[index].id,
			direction: !sort.direction
		};
		setSort(option);
		if (onSort) {
			onSort(option);
		}
	};

	const debounceFilter = debounce((id, event) => {
		if (event.target.type === 'checkbox') {
			setFilters({ ...filters, [id]: event.target.checked });
		} else {
			setFilters({ ...filters, [id]: event.target.value });
		}
	}, 600);

	const handleFilter = (id, event) => {
		event.persist();
		debounceFilter(id, event);
	};
	const handleDateFilter = (id, ymd) => {
		setFilters({ ...filters, [id]: ymd });
	};
	const handlePeriodFilter = (id, dates) => {
		setFilters({ ...filters, [id]: dates ? `${dates[0]},${dates[1]}` : null });
	};

	useEffect(() => {
		handleChange();
	}, [filters, sort, page]);

	return (
		<>
			<Table className={classes.root}>
				<TableHead>
					<TableRow>
						{header.map((head, index) => {
							return (
								<TableCell key={index} align={head.align}>
									{!!head.info && <InfoBox data={head.info} />}
									{head.disableSort || (sortOnly.length && !sortOnly.includes(header[index].id)) ? (
										<>{head.label}</>
									) : (
										<TableSortLabel
											active={sort.column === index}
											direction={sort.direction ? 'asc' : 'desc'}
											onClick={() => handleSort(index)}
											style={{ paddingRight: !!head.info ? 20 : 0 }}>
											{head.label}
										</TableSortLabel>
									)}
								</TableCell>
							);
						})}
					</TableRow>
					{hasFilter && (
						<TableRow>
							{header.map((head, index) => {
								return (
									<TableCell
										key={index}
										align={head.align}
										style={{ paddingLeft: 5, paddingRight: 5 }}>
										{!head.filter && <Box></Box>}
										{head.filter === 'text' && (
											<TextField
												variant="outlined"
												size="small"
												fullWidth
												onChange={e => handleFilter(head.id, e)}
											/>
										)}
										{head.filter === 'select' && (
											<FormControl variant="outlined" size="small" fullWidth>
												<Select
													native
													onChange={e => handleFilter(head.id, e)}
													inputProps={{ 'aria-label': 'Without label' }}>
													<option value="">Wybierz opcję</option>
													{head.list &&
														head.list.map((item, ind) => {
															return (
																<option
																	key={ind}
																	value={
																		item[
																			head.filterOptionKey
																				? head.filterOptionKey
																				: 'id'
																		]
																	}>
																	{
																		item[
																			head.filterOptionText
																				? head.filterOptionText
																				: 'name'
																		]
																	}
																</option>
															);
														})}
												</Select>
											</FormControl>
										)}
										{head.filter === 'date' && (
											<DatePicker
												isClearable={true}
												onChange={update => {
													handleDateFilter(head.id, update);
												}}
											/>
										)}
										{head.filter === 'period' && (
											<div style={{ minWidth: 130 }}>
												<DateRangePicker
													isClearable={true}
													onChange={update => {
														handlePeriodFilter(head.id, update);
													}}
												/>
											</div>
										)}
										{head.filter === 'check' && (
											<Checkbox
												// checked={checked}
												onChange={e => handleFilter(head.id, e)}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										)}
										{head.filter === 'radio' && <TextField />}
									</TableCell>
								);
							})}
						</TableRow>
					)}
				</TableHead>
				<TableBody>
					{children.length ? children : (!loading &&
						<TableRow>
							<TableCell colSpan={header.length}>
								<Typography align="center">
									Brak wyników
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
				{!disablePagination && (
					<TableFooter>
						<TableRow>
							<TableCell colSpan={header.length}>
								{!!rowsCount && (
									<Typography>
										Strona {page} z {Math.ceil(rowsCount / rowsPerPage)} stron.
									</Typography>
								)}
								<Pagination
									count={Math.ceil(rowsCount / rowsPerPage)}
									onChange={(e, page) => setPage(page)}
									page={page}
								/>
							</TableCell>
						</TableRow>
					</TableFooter>
				)}
			</Table>

			{loading && <Loader />}

			{deleteId && (
				<Dialog
					className={classes.dialog}
					open={!!deleteId}
					onClose={e => handleDelete(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Potwierdź!</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{deleteMessage}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							onClick={e => handleDelete(true)}>
							Tak
						</Button>
						<Button variant="outlined" onClick={e => handleDelete(false)}>
							Nie
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
MyTable.propTypes = {
	deleteId: PropTypes.number,
	deleteMessage: PropTypes.string,
	disablePagination: PropTypes.bool,
	header: PropTypes.array,
	loading: PropTypes.bool,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	onSort: PropTypes.func,
	rowsCount: PropTypes.number,
	rowsPerPage: PropTypes.number,
	sortOnly: PropTypes.array,
};
MyTable.defaultProps = {
	sortOnly: [],
};

export default MyTable;
