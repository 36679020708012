import React from 'react';
import './document-box.scss';

const DocumentBox = props => {

	const { heading, vh, extra_classes, box_height, children } = props;
	const heading_style = props.heading_style || {};

	const main_box_classes = ['document-box'];
	vh && main_box_classes.push('vertical-heading');
	extra_classes && main_box_classes.push(extra_classes);

	return (
		<div className={main_box_classes.join(' ')} style={{ height: box_height || 'auto' }}>
			<div className='document-box__heading' style={{
				width: vh ? box_height - 2 : 'auto',
				...heading_style
			}}>
				{heading}
			</div>

			<div className='document-box__content'>
				{children}
			</div>
		</div>
	);
};


export default DocumentBox;
