import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const Chart = props => {
	const { data } = props;

	const colors = ['#44d62c', '#ff0000', '#d0df00', '#ff7fcb'];

	const chartData = {
		labels: [''],
		datasets: data.map((item, index) => {
			return {
				data: [item.y],
				label: [item.label],
				backgroundColor: colors[index],
				borderColor: colors[index],
				borderWidth: 1
			};
		})
	};

	return (
		<div
			style={{
				width: 800,
				height: 400,
				margin: '0 auto',
				backgroundColor: 'white'
			}}>
			{chartData && (
				<Bar
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							// legend: { display: false }
						},
						scales: {
							x: {
								stacked: true
							},
							y: {
								stacked: true
							}
						}
					}}
				/>
			)}
		</div>
	);
};

export default Chart;
