import API from './API';
import { getParamsByOptions } from '../utils/functions';

const AnalyzeXyzAPI = {
	getInfo: () => API.get('/info', { params: { type: 'assortment' } }),
	get: id => API.get(`/analyzes${id ? `/${id}` : ''}`),
	updateOrCreate: (data, id) => id ? API.put(`/analyzes/${id}`, data) : API.post('/analyzes', data),
	delete: id => API.delete(`/analyzes/${id}`),
	index: options => API.get('/analyzes' + getParamsByOptions(options)),
	export: () => API.get('/analyzes/export'),
	create_list: data => API.post('/analyzes/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/analyzes/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default AnalyzeXyzAPI;
