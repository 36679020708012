/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from 'components';
import useStyles from './style';
import { Table, Row, Cell } from 'components/MyTable';
import { useToasts } from 'react-toast-notifications';
import PATHS from 'routes/paths';
import AdminPlanAPI from 'apis/AdminPlanAPI';
import TopButtonsBox from 'components/TopButtonsBox';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';

const AdminPlanList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const header = [
		// { id: 'id', label: 'ID', filter: 'text' },
		{ id: 'name', label: 'Plan', disableSort: true },
		{
			id: 'type', label: 'Typ', filter: 'select', list: [
				{ id: 'personal', name: 'indywidualny' },
				{ id: 'group', name: 'grupowy' },
			]
		},
		{
			id: 'period', label: 'Okres', filter: 'select', list: [
				{ id: 'month', name: '1 miesiąc' },
				{ id: 'semester', name: '5 miesięcy' },
				{ id: 'year', name: '12 miesięcy' },
			]
		},
		{ id: 'users_nb', label: 'Liczba użytkowników', filter: 'text' },
		{ id: 'price', label: 'Cena', filter: 'text' },
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	];

	const handleEdit = id => {
		history.push(PATHS.AdminPlanEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			AdminPlanAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const getData = () => {
		setLoading(true);
		AdminPlanAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nowy plan',
					path: PATHS.AdminPlanCreate,
				}}
			/>

			<Breadcrumbs list={['Administracja', 'Plany abonamentowe']}/>

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{row.name}</Cell>
								<Cell>{row.type_name}</Cell>
								<Cell>{row.period_name}</Cell>
								<Cell>{row.users_nb}</Cell>
								<Cell>{row.price}</Cell>
								<Cell align="center" width={120}>
									<EditButton onClick={() => handleEdit(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default AdminPlanList;
