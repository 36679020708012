import React from 'react';
import { isFunction } from 'utils/functions';
import './button.scss';

const Button = props => {
	return (
		<a
			href="/#"
			className="button"
			{...props}
			onClick={e => {
				e.preventDefault();
				isFunction(props.onClick) && props.onClick();
			}}>
			{props.children}
		</a>
	);
};

export default Button;
