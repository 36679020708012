/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Table, Row, Cell } from 'components/MyTable';
import CheckMark from 'components/misc/CheckMark';
import DetailButton from 'components/misc/DetailButton';
import TrashButton from 'components/misc/TrashButton';
import moment from 'moment';
import StockAnalyzeAPI from 'apis/StockAnalyzeAPI';
import useStyles from './style';
import { useToasts } from 'react-toast-notifications';

const StockAnalyzeList = () => {
	const history = useHistory();
	const { addToast } = useToasts();
	const classes = useStyles();

	const rowsPerPage = 20;

	const header = [
		{ id: 'name', label: 'Nazwa', filter: 'text' },
		{
			id: 'structure_analysis',
			label: 'Analiza struktury',
			filter: 'check',
			disableSort: true,
			align: 'center'
		},
		{
			id: 'ratio_analysis',
			label: 'Analiza wskaźnikowa',
			filter: 'check',
			disableSort: true,
			align: 'center'
		},
		{
			id: 'cost_analysis',
			label: 'Analiza kosztu',
			filter: 'check',
			disableSort: true,
			align: 'center'
		},
		{ id: 'range_from', label: 'Zakres od', filter: 'date', align: 'center' },
		{ id: 'range_to', label: 'Zakres do', filter: 'date', align: 'center' },
		{ id: 'warehouses_group', label: 'Grupa magazynów', filter: 'text' },
		{ id: 'description', label: 'Opis', filter: 'text' },
		{
			id: 'updated_at',
			label: 'Data wykonania',
			filter: 'date',
			align: 'center'
		},
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	];
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(false);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			StockAnalyzeAPI.delete(deleteId).then(response => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleGoToResult = id => {
		history.push(PATHS.StockAnalyzeResult(id));
	};

	const getData = () => {
		setLoading(true);
		StockAnalyzeAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	return (
		<>
			<TopButtonsBox
				add_button={{
					path: PATHS.StockAnalyzeCreate,
					label: 'Dodaj nową analizę'
				}}
				importHandler={false}
				exportHandler={false}
			/>

			<Breadcrumbs list={['Analizy', 'Analiza zapasów']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<Row key={index}>
							<Cell>{row.name}</Cell>
							<Cell align="center">
								<CheckMark checked={row.structure_analysis} />
							</Cell>
							<Cell align="center">
								<CheckMark checked={row.ratio_analysis} />
							</Cell>
							<Cell align="center">
								<CheckMark checked={row.cost_analysis} />
							</Cell>
							<Cell align="center">
								{moment(row.range_from).format('DD/MM/YYYY')}
							</Cell>
							<Cell align="center">
								{moment(row.range_to).format('DD/MM/YYYY')}
							</Cell>
							<Cell>{row.warehouses_group_names}</Cell>
							<Cell>{row.description}</Cell>
							<Cell align="center">
								{moment(row.updated_at).format('DD/MM/YYYY')}
							</Cell>
							<Cell align="center" style={{ display: 'flex' }}>
								<DetailButton onClick={() => handleGoToResult(row.id)} />
								<TrashButton onClick={() => setDeleteId(row.id)} />
							</Cell>
						</Row>
					);
				})}
			</Table>
		</>
	);
};

export default StockAnalyzeList;
