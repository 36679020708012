/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Link, Typography } from '@material-ui/core';
import useStyles from './style';
import useGlobalStyles from 'assets/style/styles';
import { ProgressBar } from 'components';
import auth from 'apis/AuthAPI';
import constants from 'utils/constants';
import clsx from 'clsx';
import { useToasts } from 'react-toast-notifications';
import PATHS from 'routes/paths';
import ContactFooter from '../SignIn/ContactFooter';

const Forgot = () => {
	const history = useHistory();

	const classes = useStyles();
	const global_classes = useGlobalStyles();
	const [input, setInput] = useState({});
	const [error, setError] = useState({});

	// const [hasAlert, setHasAlert] = useState(false);
	// const [isSuccess, setIsSuccess] = useState(false);
	// const [message, setMessage] = useState('');
	const [progressStatus, setProgressStatus] = useState(false);
	const { addToast } = useToasts();

	useEffect(() => {

	}, []);

	const handleChange = event => {
		let arr = JSON.parse(JSON.stringify(input));
		arr[event.target.name] = event.target.value;
		setInput(arr);
	};

	const handleForgot = event => {
		if ((error && (error.email && error.email.length > 0)) || !input.email) {
			addToast(constants.CHECK_ALL_FIELDS, { appearance: 'warning', autoDismissTimeout: 5000, autoDismiss: true });
		} else {
			setProgressStatus(true);
			auth
				.forgot(input.email)
				.then(response => {
					setProgressStatus(false);
					let appearance = 'warning';
					if (response.code === 200) {
						appearance = 'info';
						setTimeout(() => history.push(PATHS.Login), 100);
					}
					addToast(response.message, { appearance, autoDismissTimeout: 5000 });
				});
		}
	};

	const handleKeyPress = (event) => {
		if (event.charCode === 13) {
			handleForgot();
		}
	};

	useEffect(() => {
		let arr = JSON.parse(JSON.stringify(error));
		var pattern = new RegExp(/^[-!#$%&'*+/\d=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/\d=?A-Z^_a-z`{|}~])*@[a-zA-Z\d](-*\.?[a-zA-Z\d])*\.[a-zA-Z](-?[a-zA-Z\d])+$/i);
		if (input['email'] && !pattern.test(input['email'])) {
			arr['email'] = constants.ENTER_VALID_EMAIL;
		} else {
			arr['email'] = '';
		}

		setError(arr);
	}, [input]);
	return (
		<>
			<div className={classes.root}>
				<div className={classes.loginForm}>
					<Typography variant={'h2'} className={clsx(classes.description, global_classes.normal_font)}>
						Podaj swój adres e-mail, aby zresetować hasło
					</Typography>
					<div className={classes.form}>
						<div className={classes.input_box_label}><label htmlFor='email'>E-mail</label></div>
						<input className={classes.input_box} aria-label='e-mail' type='email' value={input.email} name='email'
									 id='email' onChange={handleChange} onKeyPress={handleKeyPress} />
						<div className={classes.error_log}>{error['email'] && error['email'].length > 0 && error.email}</div>
						<div className={classes.buttonContainer}>
							<Button variant='outlined' className={clsx(classes.btnForgot, global_classes.outline_button)}
											onClick={handleForgot}>
								Wyślij
							</Button>
							<Link to={PATHS.Login} component={RouterLink} className={classes.btnBack}>
								Wróć do poprzedniej strony
							</Link>
						</div>
					</div>

					<ContactFooter />
				</div>
			</div>
			<ProgressBar progressStatus={progressStatus} />
		</>
	);
};

Forgot.propTypes = {
	history: PropTypes.object
};

export default withRouter(Forgot);
