import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
	colors,
	Checkbox,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Switch,
	TextareaAutosize,
	Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { SingleSelect } from 'components';
import OutlineButton from 'components/OutlineButton';
import clsx from 'clsx';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { pl } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';

const materialTheme = createTheme({
	overrides: {
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: lightGreen.A200
			}
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				// backgroundColor: lightGreen.A200,
				// color: "white",
			}
		},
		MuiPickersDay: {
			day: {
				color: '#000000'
			},
			daySelected: {
				backgroundColor: lightGreen['400']
			},
			dayDisabled: {
				color: lightGreen['100']
			},
			current: {
				color: lightGreen['900'],
				fontWeight: 'bold',
				backgroundColor: lightGreen['100']
			}
		},
		MuiPickersModal: {
			dialogAction: {
				color: lightGreen['400']
			}
		}
	}
});

const useStyles = makeStyles(theme => ({
	main: {
		width: '100%'
	},
	required: {
		color: colors.red[600],
	},
	title: {
		fontWeight: 400,
		fontSize: '0.875em',
		margin: theme.spacing(1.5, 0, 0.5, 0)
	},
	input_box: {
		width: '100%',
		padding: theme.spacing(1),
		border: '1px solid #ccc',
		backgroundImage: 'none',
		borderRadius: 3,
		height: 38,
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 14
	},
	check_box: {
		marginTop: theme.spacing(1.5),
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.black
		}
	},
	error: {
		border: '1px solid red'
	},
	date_picker: {
		width: '100%',
		marginTop: 0,
		'&.MuiFormControl-marginNormal': {
			margin: theme.spacing(1, 0)
		}
	}
}));

const FormInput = props => {
	const {
		name,
		type,
		title,
		row,
		list = [],
		value = '',
		min,
		max,
		handleChange,
		error,
		disabled,
		placeholder,
		button_title,
		style,
		required = false,
		...otherProps
	} = props;

	const classes = useStyles();

	const getRemainList = _value => {
		let _result = [];
		list.map((item, index) => {
			let count = 0;
			value.map(sub => {
				if (
					Number(item.id) === Number(sub) &&
					Number(item.id) !== Number(_value)
				)
					count++;
				return null;
			});
			if (count === 0) {
				_result.push(item);
			}
			return null;
		});
		return _result;
	};

	const handleAddItem = () => {
		let _value = JSON.parse(JSON.stringify(value));
		if (value.length !== list.length) {
			_value.push(0);
			handleChange(name, _value);
		}
	};

	const handleChangeItem = (index, _value) => {
		let result = JSON.parse(JSON.stringify(value));
		result[index] = _value;
		handleChange(name, result);
	};

	const handleDeleteItem = index => {
		let result = JSON.parse(JSON.stringify(value));
		result.splice(index, 1);
		handleChange(name, result);
	};

	const handleCheckRegex = (_name, _value) => {
		var fixedInput = _value.replace(
			// eslint-disable-next-line
			/[A-Za-z!@#$%^&*()ążźćśę€ółńĄĘŚŻŹŃŁÓ=\[\]\/\'";:<>\-_+\{}?|\\`~]/g,
			// /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/g,
			''
		);
		handleChange(_name, fixedInput);
	};

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
			<div className={classes.main}>
				{type !== 'check_box' && title !== null && (
					<Typography variant="h5" className={classes.title}>
						{title}{required && <b className={classes.required}> *</b>}
					</Typography>
				)}
			</div>
			<div>
				{type === 'input' && (
					<input
						className={classes.input_box}
						type="text"
						name={name}
						value={value}
						onChange={e => handleChange(name, e.target.value)}
						disabled={disabled}
						placeholder={placeholder}
						{...otherProps}
					/>
				)}
				{type === 'postal_code' && (
					<input
						className={clsx({
							[classes.input_box]: true,
							[classes.error]: error
						})}
						name={name}
						type="text"
						value={value}
						onChange={e => handleChange(name, e.target.value)}
						disabled={disabled}
						{...otherProps}
					/>
				)}
				{type === 'number' && (
					<input
						className={clsx({
							[classes.input_box]: true,
							[classes.error]: error
						})}
						type="text"
						name={name}
						pattern=""
						value={value}
						onChange={e => handleCheckRegex(name, e.target.value)}
						disabled={disabled}
						{...otherProps}
					/>
				)}
				{type === 'number_switch' && (
					<input
						className={clsx({
							[classes.input_box]: true,
							[classes.error]: error
						})}
						type="number"
						name={name}
						pattern=""
						value={value}
						min={min}
						max={max}
						onChange={e => handleCheckRegex(name, e.target.value)}
						disabled={disabled}
						{...otherProps}
					/>
				)}
				{type === 'single' && (
					<SingleSelect
						value={value}
						handleChange={value => handleChange(name, value)}
						list={list}
						disabled={disabled}
						{...otherProps}
					/>
				)}
				{type === 'single_without_empty' && (
					<SingleSelect
						value={value}
						handleChange={value => handleChange(name, value)}
						list={list}
						disabled={disabled}
						empty={true}
						{...otherProps}
					/>
				)}
				{type === 'radio' && (
					<RadioGroup
						value={value}
						row={row}
						onChange={e => handleChange(name, e.target.value)}
						disabled={disabled}>
						{list.map((item, index) => (
							<FormControlLabel
								value={item.value}
								key={index}
								control={<Radio />}
								label={item.label}
								disabled={disabled}
							/>
						))}
					</RadioGroup>
				)}
				{type === 'date' && (
					<ThemeProvider theme={materialTheme}>
						<KeyboardDatePicker
							disabled={disabled}
							disableToolbar
							inputVariant="outlined"
							className={classes.date_picker}
							variant="inline"
							format="dd.MM.yyyy"
							margin="normal"
							value={value}
							size="small"
							style={style}
							onChange={value => handleChange(name, value)}
						/>
					</ThemeProvider>
				)}
				{type === 'check_box' && (
					<FormControlLabel
						disabled={disabled}
						className={classes.check_box}
						control={
							<Checkbox
								checked={Number(value) === 1 || value === true}
								onChange={e => handleChange && handleChange(name, !value)}
							/>
						}
						label={title}
					/>
				)}
				{type === 'switch' && (
					<>
						<FormControlLabel
							control={
								<Switch
									checked={Number(value) === 1 || value === true}
									onChange={e => handleChange && handleChange(name, !value)}
								/>
							}
							label={title}
						/>
					</>
				)}
				{type === 'area' && (
					<TextareaAutosize
						className={classes.input_box}
						disabled={disabled}
						minRows={10}
						value={value}
						onChange={e => handleChange(name, e.target.value)}
						{...otherProps}
					/>
				)}
				{type === 'several_single' && (
					<React.Fragment>
						<Grid container spacing={2}>
							{value.map((item, index) => (
								<React.Fragment key={index}>
									<Grid item xs={10}>
										<SingleSelect
											value={item}
											handleChange={_value => handleChangeItem(index, _value)}
											list={getRemainList(item)}
											disabled={disabled}
										/>
									</Grid>
									<Grid item xs={2}>
										<OutlineButton
											icon={<DeleteIcon />}
											onClick={() => handleDeleteItem(index)}
											disabled={disabled}
										/>
									</Grid>
								</React.Fragment>
							))}
						</Grid>
						<Grid container justifyContent="flex-end" spacing={2}>
							<Grid item>
								<OutlineButton
									title={button_title}
									onClick={handleAddItem}
									disabled={value.length === list.length || disabled}
								/>
							</Grid>
						</Grid>
					</React.Fragment>
				)}
				{type === 'password' && (
					<input
						className={clsx({
							[classes.input_box]: true,
							[classes.error]: error
						})}
						type="password"
						name={name}
						value={value}
						onChange={e => handleChange(name, e.target.value)}
						disabled={disabled}
						{...otherProps}
					/>
				)}
			</div>
		</MuiPickersUtilsProvider>
	);
};

export default FormInput;
