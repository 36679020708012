import EXCEL from 'js-export-xlsx';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import util from 'utils/main';

const getDetectVariableTypeFunc = type => {
	return v => typeof v === type.toLowerCase();
};

export const ucFirst = str => str.substr(0, 1).toUpperCase() + str.substr(1);

export const isFunction = getDetectVariableTypeFunc('Function');

export const isObject = getDetectVariableTypeFunc('Object');

export const isNumber = getDetectVariableTypeFunc('Number');

export const isString = getDetectVariableTypeFunc('String');

export const addZeroIfNeeded = num => (num <= 9 ? `0${num}` : num);

export const funcDelayDecorator = function(fn, delay = 300) {
	let timer;

	return function() {
		clearTimeout(timer);
		timer = setTimeout(() => fn(...arguments), delay);
	};
};

export const groupByFirstLetter = (data, calName = 'name') => {
	let options = data.reduce((r, e) => {
		let firstLetter = e[calName][0].toUpperCase();
		if (!r[firstLetter]) r[firstLetter] = { label: firstLetter, options: [e] };
		else r[firstLetter].options.push(e);
		return r;
	}, {});
	return Object.values(options);
};

export const getOptionsForAutocomplete = (data, name_param = 'name') =>
	data.map(option => {
		const firstLetter = option[name_param][0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
			...option
		};
	});

export const getPriceZlAndGr = price => {
	price = Math.round(+price * 100) / 100;
	return isNaN(price) ? ['---', '---'] : (
		price ? price.toFixed(2).split('.') : [0, '00']
	);
};

export const getParamsByOptions = options => {
	let url = `?page=${options.page ? options.page : ''}`;
	if (options?.sort?.column > -1) {
		url += `&sort=${options.sort.direction ? '-' : ''}`;
		url += options.sort.columnId;
	}
	Object.keys(options.filters).forEach(filterKey => {
		const filterValue = (options.filters[filterKey] + '').replace('+', '%2B');
		if (filterValue) url += `&filter[${filterKey}]=${filterValue}`;
	});
	return url;
};

export const sortData = (data, col, direction = true) => {
	// determine type automagically
	let types = { 'string': 0, 'number': 0 };
	data.map(row => {
		if (isNaN(+row[col])) {
			types['string'] += 1;
		} else {
			types['number'] += 1;
		}
	});
	const type = Object.entries(types).sort(([, a], [, b]) => b - a)[0][0];

	return data.sort((a, b) => {
		let v1 = a[col], v2 = b[col];
		if (type === 'number') {
			v1 = +v1;
			v2 = +v2;
			if (direction) {
				if (v1 < v2) return 1;
				else return -1;
			} else {
				if (v1 > v2) return 1;
				else return -1;
			}
		} else {
			return (direction ? 1 : -1) * toString(v1).localeCompare(toString(v2), undefined, {
				numeric: true,
				sensitivity: 'base'
			});
		}
	});
};

export function clone(input, emptyValue = {}) {
	switch (input) {
		case undefined:
		case null:
			return emptyValue;
		default:
			return JSON.parse(JSON.stringify(input)); // safe deep copy
	}
}

export function dotGet(obj, attributePath) {
	attributePath = attributePath ? attributePath + '' : '';
	return attributePath.split('.').reduce((a, b) => a ? a[b] : undefined, obj);
}

export const exportToXlsx = (header, rows, filename = 'Document') => {
	header = header.filter(i => !i.disableExport);
	let data = [];
	for (let row of rows) {
		let item = [];
		for (let head of header) {
			let val = dotGet(row, head.id);
			if (head.type === 'number') val = util.convertStrToNum(val);
			else if (head.type === 'date') val = moment(val).format(head.format);
			else if (head.type === 'bool') val = !!val ? 'TAK' : 'NIE';
			else if (head.type === 'calculate')
				val =
					util.convertStrToNum(row[head.first]) *
					util.convertStrToNum(row[head.second]);
			item.push(val);
		}
		data.push(item);
	}
	EXCEL.outPut({
		header: header.map(head => head.label),
		data: data,
		name: filename
	});
};

export const exportToPng = (id, filename = 'Image') => {
	if (document.getElementById(id)) {
		domtoimage
			.toBlob(document.getElementById(id))
			.then(function(blob) {
				window.saveAs(blob, `${filename}.png`);
			});
	}
};

export const numberStringFixed = (number, digits = 2) => {
	return (+number).toFixed(digits).replace('.', ',');
};

export const toString = value => {
	if (value === null) return '';
	if (value === false) return '';
	if (value === undefined) return '';
	return value + '';
};

export const replace = (value, search, replace) => toString(value).replace(search, replace);
export const commaToDot = value => replace(value, ',', '.');
export const dotToComma = value => replace(value, '.', ',');

export const numberString = (number, digits = 2) => {
	number = toString(number);
	if (number.indexOf('.') > 0) {
		return numberStringFixed(number, digits);
	}
	return number;
};

export const getResponseMessage = response => {
	let message = response.message;
	if (typeof response.message === 'object') {
		message = Object.entries(response.message).map(([name, msg]) => {
			return name + ': ' + msg;
		}).join(', ');
	}
	return message;
};
