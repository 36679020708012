/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Autocomplete from 'components/FormInput/Autocomplete';
import PATHS from 'routes/paths';
import WarehouseAPI from 'apis/WarehouseAPI';
import DocumentsWZAPI from 'apis/DocumentWZAPI';
import OrdersAPI from 'apis/OrderAPI';
import ContractorAPI from 'apis/ContractorAPI';
import UserAPI from 'apis/UserAPI';
import InvoiceAPI from 'apis/InvoiceAPI';
import { getPriceZlAndGr, groupByFirstLetter } from 'utils/functions';
import { Breadcrumbs, Loader } from 'components';
import DocumentBox from 'components/document/DocumentBox';
import DocumentContractors from 'components/document/DocumentContractors';
import Document from 'components/document/Document';
import FormInput from 'components/form/FormInput';
import DocumentWZCreateTable from './DocumentWZCreateTable';
import DatePicker from 'components/FormInput/MyDatePicker';
import Select from 'react-select';
import axios from 'axios';

const today = moment(new Date()).format('YYYY-MM-DD');

const DocumentWZ = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);

	const costShippingOptions = [
		{ label: 'Sprzedającego', value: 'seller' },
		{ label: 'Zamawiajacego', value: 'buyer' }
	];
	const [data, setData] = useState({
		cost_free_shipping: 'seller',
		issue_date: today,
		shipment_date: today,
		release_date: today,
		invoice_date: today,
		document_id: '',
		invoice_id: null,
	});
	const [totals, setTotals] = useState({
		price: 0,
		value: 0,
		stock: 0
	});
	const [WZ, setWZ] = useState({});
	const [items, setItems] = useState([]);
	const [contractors, setContractors] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [myCompany, setMyCompany] = useState({});
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState();
	const [assortmentsOptions, setAssortmentsOptions] = useState([]);
	const [selectedInvoice, setSelectedInvoice] = useState();
	const [invoices, setInvoices] = useState([]);

	const bottomContent = [
		{
			width: '380px',
			heading: 'Wystawił',
			content: <span className="gray-color"> Jan Kowalski </span>
		},

		{
			width: '186px',
			heading: 'Zatwierdził',
			content: <span className="gray-color"> Janina Nowak </span>
		},

		{
			width: '190px',
			heading: 'Magazyn',
			content: (
				<Autocomplete
					id="warehouse"
					options={groupByFirstLetter(warehouses)}
					value={warehouses.find(i => +i.id === +data.warehouse_id)}
					groupBy={option => option.firstLetter}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.id}
					onChange={warehouse => {
						setData({ ...data, warehouse_id: warehouse?.id });
					}}
				/>
			)
		},

		{
			width: '465px',
			group: {
				heading: 'WYMIENIONE ILOŚCI',
				items: [
					{
						heading: 'Wydał',
						content: <span className="gray-color"> Dariusz Wiśniewski </span>
					},
					{
						heading: 'Data',
						content: (
							<DatePicker
								value={data.release_date}
								onChange={v => setData({ ...data, release_date: v })}
							/>
						)
					},
					{
						heading: 'Odebrał',
						content: <span className="gray-color"> Janina Kowalska </span>
					}
				]
			}
		},

		{
			width: '',
			heading: 'Ewidencja ilościowo-wartościowa',
			content: <span className="gray-color"> Jan Nowak </span>
		}
	];

	useEffect(() => {
		axios.all([
			id ? DocumentsWZAPI.get(id) : DocumentsWZAPI.getInfo(),
			UserAPI.getCompany(),
			ContractorAPI.getRecipients(),
			WarehouseAPI.getAll(),
			OrdersAPI.getAll(),
			InvoiceAPI.getAll(),
		]).then(axios.spread((
			item,
			company,
			contractors,
			warehouses,
			orders,
			invoices
		) => {
			if (item?.data) {
				if (id) {
					setData(item.data);
					setWZ({ ...item.data });
				} else {
					setData({ ...data, document_id: item.data.document_wz?.formated })
				}
			}
			company?.data && setMyCompany(company.data);
			contractors?.data && setContractors(contractors.data.contractors_list);
			warehouses?.data && setWarehouses(warehouses.data?.filter(i => i.active));
			orders?.data && setOrders(orders.data.map(o => {
				o.assortments = o.assortments.map(i => ({
					...i,
					quantity_requested: i.quantity,
					quantity_released: i.quantity,
					price: i.assortment.sale_price,
				}));
				return o;
			}));
			invoices?.data && setInvoices(invoices.data);

			setLoading(false);
		}));
	}, []);

	const changeData = (name, value) => setData({ ...data, [name]: value });

	const validate = entity => {
		if (!entity.recipient) {
			addToast('Nie wybrano odbiorcy.', { appearance: 'error' });
			return false;
		}
		if (!entity.order_id) {
			addToast('Nie wybrano zamówienia.', { appearance: 'error' });
			return false;
		}
		if (!entity.items?.length) {
			addToast('Nie wybrano asortymentu.', { appearance: 'error' });
			return false;
		}

		for (let ass of entity.items) {
			if (!ass.assortment_id) {
				addToast('Brakuje asortymentu.', { appearance: 'error' });
				return false;
			}
			if (!ass.quantity_requested || !ass.quantity_released) {
				addToast('Nie podano ilości.', { appearance: 'error' });
				return false;
			}
		}
		if (!entity.warehouse_id) {
			addToast('Nie wybrano magazynu.', { appearance: 'error' });
			return false;
		}

		return true;
	};

	const handleSave = () => {
		const proper_document_id = data.document_id && data.document_id.replace('WZ/', '');
		let entity = {
			...data,
			recipient_id: data.recipient?.id,
			order_id: selectedOrder?.id,
			invoice_id: selectedInvoice?.id || null,
			items: items && !!items.length ? items.map(item => ({
				id: false, ...item, document_wz_id: proper_document_id
			})) : null
		};

		if (!validate(entity)) {
			return;
		}

		setLoading(true);
		DocumentsWZAPI.updateOrCreate(entity, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message, { appearance: 'success' });
				setTimeout(() => history.push(PATHS.DocumentWZList), 100);
			} else {
				addToast(response.message, { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (selectedOrder) {
			setAssortmentsOptions(
				selectedOrder.assortments.map(i => {
					return { ...i.assortment, quantity: i.quantity, stock: i.stock };
				})
			);
		}
	}, [selectedOrder]);

	useEffect(() => {
		if (orders.length && data.order_id) {
			setSelectedOrder(orders.find(i => +i.id === data.order_id));
		}
	}, [data.order_id, orders]);

	useEffect(() => {
		if (invoices.length && data.invoice_id) {
			setSelectedInvoice(invoices.find(i => +i.id === data.invoice_id));
		}
	}, [data.invoice_id, invoices]);

	useEffect(() => {
		let temp = {
			price: 0,
			value: 0,
			stock: 0
		};
		for (let item of items) {
			temp.price += isNaN(item.price) ? 0 : +item.price;
			temp.value += isNaN(item.value) ? 0 : +item.value;
			temp.stock += isNaN(item.stock) ? 0 : +item.stock;
		}
		let [price_zl, price_gr] = getPriceZlAndGr(temp.price);
		let [value_zl, value_gr] = getPriceZlAndGr(temp.value);
		setTotals({
			price_zl: price_zl,
			price_gr: price_gr,
			value_zl: value_zl,
			value_gr: value_gr,
			stock: temp.stock
		});
	}, [items]);

	useEffect(() => {
		if (contractors && data) {
			setData({
				...data,
				recipient: contractors.find(i => i.id === data.recipient_id)
			});
		}
	}, [contractors, data.recipient_id]);

	return (
		<>
			<Breadcrumbs
				list={['Dokumenty', { label: 'Wydania', path: PATHS.DocumentWZList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.DocumentWZList}
			/>

			<Loader loading={loading}>
				<Document onSaveAndGenerate={handleSave}>
					<div className="flex">
						<div style={{ flex: '0 0 430px' }}>
							<DocumentBox
								heading="Pieczęć firmowa"
								extra_classes="border"
								box_height={175}>
								<div>Firma {myCompany.company_name}</div>
								<div>{myCompany.street} {myCompany.numbers}</div>
								<div>{myCompany.postal_code} {myCompany.city}</div><br/>
								{myCompany.nip && <div>NIP: {myCompany.nip}</div>}
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 446px' }}>
							<DocumentBox
								heading="Odbiorca"
								extra_classes="bt br bb"
								box_height={175}>
								<DocumentContractors
									name="recipient"
									id={data.recipient_id}
									contractors={contractors}
									onChange={c => setData({ ...data, recipient: c, recipient_id: c.id })}
								/>

								<div className="gray-color" style={{ marginTop: '1rem' }}>
									{data.recipient && (
										<>
											<div>{data.recipient.street} {data.recipient.numbers}</div>
											<div>{data.recipient.postal_code} {data.recipient.city}</div>
											{data.recipient.nip && <div>NIP: {data.recipient.nip}</div>}
										</>
									)}
								</div>
							</DocumentBox>
						</div>

						<div
							style={{ flex: '0 0 232px', textAlign: 'center' }}
							className="box steal-bg bt br bb">
							<div style={{ fontSize: '3.5em' }}> WZ</div>
							<div>Wydanie</div>

							<div> Na zewnątrz</div>
						</div>

						<div style={{ flex: '0 0 226px' }}>
							<DocumentBox
								heading="Nr bieżący"
								extra_classes="bt br bb"
								box_height={175}>
								<FormInput disabled name="document_id" value={data.document_id}/>
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 201px' }}>
							<DocumentBox
								heading="Egz."
								extra_classes="bt br bb"
								box_height={88}>
								1
							</DocumentBox>

							<DocumentBox
								heading="Data wystawienia"
								extra_classes="br bb"
								box_height={87}>
								<DatePicker
									value={data.issue_date}
									onChange={v => setData({ ...data, issue_date: v })}
								/>
							</DocumentBox>
						</div>
					</div>

					<div className="flex">
						<div style={{ flex: '0 0 430px' }}>
							<DocumentBox
								heading="Nr zamówienia"
								extra_classes="bl br bb"
								box_height={130}>
								<Select
									placeholder="Wybierz"
									noOptionsMessage={() => 'Brak opcji'}
									options={orders}
									getOptionLabel={option => [
										option.order_number,
										' (', option.purchaser.name, ' / ', option.assortments[0].assortment.name, ')'
									].join('')}
									getOptionValue={option => option.id}
									value={selectedOrder}
									isClearable={false}
									menuPlacement="auto"
									onChange={v => {
										setSelectedOrder(v);
										setData({
											...data,
											recipient: v.purchaser,
											recipient_id: v.purchaser.id,
											assortments: v.assortments,
											warehouse_id: v.warehouse_id,
										});
									}}
								/>
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 236px' }}>
							<DocumentBox
								heading="Środek transportu"
								extra_classes="br bb"
								box_height={130}>
								<FormInput
									name="transport_type"
									value={data.transport_type || ''}
									onChange={changeData}
								/>
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 210px' }}>
							<DocumentBox
								heading="Przeznaczenie"
								extra_classes="br bb"
								box_height={130}>
								<FormInput
									name="purpose"
									value={data.purpose || ''}
									onChange={changeData}
								/>
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 232px' }}>
							<DocumentBox
								heading="Data wysyłki"
								extra_classes="br bb"
								box_height={130}>
								<DatePicker
									value={data.shipment_date}
									onChange={v => setData({ ...data, shipment_date: v })}
								/>
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 226px' }}>
							<DocumentBox
								heading="Wysyłka na koszt"
								extra_classes="br bb"
								box_height={130}>
								<Select
									placeholder="Wybierz"
									noOptionsMessage={() => 'Brak opcji'}
									options={costShippingOptions}
									value={costShippingOptions.find(
										i => i.value === data.cost_free_shipping
									)}
									onChange={v =>
										setData({ ...data, cost_free_shipping: v.value })
									}
									isSearchable={false}
								/>
							</DocumentBox>
						</div>

						<div style={{ flex: '0 0 201px' }}>
							<DocumentBox
								heading="Nr i data FV"
								extra_classes="br bb"
								box_height={130}>
								<div style={{ marginBottom: '4px' }}>
									<Select
										placeholder="Wybierz"
										noOptionsMessage={() => 'Brak opcji'}
										value={selectedInvoice}
										options={invoices}
										getOptionLabel={option => option.invoice_number}
										getOptionValue={option => option.id}
										isClearable={true}
										menuPlacement="auto"
										onChange={v => {
											setSelectedInvoice(v);
										}}
									/>
								</div>

								<div>
									<DatePicker
										value={data.invoice_date}
										onChange={v => setData({ ...data, invoice_date: v })}
									/>
								</div>
							</DocumentBox>
						</div>
					</div>

					{selectedOrder && (
						<DocumentWZCreateTable
							WZ={WZ}
							key={selectedOrder?.id}
							assortments={data.assortments}
							assortments_options={assortmentsOptions}
							onRowsChange={rows => setItems(rows)}
						/>
					)}

					<div className="flex" style={{ marginTop: 20 }}>
						<div
							style={{ flex: '0 0 1030px', textAlign: 'right' }}
							className="box border steal-bg">
							RAZEM
						</div>
						<div
							style={{ flex: '0 0 74px', maxWidth: 74, borderLeft: 0 }}
							className="box border steal-bg">
							{totals.price_zl}
						</div>
						<div
							style={{ flex: '0 0 82px', maxWidth: 82, borderLeft: 0 }}
							className="box border steal-bg">
							{totals.price_gr}
						</div>
						<div
							style={{ flex: '0 0 70px', maxWidth: 70, borderLeft: 0 }}
							className="box border steal-bg">
							{totals.value_zl}
						</div>
						<div
							style={{ flex: '0 0 78px', maxWidth: 78, borderLeft: 0 }}
							className="box border steal-bg">
							{totals.value_gr}
						</div>
						<div
							style={{ flex: '0 0 202px', maxWidth: 202, borderLeft: 0 }}
							className="box border steal-bg">
							{totals.stock}
						</div>
						{/* <div style={{ flex: 'auto' }} className="box bt bb br" /> */}
					</div>

					<div className="flex bl">
						{bottomContent.map((item, i) => {
							const { width, heading, content, group } = item;

							if (heading && content) {
								return (
									<div key={i} style={{ flex: width ? `0 0 ${width}` : 'auto' }}>
										<div
											style={{ height: '80px' }}
											className="df-centered box steal-bg br bb">
											{heading}
										</div>

										<div
											style={{ height: '48px' }}
											className="df-centered box br bb">
											{content}
										</div>
									</div>
								);
							}

							if (group) {
								return (
									<div key={i} style={{ flex: `0 0 ${width}` }}>
										<div
											style={{ height: '38px' }}
											className="df-centered box steal-bg br bb">
											{group.heading}
										</div>

										<div className="flex">
											{group.items.map((subitem, j) => (
												<div key={j} style={{ flex: '1 1' }}>
													<div
														style={{ height: '42px' }}
														className="df-centered box steal-bg br bb">
														{subitem.heading}
													</div>
													<div
														style={{ height: '48px' }}
														className="df-centered box br bb">
														{subitem.content}
													</div>
												</div>
											))}
										</div>
									</div>
								);
							}
							return null;
						})}
					</div>
				</Document>
			</Loader>
		</>
	);
}

export default DocumentWZ;
