import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const Chart = props => {
	const { data } = props;

	const colors = [
		'#44d62c',
		'#7fc2e4',
		'#0085ca',
		'#ef3340',
		'#000000',
		'#008578',
		'#f7999f',
		'#963cbd',
		'#d0df00',
		'#ff7fcb'
	];

	const chartData = {
		labels: data.map(i => i.label),
		datasets: [
			{
				data: data.map(i => i.y),
				backgroundColor: data.map(
					(item, index) => colors[index % colors.length]
				),
				borderColor: data.map((item, index) => colors[index % colors.length]),
				borderWidth: 1
			}
		]
	};

	return (
		<div
			style={{
				width: 800,
				height: 400,
				margin: '0 auto',
				backgroundColor: 'white'
			}}>
			{chartData && (
				<Bar
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: { display: false }
						}
					}}
				/>
			)}
		</div>
	);
};

export default Chart;
