/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProgressBar } from 'components';
import auth from '../../apis/AuthAPI';
import PATHS from 'routes/paths';
import { useToasts } from 'react-toast-notifications';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ValidateUser = () => {
	const history = useHistory();
	let query = useQuery();
	let token = query.get('token');
	const { addToast } = useToasts();

	const [progressStatus, setProgressStatus] = useState(false);

	useEffect(() => {
		setProgressStatus(true);
		auth
			.validate(token)
			.then(response => {
				let appearance = 'success';
				if (response.code === 200) {
					setProgressStatus(false);
					setTimeout(() => history.push(PATHS.Login), 100);
				} else {
					appearance = 'info';
					setProgressStatus(false);
					setTimeout(() => history.push(PATHS.Login), 100);
				}
				addToast(response.message, {
					appearance,
					autoDismissTimeout: 20000,
					autoDismiss: true
				});
			});
	}, []);

	return (
		<>
			<ProgressBar progressStatus={progressStatus} />
		</>
	);
};

ValidateUser.propTypes = {
	history: PropTypes.object
};

export default withRouter(ValidateUser);
