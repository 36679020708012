/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import AssortmentAPI from 'apis/AssortmentAPI';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import CheckMark from 'components/misc/CheckMark';
import PreviewButton from 'components/misc/PreviewButton';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import { numberStringFixed } from 'utils/functions';
import { useToasts } from 'react-toast-notifications';
import { assortment_header } from 'utils/xlsx_headers';
import useGlobalStyles from 'assets/style/styles';

const useStyles = makeStyles(theme => ({
	table: {}
}));

const AssortmentList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const [header, setHeader] = useState([
		{ id: 'name', label: 'Nazwa', filter: 'text' },
		{ id: 'index', label: 'Indeks', filter: 'text' },
		{ id: 'gtin', label: 'GTIN', filter: 'text' },
		{
			id: 'measure_unit',
			label: 'Jednostka miary',
			filter: 'select'
		},
		{
			id: 'logistic_unit',
			label: 'Jednostka logistyczna',
			filter: 'select'
		},
		{
			id: 'active',
			label: 'Aktywny',
			filter: 'select',
			align: 'center',
			list: [
				{ id: 0, name: 'Nie' },
				{ id: 1, name: 'Tak' }
			]
		},
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	]);
	const globalClasses = useGlobalStyles();

	const handleEdit = id => {
		history.push(PATHS.AssortmentEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			AssortmentAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		AssortmentAPI.create_list(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		AssortmentAPI.export(options).then(response => {
			if (response instanceof Blob) {
				const url = window.URL.createObjectURL(response);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Asortyment.xlsx');
				document.body.appendChild(link);
				setLoading(false);
				link.click();
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		AssortmentAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		AssortmentAPI.getInfo().then(res => {
			setHeader([...header.slice(0, 3), {
				...header[3], list: res.data?.measurement_unit_list?.filter(i => i.type === 2)
			}, {
				...header[4], list: res.data?.measurement_unit_list?.filter(i => i.type === 1)
			}, ...header.slice(5)]);
		});
	}, []);

	const handlePreview = index => {
		let rows = JSON.parse(JSON.stringify(data));
		rows[index].preview = !rows[index].preview;
		setData(rows);
	};

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nowy asortyment',
					path: PATHS.AssortmentCreate,
				}}
				importHandler={handleImport}
				exportHandler={handleExport}
				header_list={assortment_header}
			/>

			<Breadcrumbs list={['Dane podstawowe', 'Asortyment']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{row.name}</Cell>
								<Cell>{row.index}</Cell>
								<Cell>{row.gtin}</Cell>
								<Cell>{row.measure_name}</Cell>
								<Cell>{row.unit_name}</Cell>
								<Cell align="center">
									<CheckMark checked={row.active} />
								</Cell>
								<Cell align="center" width={180}>
									<PreviewButton
										active={!!row.preview}
										onClick={() => handlePreview(index)}
									/>
									<EditButton onClick={() => handleEdit(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>

							{row.preview && (
								<Row>
									<Cell colSpan={9}>
										<Grid
											container
											spacing={2}
											justifyContent="space-around"
											style={{ padding: '10px 50px' }}>
											<Grid item xs={6}>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Cena zakupu [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={numberStringFixed(row.purchase_price)}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Cena sprzedaży [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={numberStringFixed(row.sale_price)}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Grupa asortymentowa
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={row.assortment_group_name}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Dostawca
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={row?.supplier_contractor.name}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Odbiorca
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={row?.recipient_contractor.name}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6}>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Prawdopodobieństwo obsługi popytu [%]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={numberStringFixed(row.service_demand)}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Czas cyklu uzupełnienia [dni]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={row.refill_cycle_time}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Odchylenie czasu cyklu uzupełnienia [dni]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={row.cycle_time_deviations}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 8 }}>
															Współczynnik kosztu utrzymania zapasu [-]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															defaultValue={numberStringFixed(row.inventory_cost_factor)}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Cell>
								</Row>
							)}
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default AssortmentList;
