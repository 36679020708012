import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	required: {
		outline: '1px solid #f00',
	},
	lineTop: {
		borderTop: '1px solid #E1E3E5',
		marginTop: 20
	},
	hint: {
		color: theme.palette.gray_light,
	},
	dateHint: {
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.gray_light,
		justifyContent: 'center',
		paddingRight: theme.spacing(1),
	},
}));

export default useStyles;
