import React from 'react';
import useStyles from './style';
import clsx from 'clsx';
import { Button, Link, Typography } from '@material-ui/core';
import ContactFooter from '../SignIn/ContactFooter';
import { Link as RouterLink } from 'react-router-dom';
import PATHS from '../../routes/paths';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useGlobalStyles from '../../assets/style/styles';
import { SubscriptionPlans } from '../SubscriptionPlans';

const PriceList = () => {
	const classes = useStyles();
	const global_classes = useGlobalStyles();

	return (
		<div id="price-list" className={classes.root}>
			<div>
				<Typography
					className={classes.title}
					align="center"
					component="h1">
					Cennik aplikacji Monza
				</Typography>
				<Typography align="center">
					Zarejestruj się aby wybrać interesujący Cię pakiet
				</Typography>
			</div>
			<SubscriptionPlans isPublic />
			<div className={classes.buttonContainer}>
				<Link
					component={RouterLink}
					to={PATHS.Registration}>
					<Button
						className={clsx(
							global_classes.btnLogin,
							global_classes.outline_button
						)}
						variant="outlined">
						Zarejestruj się
					</Button>
				</Link>
				<Link
					className={global_classes.btnRegister}
					component={RouterLink}
					to={PATHS.Login}>
					Wróć do strony logowania <ArrowRightAltIcon className={global_classes.arrow}/>
				</Link>
			</div>
			<ContactFooter/>
		</div>
	);
};
export default PriceList;
