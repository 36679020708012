/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { Button, List, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		// backgroundColor: theme.palette.background.paper
	},
	item: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0
	},
	labelRoot: {
		color: theme.palette.sidebar_color,
		cursor: 'pointer'
	},
	label: {
		padding: theme.spacing(0.6),
		justifyContent: 'flex-start',
		color: theme.palette.sidebar_color,
		fontFamily: 'roboto',
		fontSize: '0.9em',
		'&:hover': {
			color: theme.palette.white,
			fontWeight: 600,
			borderRadius: '0px'
		}
	},
	section: {
		padding: theme.spacing(1, 4),
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: 400,
		fontSize: '0.8750em',
		color: theme.palette.sidebar_color,
		lineHeight: '1em',
		'&:hover': {
			color: theme.palette.white,
			fontWeight: 600,
			borderRadius: '0px'
		}
	},
	title: {
		width: '240px'
	},
	button: {
		padding: theme.spacing(0.6),
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: 400,
		fontSize: '0.8750em',
		color: theme.palette.sidebar_color,
		lineHeight: '1em',
		'&:hover': {
			color: theme.palette.white,
			fontWeight: 600,
			borderRadius: '0px'
		}
	},
	active: {
		fontWeight: 600,
		color: theme.palette.white,
		borderRadius: '0px'
	},
	nested: {
		paddingLeft: theme.spacing(4)
	}
}));

const CustomRouterLink = forwardRef((props, ref) => (
	<div
		ref={ref}
		style={{ flexGrow: 1 }}
	>
		<RouterLink {...props} />
	</div>
));

const SidebarNavTree = props => {
	const classes = useStyles();
	const { pages, className, history, ...rest } = props;
	const [open, setOpen] = useState({});

	const handleClick = (page) => {
		history.push(page.href);
	};

	const handleItemClick = (index) => {
		setOpen({ [index]: !open[index] });
	};

	useEffect(() => {
		pages.map((item, index) => item.label && item.items.map(page => page.href === history.location.pathname && setOpen({ [index]: true })));
	}, [history.location]);

	return (
		<List
			{...rest}
			className={clsx(classes.root, className)}
		>
			{pages.map((section, index) => (
					section.label ? (
						<Fragment key={index}>
							<ListItem className={classes.labelRoot} onClick={() => handleItemClick(index)}>
								<Button
									className={classes.label}
								>{section.label}</Button>
								{open[index] ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={open[index]} timeout='auto' unmountOnExit>
								<List component='div' disablePadding>
									{section.items && section.items.map(((item, subindex) => (
										<Fragment key={subindex}>
											<ListItem
												className={classes.nested}
												disableGutters
												key={item.title}
												onClick={() => handleClick(item)}
											>
												<Button
													activeClassName={!item.sub ? classes.active : classes.active_sub}
													className={classes.button}
													component={CustomRouterLink}
													to={item.href}
												>
													<div className={classes.title}>
														{item.title}
													</div>
												</Button>
											</ListItem>
										</Fragment>
									)))}
								</List>
							</Collapse>
						</Fragment>
					) : (
						<Fragment key={index}>
							<ListItem
								disableGutters
								key={section.title}
								onClick={() => handleClick(section)}
							>
								<Button
									activeClassName={!section.sub ? classes.active : classes.active_sub}
									className={classes.section}
									component={CustomRouterLink}
									to={section.href}
								>
									<div className={classes.title}>
										{section.title}
									</div>
								</Button>
							</ListItem>
						</Fragment>
					)
				)
			)}
		</List>
	);
};

SidebarNavTree.propTypes = {
	className: PropTypes.string,
	pages: PropTypes.array.isRequired
};

export default SidebarNavTree;



