import React from 'react';
import PropTypes from 'prop-types';

import './loader.scss';

const Loader = props => {
	const { style, loading = true, children } = props;

	return <>
		{children}
		{loading ? <div className="loader-wrapper">
			<div className="loader" style={style}>
				<div className="cssload-whirlpool"/>
			</div>
		</div> : ''}
	</>
};

Loader.propTypes = {
	style: PropTypes.object,
	loading: PropTypes.bool,
};

export default Loader;
