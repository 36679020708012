/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { groupByFirstLetter } from 'utils/functions';

import PATHS from 'routes/paths';
import AssortmentAPI from 'apis/AssortmentAPI';
import OrderAPI from 'apis/OrderAPI';
import ContractorAPI from 'apis/ContractorAPI';
import WarehouseAPI from 'apis/WarehouseAPI';
import UserAPI from 'apis/UserAPI';

import Autocomplete from 'components/FormInput/Autocomplete';
import { Breadcrumbs, Loader } from 'components';
import DocumentBox from 'components/document/DocumentBox';
import DocumentContractors from 'components/document/DocumentContractors';
import Document from 'components/document/Document';
import FormInput from 'components/form/FormInput';
import DatePicker from 'components/FormInput/MyDatePicker';
import OrderCreateTable from './OrderCreateTable';
import axios from 'axios';
// import moment from 'moment';
// const today = moment(new Date()).format('YYYY-MM-DD');

const Order = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	const [assortmentsOptions, setAssortmentsOptions] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [contractors, setContractors] = useState([]);
	const [myCompany, setMyCompany] = useState({});
	const [assortments, setAssortments] = useState([]);

	useEffect(() => {
		axios.all([
			id ? OrderAPI.get(id) : OrderAPI.getInfo(),
			UserAPI.getCompany(),
			ContractorAPI.getAll({ active: 1 }),
			WarehouseAPI.getAll(),
			AssortmentAPI.getAll(),
		]).then(axios.spread((
			item,
			company,
			contractors,
			warehouses,
			assortments
		) => {
			if (item?.data) {
				if (id) {
					setData({ ...item.data, purchaserItem: item.data?.purchaserItem });
				} else {
					setData({ ...data, order_number: item.data.order?.formated });
				}
			}
			company?.data && setMyCompany(company.data);
			contractors?.data && setContractors(contractors.data);
			warehouses?.data && setWarehouses(warehouses.data);
			assortments?.data && setAssortmentsOptions(assortments.data?.filter(i => i.active));

			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handlePurchaserChange = c => setData(prev => ({
		...prev,
		purchaserItem: c,
		purchaser: c?.id,
		street: c?.street,
		building_number: c?.building_number,
		apartment_number: c?.apartment_number,
		postal_code: c?.postal_code,
		city: c?.city
	}));

	const handleSave = () => {
		let entity = {
			...data,
			regon: data.purchaserItem?.regon,
			nip: data.purchaserItem?.nip,
			assortments: assortments?.map(item => ({
				id: false,
				assortment_id: item.assortment_id,
				quantity: item.quantity,
				delivery_date: item.delivery_date
			}))
		};
		if (!validate(entity)) {
			return;
		}
		setLoading(true);
		OrderAPI.updateOrCreate(entity, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message, { appearance: 'success' });
				setTimeout(() => history.push(PATHS.OrderList), 100);
			} else {
				addToast(response.message, { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	const validate = entity => {
		if (!entity.purchaser) {
			addToast('Nie wybrano nabywcy.', { appearance: 'error' });
			return false;
		}

		if (!entity.document_of_date) {
			addToast('Nie podano daty dokumentu.', { appearance: 'error' });
			return false;
		}

		if (!entity.assortments?.length) {
			addToast('Nie wybrano asortymentu.', { appearance: 'error' });
			return false;
		}

		for (let ass of entity.assortments) {
			if (!ass.assortment_id) {
				addToast('Brakuje asortymentu.', { appearance: 'error' });
				return false;
			}
			if (!ass.quantity) {
				addToast('Nie podano ilości.', { appearance: 'error' });
				return false;
			}
			if (!ass.delivery_date) {
				addToast('Nie podano daty dostawy.', { appearance: 'error' });
				return false;
			}
		}

		// if (!entity.attachment_number) {
		// 	addToast('Nie podano ilości załączników.', { appearance: 'error' });
		// 	return false;
		// }

		if (!entity.warehouse_id) {
			addToast('Nie wybrano magazynu.', { appearance: 'error' });
			return false;
		}

		return true;
	};

	return (
		<>
			<Breadcrumbs
				list={['Dokumenty', { label: 'Zamówienia', path: PATHS.OrderList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.OrderList}
			/>

			<Loader loading={loading}>
				<Document onSaveAndGenerate={handleSave}>
					<div className="flex">
						<div className="df-column" style={{ flex: 'auto' }}>
							<div className="flex">
								<div style={{ flex: '0 0 436px' }}>
									<DocumentBox
										box_height={184}
										extra_classes="bb bt bl"
										heading="ZAMAWIAJĄCY"
										heading_style={{
											left: '-77px',
											top: '77px'
										}}
										vh>
										<div style={{ marginBottom: '20px' }}>
											<div className="label">Firma</div>
											<DocumentContractors
												contractors={contractors}
												id={data.purchaser}
												name="purchaser"
												onChange={handlePurchaserChange}
											/>
										</div>

										<div className="label">Adres</div>

										<div className="gray-color">
											{data.purchaser && (
												<>
													{data.street} {data.building_number}/
													{data.apartment_number}
													{', '}
													{data.postal_code} {data.city}
												</>
											)}
										</div>
									</DocumentBox>

									<div className="box bb bl br">
										<span> REGON </span>
										<span className="gray-color">
											{' '}
											{data.purchaserItem?.regon || data.regon}{' '}
										</span>
									</div>

									<div className="box bl br">
										<FormInput
											label="Warunki płatności"
											name="payment_terms"
											onChange={handleChange}
											value={data.payment_terms}
										/>
									</div>
								</div>

								<div style={{ flex: '0 0 520px' }}>
									<DocumentBox
										box_height={184}
										extra_classes="bb bt bl"
										heading="ADRES WYSYŁKI"
										heading_style={{
											left: '-77px',
											top: '77px'
										}}
										vh>
										<div style={{ marginBottom: '20px' }}>
											<div className="label">Firma</div>
											<div className="gray-color" style={{ height: 38 }}>
												{data.purchaserItem?.name}
											</div>
										</div>

										<div className="label"> Adres</div>

										<div className="df-ac" style={{ margin: '0 -4px' }}>
											<FormInput
												box_style={{ margin: '0 4px' }}
												key="street"
												name="street"
												onChange={handleChange}
												value={data.street}
											/>
											<FormInput
												box_style={{ margin: '0 4px' }}
												key="building_number"
												name="building_number"
												onChange={handleChange}
												value={data.building_number}
											/>
											<div style={{ paddingTop: 8 }}>/</div>
											<FormInput
												box_style={{ margin: '0 4px' }}
												key="apartment_number"
												name="apartment_number"
												onChange={handleChange}
												value={data.apartment_number}
											/>
											<FormInput
												box_style={{ margin: '0 4px' }}
												key="postal_code"
												name="postal_code"
												onChange={handleChange}
												value={data.postal_code}
											/>
											<FormInput
												box_style={{ margin: '0 4px' }}
												key="city"
												name="city"
												onChange={handleChange}
												value={data.city}
											/>
										</div>
									</DocumentBox>

									<div className="box">
										<FormInput
											label="Konto w banku"
											name="bank_account"
											onChange={handleChange}
											placeholder="XXXXXXXXX"
											value={data.bank_account}
										/>
									</div>
								</div>
							</div>

							<div
								className="flex bb bl br bt"
								style={{ width: '100%', flex: 'auto' }}>
								<div className="box gray-bg" style={{ flex: '0 0 180px' }} />
								<div className="df-ac box bl br gray-bg" style={{ flex: 'auto' }}>
									NIP {data.purchaserItem?.nip}
								</div>

								<div className="box gray-bg" style={{ flex: '0 0 180px' }} />
							</div>
						</div>

						<div style={{ flex: '0 0 580px' }}>
							<DocumentBox
								box_height={216}
								extra_classes="gray border"
								heading="ADRESAT"
								heading_style={{
									left: '-93px',
									top: '93px'
								}}
								vh>
								<div style={{ marginBottom: '20px' }}>
									<div className="label"> Firma</div>
									<div className="gray-color"> {myCompany.company_name} </div>
								</div>

								<div className="label"> Adres</div>
								<div className="gray-color">
									{myCompany.street} {myCompany.numbers} <br />
									{myCompany.postal_code} {myCompany.city}
								</div>
							</DocumentBox>

							<div className="box bb bl br gray-bg">
								<div
									style={{
										fontWeight: 'bold',
										fontSize: '32px',
										letterSpacing: '30px',
										textAlign: 'center'
									}}>
									{' '}
									ZAMÓWIENIE
								</div>

								<div className="df-ac">
									<div style={{ marginRight: '35px', fontSize: '14px' }}>
										<div style={{ letterSpacing: '5px' }}>
											<strong>OGÓLNE</strong>
										</div>
										<div>
											<strong>z rubrykami</strong>
										</div>
									</div>

									<div className="df-ac" style={{ flex: 'auto' }}>
										<div
											style={{
												fontSize: '28px',
												fontWeight: 'bold',
												marginRight: '10px'
											}}>
											{' '}
											NR
										</div>

										<FormInput disabled value={data.order_number} />
									</div>
								</div>
							</div>

							<div className="box br bb df-ac">
								<div style={{ flex: '0 0 40%' }}> z dnia</div>
								<DatePicker
									onChange={val => {
										setData({ ...data, document_of_date: val });
									}}
									value={data.document_of_date}
								/>
							</div>
						</div>
					</div>

					<OrderCreateTable
						assortments={data.assortments}
						assortments_options={assortmentsOptions}
						onRowsChange={val => setAssortments(val)}
					/>

					<div className="flex" style={{ marginTop: 20 }}>
						<div className="gray-bg box border" style={{ flex: '0 0 620px' }}>
							<div className="df-ac" style={{ marginBottom: '4px' }}>
								<span style={{ marginRight: '5px' }}>Uwagi</span>
								<FormInput
									name="comments"
									onChange={handleChange}
									value={data.comments}
								/>
							</div>

							<div className="df-ac">
								<span style={{ marginRight: '5px' }}>Ilość załączników</span>

								<FormInput
									name="attachment_number"
									onChange={handleChange}
									value={data.attachment_number}
								/>
							</div>
						</div>

						<div className="box bt br bb" style={{ flex: '0 0 320px' }}>
							<div className="label"> Magazyn realizujący</div>

							<Autocomplete
								getOptionLabel={option => option.name}
								getOptionValue={option => option.id}
								groupBy={option => option.firstLetter}
								onChange={warehouse =>
									setData(prev => ({ ...prev, warehouse_id: warehouse?.id }))
								}
								options={groupByFirstLetter(warehouses)}
								value={warehouses.find(item => +item.id === +data.warehouse_id)}
							/>
						</div>

						<div
							className="box bt br bb"
							style={{
								padding: '43px 5px 0',
								flex: 'auto'
							}}>
							Pieczęć i podpis nabywcy : {myCompany.company_name}
						</div>
					</div>
				</Document>
			</Loader>
		</>
	);
};

export default Order;
