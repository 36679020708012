/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Table, Row, Cell } from 'components/MyTable';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import MeasurementUnitAPI from 'apis/MeasurementUnitAPI';
import { measure_unit_header } from 'utils/xlsx_headers';
import { exportToXlsx } from 'utils/functions';
import { useToasts } from 'react-toast-notifications';
import useStyles from './style';

const MeasurementUnit = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const [header, setHeader] = useState([
		{ id: 'type', label: 'Jednostka', filter: 'select' },
		{ id: 'name', label: 'Nazwa', filter: 'text' },
		{ id: 'description', label: 'Opis', filter: 'text' },
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	]);
	const [units, setUnits] = useState([]);

	const handleEdit = id => {
		history.push(PATHS.MeasurementUnitEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			MeasurementUnitAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		MeasurementUnitAPI.create_list(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		MeasurementUnitAPI.export(options).then(response => {
			if (response.code === 200) {
				exportToXlsx(measure_unit_header, response.data, 'Jednostki miary');
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		MeasurementUnitAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		MeasurementUnitAPI.getInfo().then(res => {
			if(res.code === 200){
				setHeader([{ ...header[0], list: res.data.typeList }, ...header.slice(1)]);

				let copy = {};
				for (let item of res.data.typeList) {
					copy[item.id] = item.name;
				}
				setUnits(copy);
			}
		});
	}, []);

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nową jednostkę',
					path: PATHS.MeasurementUnitCreate,
				}}
				importHandler={handleImport}
				exportHandler={handleExport}
				header_list={measure_unit_header}
			/>

			<Breadcrumbs list={['Dane podstawowe', 'Jednostki miary']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{units[row.type]}</Cell>
								<Cell>{row.name}</Cell>
								<Cell>{row.description}</Cell>
								<Cell align="center">
									<EditButton onClick={() => handleEdit(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default MeasurementUnit;
