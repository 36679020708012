/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import AssortmentGroupAPI from 'apis/AssortmentGroupAPI';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Table, Row, Cell } from 'components/MyTable';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import { exportToXlsx } from 'utils/functions';
import { assortment_group_header } from 'utils/xlsx_headers';
import mainUtil from 'utils/main';
import { useToasts } from 'react-toast-notifications';
import useStyles from './style';

const AssortmentGroupList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const [header, setHeader] = useState([
		{ id: 'main_group_name', label: 'Nazwa głównej grupy', filter: 'select' },
		{ id: 'name', label: 'Nazwa grupy asortymentowej', filter: 'text' },
		{ id: 'description', label: 'Opis', filter: 'text' },
		{ id: 'code', label: 'Kod', filter: 'text' },
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	]);

	const handleEdit = id => {
		history.push(PATHS.AssortmentGroupEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			AssortmentGroupAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		AssortmentGroupAPI.create_list(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		AssortmentGroupAPI.export(options).then(response => {
			if (response.code === 200) {
				exportToXlsx(assortment_group_header, response.data, 'Grupy asortymentowe');
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		AssortmentGroupAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		AssortmentGroupAPI.getInfo().then(res => {
			setHeader([{
				...header[0], list: mainUtil.sortObjArray(res.data.assortments_groups, 'name')
			}, ...header.slice(1)]);
		});
	}, []);

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nową grupę',
					path: PATHS.AssortmentGroupCreate,
				}}
				importHandler={handleImport}
				exportHandler={handleExport}
				header_list={assortment_group_header}
			/>

			<Breadcrumbs list={['Dane podstawowe', 'Grupy asortymentowe']}/>

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell width={250}>
									{row.main_group_name ? row.main_group_name : row.name}
								</Cell>
								<Cell width={250}>{row.main_group_name ? row.name : ''}</Cell>
								<Cell>{row.description}</Cell>
								<Cell>{row.code}</Cell>
								<Cell align="center" width={120}>
									<EditButton onClick={() => handleEdit(row.id)}/>
									<TrashButton onClick={() => setDeleteId(row.id)}/>
								</Cell>
							</Row>
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default AssortmentGroupList;
