import React from 'react';

import { DocumentTable } from 'components/document/DocumentTable';
import OrderCreateTableRow from './OrderCreateTableRow';

const COLUMN_SIZES = [46, 608, 322, 50, 66, 444];

const OrderCreateTable = props => (
  <DocumentTable
    {...props}
    column_sizes={COLUMN_SIZES}
    head={[
      'Lp.',
      'Nazwa towaru lub usługi',
      'KTM Symbol indeksu',
      'j.m',
      'Ilość',
      'Termin dostawy'
    ]}
    RowComponent={OrderCreateTableRow}
  />
)

export default OrderCreateTable;
