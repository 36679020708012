/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, OutlineButton } from 'components';

// import useStyles from './style';
import {
	Typography,
	CardContent,
	Grid,
	Card,
	Box,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button
} from '@material-ui/core';
import moment from 'moment';
import StockAnalyzes from 'apis/StockAnalyzeAPI';
import Loader from 'components/Loader';
import PATHS from 'routes/paths';
import useGlobalStyles from 'assets/style/styles';
import QuantitativeStructure from './tables/QuantitativeStructure/index';
import ValueStructure from './tables/ValueStructure';
import IndicativeQuantitative from './tables/IndicativeQuantitative';
import ValueBasedRatio from './tables/ValueBasedRatio';
import InventoryMaintenanceCost from './tables/InventoryMaintenanceCost';
import CollectiveValue from './tables/CollectiveValue';
import CollectiveQuantity from './tables/CollectiveQuantity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';

const StockAnalyzeResult = () => {
	const history = useHistory();
	const { id } = useParams();
	const globalClasses = useGlobalStyles();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [displayMethod, setDisplayMethod] = useState('separately'); // collectively, separately
	const [subDisplayMethod, setSubDisplayMethod] = useState('valuably');
	const [data, setData] = useState({});
	const [analyzeVisible, setAnalyzeVisible] = useState(true);

	const handleNewStockAnalyze = () => {
		history.push(PATHS.StockAnalyzeCreate);
	};

	const combineData = (a, b) => {
		if (!a || !b) return;
		return a.map((v, i) => {
			return { ...v, ...b[i] };
		});
	};

	useEffect(() => {
		setLoading(true);
		StockAnalyzes.get(id).then(res => {
			if (res.code === 200) {
				setData({
					...res.data,
					calculations: {
						...res.data.calculations,
						collective_value: {
							assortment_shot: combineData(
								res.data.calculations.value_structure_analysis?.assortment_shot,
								res.data.calculations.value_based_ratio_analysis
									?.assortment_shot
							),
							group_shot: combineData(
								res.data.calculations.value_structure_analysis?.group_shot,
								res.data.calculations.value_based_ratio_analysis?.group_shot
							)
						},
						collective_quantity: {
							assortment_shot: combineData(
								res.data.calculations.quantitative_structure_analysis
									?.assortment_shot,
								res.data.calculations.indicative_quantitative_analysis
									?.assortment_shot
							),
							group_shot: combineData(
								res.data.calculations.quantitative_structure_analysis?.group_shot,
								res.data.calculations.indicative_quantitative_analysis?.group_shot
							)
						}
					}
				});
			} else if (res.status === 404) {
				addToast(res.message || 'Błąd 404', { appearance: 'error' });
				history.push(PATHS.StockAnalyzeList);
			}
			setLoading(false);
		});
	}, []);

	return (
		<>
			<Breadcrumbs
				list={['Analizy', { label: 'Analiza zapasów', path: PATHS.StockAnalyzeList }, 'Wyniki analizy']}
				back_url={PATHS.StockAnalyzeList}
			/>

			<Loader loading={loading}>
				<Grid container spacing={2}>
				<Grid item xs={9}>
					<Card style={{ marginBottom: '1em' }}>
						<CardContent>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h4">Dane dotyczące analizy</Typography>
								<Button
									variant="outlined"
									onClick={() => setAnalyzeVisible(!analyzeVisible)}>
									{!analyzeVisible && <ExpandMoreIcon />}
									{analyzeVisible && <ExpandLessIcon />}
								</Button>
							</Box>
							{analyzeVisible && (
								<Box>
									<Grid container spacing={2}>
										<Grid item xs={2}>
											<Typography>Nazwa analizy</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">{data.name}</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Zakres od</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.range_from
													? moment(data.range_from).format('DD/MM/YYYY')
													: ''}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Zakres do</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.range_to
													? moment(data.range_to).format('DD/MM/YYYY')
													: ''}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Opis</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">{data.description}</Typography>
										</Grid>

										<Grid item xs={2}>
											<Typography>Asortyment</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.assortment_groups_children_names}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Typography>Grupy asortymentowe</Typography>
										</Grid>
										<Grid item xs={10}>
											<Typography variant="h6">
												{data.assortment_groups_main_names}
											</Typography>
										</Grid>
									</Grid>
								</Box>
							)}
						</CardContent>
					</Card>

					<Card>
						<CardContent>
							<Box style={{ display: 'flex' }}>
								<Typography variant="h4">Wyniki analizy</Typography>
								<Box style={{ marginLeft: 20, paddingTop: 5 }}>
									<Typography>Wyświetlaj wyniki:</Typography>
									<RadioGroup
										defaultValue={displayMethod}
										onChange={e => setDisplayMethod(e.target.value)}>
										<FormControlLabel
											label="Zbiorczo"
											value="collectively"
											control={<Radio />}
										/>
										{displayMethod === 'collectively' && (
											<Box style={{ paddingLeft: 50 }}>
												<RadioGroup
													defaultValue={subDisplayMethod}
													onChange={e => setSubDisplayMethod(e.target.value)}>
													<FormControlLabel
														label="Wartościowo"
														value="valuably"
														control={<Radio />}
													/>
													<FormControlLabel
														label="Ilościowo"
														value="quantitatively"
														control={<Radio />}
													/>
												</RadioGroup>
											</Box>
										)}
										<FormControlLabel
											label="Oddzielnie"
											value="separately"
											control={<Radio />}
										/>
										{displayMethod === 'separately' && (
											<Box style={{ paddingLeft: 50 }}>
												<FormInput
													title="Analiza struktury"
													type="check_box"
													value={data.structure_analysis}
													handleChange={(n, v) =>
														setData({
															...data,
															structure_analysis: v,
															structure_quantitative_analysis: v,
															structure_value_analysis: v
														})
													}
												/>
												<Box style={{ paddingLeft: 50 }}>
													<FormInput
														title="Analiza ilościowa"
														type="check_box"
														value={data.structure_quantitative_analysis}
														handleChange={(n, v) =>
															setData({
																...data,
																structure_quantitative_analysis: v,
																structure_analysis:
																	v || data.structure_value_analysis
															})
														}
													/>
													<FormInput
														title="Analiza wartościowa"
														type="check_box"
														value={data.structure_value_analysis}
														handleChange={(n, v) =>
															setData({
																...data,
																structure_value_analysis: v,
																structure_analysis:
																	v || data.structure_quantitative_analysis
															})
														}
													/>
												</Box>
												<FormInput
													title="Analiza wskaźnikowa"
													type="check_box"
													value={data.ratio_analysis}
													handleChange={(n, v) =>
														setData({
															...data,
															ratio_analysis: v,
															ratio_quantitative_analysis: v,
															ratio_value_analysis: v
														})
													}
												/>
												<Box style={{ paddingLeft: 50 }}>
													<FormInput
														title="Analiza ilościowa"
														type="check_box"
														value={data.ratio_quantitative_analysis}
														handleChange={(n, v) =>
															setData({
																...data,
																ratio_quantitative_analysis: v,
																ratio_analysis: v || data.ratio_value_analysis
															})
														}
													/>
													<FormInput
														title="Analiza wartościowa"
														name="test3"
														type="check_box"
														value={data.ratio_value_analysis}
														handleChange={(n, v) =>
															setData({
																...data,
																ratio_value_analysis: v,
																ratio_analysis:
																	v || data.ratio_quantitative_analysis
															})
														}
													/>
												</Box>
												<FormInput
													title="Analiza kosztów"
													name="test"
													type="check_box"
													value={data.cost_analysis}
													handleChange={(n, v) =>
														setData({
															...data,
															cost_analysis: v
														})
													}
												/>
											</Box>
										)}
									</RadioGroup>
								</Box>
							</Box>

							{displayMethod === 'collectively' && (
								<>
									{subDisplayMethod === 'valuably' && (
										<Box>
											<CollectiveValue
												valA={data.abc_analysis_details_category_a_min}
												valB={data.abc_analysis_details_category_b_min}
												valC={data.abc_analysis_details_category_c_min}
												data={data.calculations?.collective_value}
											/>
										</Box>
									)}
									{subDisplayMethod === 'quantitatively' && (
										<Box>
											<CollectiveQuantity
												valA={data.abc_analysis_details_category_a_min}
												valB={data.abc_analysis_details_category_b_min}
												valC={data.abc_analysis_details_category_c_min}
												data={data.calculations?.collective_quantity}
											/>
										</Box>
									)}
								</>
							)}

							{displayMethod === 'separately' && (
								<Box>
									{!!data.structure_quantitative_analysis && (
										<QuantitativeStructure
											valA={data.abc_analysis_details_category_a_min}
											valB={data.abc_analysis_details_category_b_min}
											valC={data.abc_analysis_details_category_c_min}
											data={data.calculations?.quantitative_structure_analysis}
										/>
									)}
									{!!data.structure_value_analysis && (
										<ValueStructure
											valA={data.abc_analysis_details_category_a_min}
											valB={data.abc_analysis_details_category_b_min}
											valC={data.abc_analysis_details_category_c_min}
											data={data.calculations?.value_structure_analysis}
										/>
									)}
									{!!data.ratio_quantitative_analysis && (
										<IndicativeQuantitative
											valA={data.abc_analysis_details_category_a_min}
											valB={data.abc_analysis_details_category_b_min}
											valC={data.abc_analysis_details_category_c_min}
											data={data.calculations?.indicative_quantitative_analysis}
										/>
									)}
									{!!data.ratio_value_analysis && (
										<ValueBasedRatio
											valA={data.abc_analysis_details_category_a_min}
											valB={data.abc_analysis_details_category_b_min}
											valC={data.abc_analysis_details_category_c_min}
											data={data.calculations?.value_based_ratio_analysis}
										/>
									)}
									{!!data.cost_analysis && (
										<InventoryMaintenanceCost
											valA={data.abc_analysis_details_category_a_min}
											valB={data.abc_analysis_details_category_b_min}
											valC={data.abc_analysis_details_category_c_min}
											data={
												data.calculations?.inventory_maintenance_cost_analysis
											}
										/>
									)}
								</Box>
							)}

						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={3}>
					<Card>
						<CardContent style={{ paddingBottom: 16 }}>
							<OutlineButton
								title="Utwórz nową analizę"
								onClick={handleNewStockAnalyze}
								parent_class={globalClasses.full_fill}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			</Loader>
		</>
	);
};

export default StockAnalyzeResult;
