import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PATHS from 'routes/paths';
import API from 'apis/API';
import Auth, { isSubscriptionExpiring, subscriptionValidUntil } from 'apis/AuthAPI';
import storage from 'utils/storage';
import { getPageTitle, LoggedRoutes, UnLoggedRoutes } from 'routes/Routes';
import { Loader } from 'components';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import { useToasts } from 'react-toast-notifications';
import { NotFound } from 'views';

const AuthContext = React.createContext();

export const AuthContextProvider = () => {
	const { addToast } = useToasts();

	const history = useHistory();
	const location = useLocation();

	const [loading, setLoading] = useState(true);
	const [logged, setLogged] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const Routes = useMemo(() => logged ? LoggedRoutes : UnLoggedRoutes, [logged]);
	const Layout = useMemo(() => logged ? MainLayout : MinimalLayout, [logged]);
	const page_title = useMemo(() => logged ? getPageTitle(location.pathname) : '', [logged, location.pathname]);

	useEffect(() => {
		history.listen((location, action) => {
			setNotFound(false);
		});
	}, []);

	const logIn = () => {
		setLogged(true);
		history.push(PATHS.Welcome);

		addToast('Zalogowano pomyślnie.', {
			appearance: 'success',
			autoDismissTimeout: 10000,
			autoDismiss: true
		});

		if (isSubscriptionExpiring()) {
			const validUntil = subscriptionValidUntil();
			addToast(`Twój abonament wygasa! Jest ważny jeszcze do ${validUntil}`, {
				appearance: 'warning',
				autoDismissTimeout: 30000,
				autoDismiss: true
			});
		}
	};

	const logOut = () => {
		storage.removeStorage('token');
		storage.removeStorage('role');
		storage.removeStorage('subscription');
		setLogged(false);
	};

	const checkIsTokenValid = () => {
		storage.getStorage('token') ? Auth.validateToken().then(response => {
			setLogged(response.code === 200);
			setLoading(false);

			if (response.code === 200) {
				if (storage.getStorage('role') !== response.data?.role
					|| storage.getStorage('subscription') !== JSON.stringify(response.data?.subscription)) {
					// addToast('Twój abonament uległ zmianie.', {
					// 	appearance: 'warning', autoDismissTimeout: 10000, autoDismiss: true,
					// });
					// setTimeout(() => history.push(PATHS.Welcome), 100);
					setTimeout(() => history.push(location.pathname), 100);
				}
				storage.setStorage('role', response.data?.role);
				storage.setStorage('subscription', JSON.stringify(response.data?.subscription || '{}'));
			}
		}) : setLoading(false);
	};

	const isSubscriptionExpired = response => {
		if (response?.status === 402) {
			storage.setStorage('role', response.data?.data?.role || 'user');
			storage.setStorage('subscription', JSON.stringify(response.data?.data?.subscription || '{}'));

			addToast(response?.message || 'Brak aktywnego abonamentu.', {
				appearance: 'warning',
				autoDismissTimeout: 10000,
				autoDismiss: true
			});

			setTimeout(() => history.push(PATHS.SubscriptionPlanList), 100);

			return true;
		}
		return false;
	};

	const isSessionExpired = response => {
		if (response?.status === 401) {
			addToast('Sesja logowania wygasła.', {
				appearance: 'warning',
				autoDismissTimeout: 10000,
				autoDismiss: true
			});
			logOut();
			return true;
		}
		return false;
	};

	useEffect(() => {
		API.interceptors.response.use(response => {
			if (response.newToken) {
				storage.setStorage('token', response.newToken);
			}
			if(response.code === 500 && response.message){
				addToast(response.message, {
					appearance: 'error',
					autoDismissTimeout: 20000,
					autoDismiss: true,
				});
			}
			if (isSessionExpired(response) || isSubscriptionExpired(response)) {
				// return Promise.reject(response);
				return new Promise(() => {});
			}
			return response;
		}, error => {
			if (isSessionExpired(error.response) || isSubscriptionExpired(error.response)) {
				//
			} else if (error.message) {
				addToast(error.response?.message || error.message, {
					appearance: 'error',
					autoDismissTimeout: 20000,
					autoDismiss: true,
				});
			}
			if (error.response?.status === 404) {
				const regexp = /\/edit\/\d+$/;
				if (history.location.pathname.match(regexp)) {
					// redirect not found models to list
					history.push(history.location.pathname.replace(regexp, ''));
				} else {
					setNotFound(true);
				}
			}
			return error;
		});

		checkIsTokenValid();
	}, []);

	return (
		<AuthContext.Provider value={{
			logIn: () => logIn(),
			logOut: () => {
				logOut();
				addToast('Wylogowano pomyślnie.', {
					appearance: 'info',
					autoDismissTimeout: 10000,
					autoDismiss: true
				});
			}
		}}>
			{loading && <Loader style={{ height: '100vh', background: '#f4f6f8' }} />}
			{!loading &&
			<Layout title={page_title}>
				{notFound ? <NotFound/> : <Routes/>}
			</Layout>
			}
		</AuthContext.Provider>
	);
};

export default AuthContext;
