import EXCEL from 'js-export-xlsx';

class Utils {
	getAttrFromArray = (list, id, attribute, default_value) => {
		const index = element => element.id === +id;

		let order = list.findIndex(index);
		let item = list[order];
		if (item) {
			return item[attribute];
		} else {
			return default_value;
		}
	};

	sortObjArray = (array, key, direction = 1) => {
		return array.sort((a, b) => {
			let textA = a[key].toUpperCase();
			let textB = b[key].toUpperCase();
			return (textA < textB) ? -1 * direction : (textA > textB) ? direction : 0;
		});
	}

	convertStrToNum = str => {
		if (!str) {
			return 0;
		}
		let num = Number(str.toString().replace(',', '.'));
		return num;
	};

	convertNumToStr = num => {
		if (num === null) return '';
		let str = num.toString().replace('.', ',');
		return str;
	};

	round = (num, decimal) => {
		const factorOfTen = Math.pow(10, decimal);
		return Math.round(num * factorOfTen) / factorOfTen;
	};

	export = (headers, data) => {
		let export_data = [];
		data.map((item, index) => {
			let exported_item = [];
			headers.map((header_item, h_index) => {
				let val = item[header_item.attr];
				if (header_item.type === 'number') val = this.convertStrToNum(val);
				else if (header_item.type === 'bool')
					val = item[header_item.attr] === 1 ? 'TAK' : 'NIE';
				else if (header_item.type === 'calculate')
					val =
						this.convertStrToNum(item[header_item.first]) *
						this.convertStrToNum(item[header_item.second]);
				exported_item.push(val);
				return null;
			});
			export_data.push(exported_item);
			return null;
		});
		EXCEL.outPut({
			header: headers.map((item, index) => item.header),
			data: export_data,
			name: 'download'
		});
	};


	// getById = (arr, idVal, idCol='id', nameCol='name') => {
	// 	for (let ar of arr) {
	// 		if (+arr[idCol]  === +idVal ) {
	// 			return ar[nameCol];
	// 		}
	// 	}
	// 	return '';
	// };
}

export default new Utils();
