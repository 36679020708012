/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Typography, Grid, TextareaAutosize } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import PreviewButton from 'components/misc/PreviewButton';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import CheckMark from 'components/misc/CheckMark';
import ContractorAPI from 'apis/ContractorAPI';
import { contractor_header } from 'utils/xlsx_headers';
import useGlobalStyles from 'assets/style/styles';
import { exportToXlsx } from 'utils/functions';
import { useToasts } from 'react-toast-notifications';
import useStyles from './style';

const Contractor = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const header = [
		{ id: 'name', label: 'Nazwa', filter: 'text' },
		{ id: 'code', label: 'Kod', filter: 'text' },
		{ id: 'GTIN', label: 'GLN', filter: 'text' },
		{ id: 'regon', label: 'REGON', filter: 'text' },
		{ id: 'nip', label: 'NIP', filter: 'text' },
		{
			id: 'supplier',
			label: 'Dostawca',
			align: 'center',
			filter: 'select',
			list: [
				{ id: 0, name: 'Nie' },
				{ id: 1, name: 'Tak' }
			]
		},
		{
			id: 'recipient',
			label: 'Odbiorca',
			align: 'center',
			filter: 'select',
			list: [
				{ id: 0, name: 'Nie' },
				{ id: 1, name: 'Tak' }
			]
		},
		{
			id: 'active',
			label: 'Aktywny',
			align: 'center',
			filter: 'select',
			list: [
				{ id: 0, name: 'Nie' },
				{ id: 1, name: 'Tak' }
			]
		},
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	];
	const globalClasses = useGlobalStyles();

	const handleEdit = id => {
		history.push(PATHS.ContractorEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			ContractorAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		ContractorAPI.create_list(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		ContractorAPI.export(options).then(response => {
			if (response.code === 200) {
				exportToXlsx(contractor_header, response.data, 'Kontrahenci');
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		ContractorAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	const handlePreview = index => {
		let rows = JSON.parse(JSON.stringify(data));
		rows[index].preview = !rows[index].preview;
		setData(rows);
	};

	return (
		<>
			<TopButtonsBox
				add_button={{
					label: 'Dodaj nowego kontrahenta',
					path: PATHS.ContractorCreate
				}}
				exportHandler={handleExport}
				importHandler={handleImport}
				header_list={contractor_header}
			/>

			<Breadcrumbs list={['Dane podstawowe', 'Kontrahenci']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{row.name}</Cell>
								<Cell>{row.code}</Cell>
								<Cell>{row.GTIN}</Cell>
								<Cell>{row.regon}</Cell>
								<Cell>{row.nip}</Cell>
								<Cell align="center">
									<CheckMark checked={row.supplier} />
								</Cell>
								<Cell align="center">
									<CheckMark checked={row.recipient} />
								</Cell>
								<Cell align="center">
									<CheckMark checked={row.active} />
								</Cell>
								<Cell align="center" width={180}>
									<PreviewButton
										active={!!row.preview}
										onClick={() => handlePreview(index)}
									/>
									<EditButton onClick={() => handleEdit(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>
							{row.preview && (
								<Row>
									<Cell colSpan={9}>
										<Grid
											container
											spacing={3}
											justifyContent="space-around"
											style={{ padding: '10px 50px' }}>
											<Grid item xs={12}>
												<Grid container spacing={2}>
													<Grid item xs={1}>
														<Typography
															variant="h6"
															style={{ marginBottom: 4 }}>
															Adres
														</Typography>
													</Grid>
													<Grid item xs={1}>
														<input
															disabled
															value={row.street}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={1}>
														<input
															disabled
															value={row.building_number}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={1}>
														<input
															disabled
															value={row.apartment_number}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={1}>
														<input
															disabled
															value={row.postal_code}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={1}>
														<input
															disabled
															value={row.city}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid
														item
														xs={5}
														style={{
															display: 'flex',
															justifyContent: 'flex-end',
															alignItems: 'center'
														}}>
														<CheckMark active={row.supplier_transport} />
														<Typography variant="h6">
															Dostawca pokrywa koszt transportu
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6}>
												<Typography
													variant="h6"
													style={{ marginBottom: 4, marginTop: 8 }}>
													Opis
												</Typography>
												<TextareaAutosize
													rows={4}
													disabled
													value={row.description}
													className={globalClasses.area}
												/>
											</Grid>
											<Grid item xs={6} style={{ marginTop: 30 }}>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 4 }}>
															Minimalna wiekość zamówienia
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={row.minimum_order_quantity}
															className={globalClasses.input_box}
														/>
													</Grid>
													<Grid item xs={6}>
														<Typography
															variant="h6"
															style={{ marginBottom: 4 }}>
															Minimalna wartość zamówienia [PLN]
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<input
															disabled
															value={row.minimum_order_value}
															className={globalClasses.input_box}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Cell>
								</Row>
							)}
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default Contractor;
