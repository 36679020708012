import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import readXlsxFile from 'read-excel-file';
import constants from 'utils/constants';

import Button from 'components/Button';
import './top-buttons-box.scss';

const TopButtonsBox = props => {
	const history = useHistory();
	const { addToast } = useToasts();
	const import_input = useRef();

	const { extra_classes, add_button, generate_button, search_button, header_list } = props;
	const { importHandler, exportHandler, exportText = 'Eksportuj listę do XLS', exportTitle } = props;

	const importImportOnChange = e => {
		readXlsxFile(e.target.files[0]).then(rows => {
			let header = rows[0];
			let result = [];
			if (header.length !== header_list.length) {
				addToast(constants.WRONG_XLSX_HEADER_LENGTH, {
					appearance: 'error'
				});
			} else {
				let invalid = [];
				header.map((item, index) => {
					if (item !== header_list[index].label.replace(/\n/, '')) {
						invalid.push(item);
					}
					return null;
				});

				if (invalid.length) {
					addToast(constants.WRONG_XLSX_HEADER + invalid.join(', '), {
						appearance: 'error'
					});
				} else {
					rows.splice(0, 1);
					rows.map((row, index) => {
						let res_item = {};
						row.map((item, index) => {
							res_item = { ...res_item, [header_list[index].id]: item };
							return null;
						});
						result.push(res_item);
						return null;
					});

					importHandler(result);
				}
			}
		});
	};

	return (
		<div className={`top-buttons-box ${extra_classes || ''}`}>
			<div className="top-buttons-box__left">
				{add_button && add_button.path && (
					<Button onClick={() => history.push(add_button.path)}>
						{add_button.label || 'Dodaj dokument'}
					</Button>
				)}
				{search_button && search_button.path && (
					<Button onClick={() => history.push(search_button.path)}>
						{search_button.label || 'Wyszukaj dokument'}
					</Button>
				)}

				{importHandler && (
					<>
						<input
							ref={import_input}
							type="file"
							accept=".xls,.xlsx"
							style={{ display: 'none' }}
							onChange={importImportOnChange}
						/>

						<Button
							onClick={() => {
								import_input.current && import_input.current.click();
							}}>
							Dodaj dane z pliku XLS
						</Button>
					</>
				)}

				{generate_button &&
					generate_button.handler &&
					generate_button.label && (
						<Button onClick={generate_button.handler}>
							{generate_button.label}
						</Button>
					)}
			</div>

			<div className="top-buttons-box__right">
				{exportHandler && (
					<Button onClick={exportHandler} title={exportTitle}> {exportText} </Button>
				)}
			</div>
		</div>
	);
};

export default TopButtonsBox;
