import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import PATHS from 'routes/paths';

const WelcomeDetails = () => {
	const classes = useStyles();
	return (
		<>
			<Grid container
						spacing={4}
						direction='row'
						justifyContent='center'>
				<Grid item xs={12} sm={10} md={8}>
					<Typography variant='h1' component='h2' className={classes.welcomeIntro}>
						Witaj w naszym systemie!
					</Typography>
					<Typography component='p' className={classes.welcomeText}>
						<strong>UWAGA!</strong> Wypełnij dane w zakładce "Moja Firma", aby poprawnie korzystać z aplikacji.
					</Typography>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
						<Card className={classes.root} variant='outlined'>
							<CardContent>
								<Typography className={classes.title} gutterBottom>
									Przejdź do systemu
								</Typography>

								<Grid container
											spacing={2}
											direction='row'
											justifyContent='center'
											style={{ marginTop: 20 }}>
									<Grid item xs={8}>
										<Button component={Link} to={PATHS.WarehouseOperationList} className={classes.filled_button}
														variant='outlined'>
											Operacje magazynowe
										</Button>
									</Grid>
									<Grid item xs={8}>
										<Button component={Link} to={PATHS.DocumentPZList} className={classes.filled_button} variant='outlined'>
											Przyjęcia zewnętrzne
										</Button>
									</Grid>
									<Grid item xs={8}>
										<Button component={Link} to={PATHS.AnalyzeXyzList} className={classes.filled_button} variant='outlined'>
											Klasyfikacje asortymentu
										</Button>
									</Grid>
									<Grid item xs={8}>
										<Button component={Link} to={PATHS.StockAnalyzeList} className={classes.filled_button}
														variant='outlined'>
											Analizy zapasów
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				{/*<Grid item xs={6}>*/}
				{/*	<Card className={classes.root} variant='outlined'>*/}
				{/*		<CardContent>*/}
				{/*			<Typography className={classes.title} gutterBottom>*/}
				{/*				Pomoc i poradniki*/}
				{/*			</Typography>*/}
				{/*			<Grid container alignItems={'flex-end'} spacing={3}>*/}
				{/*				<Grid item xs={6}>*/}
				{/*					<Typography component='p' className={classes.text}>*/}
				{/*						Potrzebujesz pomocy? <br />*/}
				{/*						Skorzystaj z naszych poradników!*/}
				{/*					</Typography>*/}
				{/*				</Grid>*/}
				{/*				<Grid item xs={6}>*/}
				{/*					<Button component={Link} to={'/'} className={classes.filled_button} variant='outlined'>*/}
				{/*						Pobierz poradnik*/}
				{/*					</Button>*/}
				{/*				</Grid>*/}
				{/*			</Grid>*/}
				{/*		</CardContent>*/}
				{/*	</Card>*/}
				{/*	<Card className={classes.root} variant='outlined'>*/}
				{/*		<CardContent>*/}
				{/*			<Typography className={classes.title} gutterBottom>*/}
				{/*				Sklep i abonament*/}
				{/*			</Typography>*/}
				{/*			<Grid container justifyContent="center" alignItems="flex-end" spacing={3}>*/}
				{/*				<Grid item xs={6}>*/}
				{/*					<Typography component='p' className={classes.text}>*/}
				{/*						Zobacz ofertę naszych abonentów i wybierz najlepszą ofertę.*/}
				{/*					</Typography>*/}
				{/*				</Grid>*/}
				{/*				<Grid item xs={6}>*/}
				{/*					<Button component={Link} to={'/'} className={classes.filled_button} variant='outlined'>*/}
				{/*						Zobacz ofertę*/}
				{/*					</Button>*/}
				{/*				</Grid>*/}
				{/*				<Grid item xs={6}>*/}
				{/*					<Typography component='p' className={classes.text}>*/}
				{/*						Przejdź do naszego sklepu e-bit!*/}
				{/*					</Typography>*/}
				{/*				</Grid>*/}
				{/*				<Grid item xs={6}>*/}
				{/*					<Button component={Link} to={'/'} className={classes.filled_button} variant='outlined'>*/}
				{/*						Sklep*/}
				{/*					</Button>*/}
				{/*				</Grid>*/}
				{/*			</Grid>*/}
				{/*		</CardContent>*/}
				{/*	</Card>*/}
				{/*</Grid>*/}
			</Grid>
		</>
	);
};

export default WelcomeDetails;
