import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center'
	},

	loginForm: {
		maxWidth: 620,
	},

	description: {
		maxWidth: 620,
		marginBottom: theme.spacing(1)
	},

	title: {
		maxWidth: 450,
		margin: 'auto'
	},

	form: {
		maxWidth: 300,
		margin: 'auto',
		marginTop: theme.spacing(5)
	},

	buttonContainer: {
		marginTop: theme.spacing(10)
	},
	input_box_label: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
		fontSize: '0.875em',
		color: theme.palette.black
	},
	input_box: {
		'& svg': {
			fill: theme.palette.text.secondary
		},
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.black_white,
		border: `1px solid ${theme.palette.text.primary}`,
		padding: '10px 20px',
		width: '100%',
		marginBottom: 10
	},
	error_log: {
		color: 'red',
		fontSize: '0.875em',
		marginTop: 10
	},
	btnLogin: {
		'& .MuiButton-label': {
			textTransform: 'none',
			fontSize: '1em'
		},
		width: '100%',
		color: theme.palette.black
	},
	rippleClass: {
		backgroundColor: 'red'
	},
	btnForgot: {
		marginTop: theme.spacing(2),
		'&:hover': {
			textDecoration: 'none'
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		color: theme.palette.black,
		fontSize: '0.9em',
		fontWeight: 600
	},
	btnRegister: {
		marginTop: theme.spacing(8),
		'&:hover': {
			textDecoration: 'none'
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		color: theme.palette.green,
		fontSize: '0.9em',
		fontWeight: 500
	},
	btnGoToLogin: {
		marginTop: theme.spacing(8),
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: '#000',
			color: '#fff'
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		color: '#000',
		fontSize: '0.9em',
		fontWeight: 500,
		border: '2px solid #000',
		padding: 10
	},
	arrow: {
		color: theme.palette.green,
		marginLeft: theme.spacing(2)
	},
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	inlineLink: {
		display: 'inline',
		fontWeight: 'bold',
		color: '#000',
		marginLeft: 4,
		marginRight: 4,
	}
}));

export default useStyles;
