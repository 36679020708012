import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar } from './components';

const useStyles = makeStyles(theme => ({
	root: {
		// backgroundColor: theme.palette.background.default,
		paddingTop: 56
		// height: '100%',

	},
	shiftContent: {
		paddingLeft: 220
	},
	topbar_max: {
		transform: 'translateX(-220px)',
		visibility: 'visible',
		width: '100%'
	},
	topbar_min: {
		transform: 'translateX(0)',
		visibility: 'visible'

	},
	content: {
		position: 'relative',
		height: 'calc(100vh - 56px)',
		overflow: 'scroll',
		backgroundColor: theme.palette.background.default
	},
	mainContainer: {
		padding: theme.spacing(5, 4, 0, 4),
		minHeight: 'calc(100vh - 56px)',
	},
}));

const Main = props => {
	const { children } = props;

	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true
	});

	const [openSidebar, setOpenSidebar] = useState(true);

	const handleSidebarOpen = () => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};

	const shouldOpenSidebar = openSidebar;

	useEffect(() => {
		if (isDesktop === false) {
			setOpenSidebar(false);
		}
	}, [isDesktop]);

	return (
		<div
			className={clsx({
				[classes.root]: true,
				[classes.shiftContent]: openSidebar && isDesktop
			})}
		>
			<Topbar
				onSidebarOpen={handleSidebarOpen}
				onSidebarClose={handleSidebarClose}
				openSidebar={openSidebar}
				className={!openSidebar || !isDesktop ? classes.topbar_max : classes.topbar_min}
				title={props.title}
			/>

			<Sidebar
				onClose={handleSidebarClose}
				open={shouldOpenSidebar}
				variant={isDesktop ? 'persistent' : 'temporary'}
			/>
			<main className={classes.content}>
				<div className={classes.mainContainer}>
					{children}
				</div>
			</main>
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node
};

export default Main;
