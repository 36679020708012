import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';

import PATHS from 'routes/paths';
import { ReactComponent as LogoSvg } from 'svg/logo.svg';
import useStyles from './style';
import SidebarNavTree from './components/SidebarNavTree/SidebarNavTree';
import { getRole, isSubscriptionValid } from 'apis/AuthAPI';

const Sidebar = props => {
	const { open, variant, history, onClose, className, staticContext, ...rest } = props;
	const classes = useStyles();
	// const location = useLocation();
	const role = getRole();

	let profileItems = [
		{
			title: 'Edytuj',
			href: PATHS.Profile,
		},
	];
	if (role !== 'managed_user') {
		profileItems.push(
			{
				title: 'Moje faktury',
				href: PATHS.ProfileInvoiceList,
			}
		);
	}

	let itemsTree = [
		{
			title: 'Kokpit',
			href: PATHS.Welcome,
		},
		{
			title: 'Moja firma',
			href: PATHS.MyCompany,
		},
		{
			label: 'PROFIL',
			items: profileItems,
		},
	];

	if (isSubscriptionValid()) {
		itemsTree.push(...[
			{
				label: 'DANE PODSTAWOWE',
				items: [
					{
						title: 'Asortyment',
						href: PATHS.AssortmentList,
					},
					{
						title: 'Grupy asortymentowe',
						href: PATHS.AssortmentGroupList,
					},
					{
						title: 'Magazyny',
						href: PATHS.WarehouseList,
					},
					{
						title: 'Grupy magazynów',
						href: PATHS.WarehouseGroupList,
					},
					{
						title: 'Kontrahenci',
						href: PATHS.ContractorList,
					},
					{
						title: 'Jednostki miary',
						href: PATHS.MeasurementUnitList,
					},
					{
						title: 'Operacje magazynowe',
						href: PATHS.WarehouseOperationList,
					}
				]
			},
			{
				label: 'DOKUMENTY',
				items: [
					{
						title: 'Zamówienia',
						href: PATHS.OrderList,
					},
					{
						title: 'Przyjęcia zewnętrzne (PZ)',
						href: PATHS.DocumentPZList,
					},
					{
						title: 'Wydania zewnętrzne (WZ)',
						href: PATHS.DocumentWZList,
					},
					{
						title: 'Faktury (FV)',
						href: PATHS.InvoiceList,
					}
				]
			},
			{
				label: 'ANALIZY',
				items: [
					{
						title: 'Klasyfikacje asortymentu',
						href: PATHS.AnalyzeXyzList,
					},
					{
						title: 'Analizy zapasów',
						href: PATHS.StockAnalyzeList,
					}
				]
			},
		]);
	}

	if (role === 'manager') {
		itemsTree.push({
			label: 'ADMINISTRACJA',
			items: [
				{
					title: 'Współpracownicy',
					href: PATHS.AdminUserList,
				},
			]
		});
	}

	if (role === 'superadmin') {
		itemsTree.push({
			label: 'ADMINISTRACJA',
			items: [
				{
					title: 'Użytkownicy',
					href: PATHS.AdminUserList,
				},
				{
					title: 'Faktury',
					href: PATHS.AdminInvoiceList,
				},
				{
					title: 'Plany abonamentowe',
					href: PATHS.AdminPlanList,
				},
				{
					title: 'Abonamenty',
					href: PATHS.AdminSubscriptionList,
				},
				{
					title: 'Płatności',
					href: PATHS.AdminPaymentList,
				},
			]
		});
	}

	return (
		<Drawer
			anchor='left'
			classes={{ paper: classes.drawer }}
			onClose={onClose}
			open={open}
			variant={variant}
		>
			<div
				{...rest}
				className={clsx(classes.root, className)}
			>

				<div className={classes.logoBlock}>
					<LogoSvg/>
				</div>

				<SidebarNavTree
					className={classes.nav}
					pages={itemsTree}
					history={history}
				/>

			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default withRouter(Sidebar);
