/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import useStyles from './style';
import useGlobalStyles from 'assets/style/styles';
import { ProgressBar } from 'components';
import auth from 'apis/AuthAPI';
import constants from 'utils/constants';
import clsx from 'clsx';
import { useToasts } from 'react-toast-notifications';
import PATHS from 'routes/paths';
import { validatePassword } from 'helpers/form';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
	const history = useHistory();
	let query = useQuery();
	let token = query.get('token');
	const classes = useStyles();
	const global_classes = useGlobalStyles();
	const [input, setInput] = useState({});
	const [error, setError] = useState({});

	const [progressStatus, setProgressStatus] = useState(false);
	const { addToast } = useToasts();

	const handleChange = event => {
		let arr = JSON.parse(JSON.stringify(input));
		arr[event.target.name] = event.target.value;
		setInput(arr);
	};

	const handleResetPassword = event => {
		if ((error && ((error.password && error.password.length > 0) || (error.reset_password && error.reset_password.length > 0))) || !input.password || !input.reset_password) {
			addToast(constants.CHECK_ALL_FIELDS, { appearance: 'warning', autoDismissTimeout: 5000, autoDismiss: true });
		} else {
			setProgressStatus(true);
			auth
				.reset_password(input.password, token)
				.then(response => {
					setProgressStatus(false);
					let appearance = 'error';
					if (response.code === 200) {
						appearance = 'success';
						setTimeout(() => history.push(PATHS.Login), 100);
					}
					addToast(response.message, { appearance, autoDismissTimeout: 5000 });
				});
		}
	};
	const handleKeyPress = (event) => {
		if (event.charCode === 13) {
			handleResetPassword();
		}
	};
	useEffect(() => {
	}, []);
	useEffect(() => {
		let arr = JSON.parse(JSON.stringify(error));
		arr['password'] = validatePassword(input['password']);
		if (input['reset_password'] && input['reset_password'] !== input['password']) {
			arr['reset_password'] = constants.ENTER_SAME_PASSWORD;
		} else {
			arr['reset_password'] = '';
		}

		setError(arr);
	}, [input]);

	return (
		<>
			<div className={classes.root}>
				<div className={classes.loginForm}>
					<Typography variant={'h2'} className={clsx(classes.description, global_classes.normal_font)}>Ustaw nowe
						hasło</Typography>
					<div className={classes.form}>
						<div className={classes.input_box_label} htmlFor='passwordInput'>Hasło</div>
						<input className={classes.input_box} type='password' value={input.password} name='password'
									 placeholder='Hasło' onChange={handleChange} onKeyPress={handleKeyPress} />
						<div
							className={classes.error_log}>{error['password'] && error['password'].length > 0 && error.password}</div>
						<div className={classes.input_box_label} htmlFor='resetpasswordInput'>Powtórz hasło</div>
						<input className={classes.input_box} type='password' value={input.reset_password} name='reset_password'
									 placeholder='Powtórz hasło' onChange={handleChange} onKeyPress={handleKeyPress} />
						<div
							className={classes.error_log}>{error['reset_password'] && error['reset_password'].length > 0 && error.reset_password}</div>
						<div className={classes.buttonContainer}>
							<Button variant='outlined' className={clsx(classes.btnLogin, global_classes.outline_button)}
											onClick={handleResetPassword}>
								Ustaw hasło
							</Button>
						</div>
					</div>
				</div>
				<ProgressBar progressStatus={progressStatus} />
			</div>
		</>
	);
};

ResetPassword.propTypes = {
	history: PropTypes.object
};

export default withRouter(ResetPassword);
