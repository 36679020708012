/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useDidUpdate from 'hooks/useDidUpdate';
import { isFunction, isObject } from 'utils/functions';

import Button from 'components/Button';
import './document-table.scss';

export default function DocumentTable(props) {
	const {
		head,
		column_sizes,
		RowComponent,
		assortments,
		assortments_options,
		...row_props
	} = props;

	const [rows, setRows] = useState([]);
	const [assortmentList, setAssortmentList] = useState([]);

	const DocumentTableTh = props => (
		<div
			className="document-table__th steal-bg"
			style={
				column_sizes
					? {
							flex: `0 0 ${column_sizes[props.index]}px`,
							maxWidth: `${column_sizes[props.index]}px`
					  }
					: {}
			}>
			{props.th}
		</div>
	);

	const getThGroupWidthStyles = items => {
		const width = items.reduce((total, current) => {
			total += column_sizes[current.index];
			return total;
		}, 0);

		return {
			flex: `0 0 ${width}px`,
			maxWidth: `${width}px`
		};
	};

	const DocumentTableHeader = () => {
		let proper_index = -1;

		if (!head || !head.length) return [];

		return head.map((item, i) => {
			if (isObject(item)) {
				return (
					<div
						key={i}
						className="document-table__th_group"
						style={getThGroupWidthStyles(item.items)}>
						<div style={{ textAlign: 'center' }} className="steal-bg">
							{item.head}
						</div>

						<div className="flex">
							{item.items &&
								!!item.items.length &&
								item.items.map((th, j) => {
									proper_index++;

									return <DocumentTableTh key={`${i}_${j}`} {...th} />;
								})}
						</div>
					</div>
				);
			}

			proper_index++;

			return <DocumentTableTh key={i} index={proper_index} th={item} />;
		});
	};

	const changeRow = (row_index, new_data) => {
		setRows(prev => {
			prev[row_index] = { ...prev[row_index], ...new_data };
			return [...prev];
		});
	};

	const removeRow = row_index => {
		setRows(rows.filter((r, i) => i !== row_index));
	};

	const addNewItem = () => {
		setRows([...rows, {}]);
	};

	useEffect(() => {
		if (assortments && assortments.length) {
			setRows([
				...assortments.map(a => {
					a.code = a.assortment.gtin;
					a.measure_name = a.assortment.measure_name;
					return a;
				})
			]);
		} else {
			setRows([]);
		}
	}, [assortments]);

	useEffect(() => {
		let assIds = rows
			.map(function(i) {
				return i.assortment_id;
			})
			.filter(function(i) {
				return i !== undefined;
			});
		setAssortmentList(
			assortments_options.filter(i => {
				return assIds.indexOf(i.id) < 0;
			})
		);
	}, [rows, assortments_options]);

	useDidUpdate(
		() => isFunction(props.onRowsChange) && props.onRowsChange(rows),
		[rows]
	);

	return (
		<div className="document-table">
			<div className="document-table__head flex">
				<DocumentTableHeader />
			</div>

			<div className="document-table__body">
				{rows &&
					!!rows.length &&
					rows.map((row, i) => (
						<RowComponent
							key={i}
							index={i}
							assortmentList={assortmentList}
							assortments_options={assortments_options}
							column_sizes={column_sizes}
							row={row}
							{...row_props}
							changeRow={changeRow}
							removeRow={removeRow}
						/>
					))}
			</div>

			<div className="document-table__bottom">
				<Button onClick={addNewItem}>Dodaj nowy towar</Button>
			</div>
		</div>
	);
}
