import React from 'react';


export default function DocumentTableRemove(props) {
	return (
		<a
			href='/#'
			className='document-table__remove'
			onClick={e => {
				e.preventDefault();
				props.onClick();
			}}
		>
			X
		</a>
	);
}

