/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Autocomplete from 'components/FormInput/Autocomplete';

import PATHS from 'routes/paths';
import WarehouseAPI from 'apis/WarehouseAPI';
import DocumentsPZAPI from 'apis/DocumentPZAPI';
import OrdersAPI from 'apis/OrderAPI';
import ContractorAPI from 'apis/ContractorAPI';
import UserAPI from 'apis/UserAPI';
import { getPriceZlAndGr, groupByFirstLetter } from 'utils/functions';
import { Breadcrumbs, Loader } from 'components';
import DocumentBox from 'components/document/DocumentBox';
import DocumentContractors from 'components/document/DocumentContractors';
import Document from 'components/document/Document';
import FormInput from 'components/form/FormInput';
import DocumentPZCreateTable from './DocumentPZCreateTable';
import DatePicker from 'components/FormInput/MyDatePicker';
import Select from 'react-select';
import axios from 'axios';

const today = moment(new Date()).format('YYYY-MM-DD');

const DocumentPZ = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);

	const costShippingOptions = [
		{ label: 'Sprzedającego', value: 'seller' },
		{ label: 'Zamawiajacego', value: 'buyer' }
	];
	const [data, setData] = useState({
		cost_free_shipping: 'seller',
		issue_date: today,
		shipment_date: today,
		receipt_date: today,
		document_id: ''
	});
	const [totals, setTotals] = useState({
		price: 0,
		value: 0,
		stock: 0
	});
	const [PZ, setPZ] = useState({});
	const [items, setItems] = useState([]);
	const [contractors, setContractors] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [myCompany, setMyCompany] = useState({});
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState();
	const [assortmentsOptions, setAssortmentsOptions] = useState([]);

	const bottomContent = [
		{
			width: '304px',
			heading: 'Wystawił',
			content: <span className="gray-color"> Jan Kowalski </span>
		},

		{
			width: '168px',
			heading: 'Zatwierdził',
			content: <span className="gray-color"> Janina Nowak </span>
		},

		{
			width: '218px',
			heading: 'Magazyn',
			content: (
				<Autocomplete
					id="warehouse"
					options={groupByFirstLetter(warehouses)}
					value={warehouses.find(i => +i.id === +data.warehouse_id)}
					groupBy={option => option.firstLetter}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.id}
					onChange={warehouse => {
						setData({ ...data, warehouse_id: warehouse?.id });
					}}
				/>
			)
		},

		{
			width: '415px',
			group: {
				heading: 'WYMIENIONE ILOŚCI',
				items: [
					{
						heading: 'Dostarczył',
						content: <span className="gray-color"> Dariusz Wiśniewski </span>
					},
					{
						heading: 'Data',
						content: (
							<DatePicker
								value={data.receipt_date}
								onChange={val => {
									setData({ ...data, receipt_date: val });
								}}
							/>
						)
					},
					{
						heading: 'Przyjął',
						content: <span className="gray-color"> Janina Kowalska </span>
					}
				]
			}
		},

		{
			width: '',
			heading: 'Ewidencja ilościowo-wartościowa',
			content: <span className="gray-color"> Jan Nowak </span>
		}
	];

	useEffect(() => {
		axios.all([
			id ? DocumentsPZAPI.get(id) : DocumentsPZAPI.getInfo(),
			UserAPI.getCompany(),
			ContractorAPI.getProviders(),
			WarehouseAPI.getAll(),
			OrdersAPI.getAll(),
		]).then(axios.spread((
			item,
			company,
			contractors,
			warehouses,
			orders
		) => {
			if (item?.data) {
				if (id) {
					setData(item.data);
					setPZ({ ...item.data });
				} else {
					setData({ ...data, document_id: item.data.document_pz?.formated })
				}
			}
			company?.data && setMyCompany(company.data);
			contractors?.data && setContractors(contractors.data.contractors_list);
			warehouses?.data && setWarehouses(warehouses.data?.filter(i => i.active));
			orders?.data && setOrders(orders.data.map(o => {
				o.assortments = o.assortments.map(i => ({
					...i,
					delivered_quantity: i.quantity,
					received_quantity: i.quantity,
					price: i.assortment.sale_price,
				}));
				return o;
			}));

			setLoading(false);
		}));
	}, []);

	const changeData = (name, value) => setData({ ...data, [name]: value });

	const validate = entity => {
		if (!entity.supplier_id) {
			addToast('Nie wybrano dostawcy.', { appearance: 'error' });
			return false;
		}
		if (!entity.order_id) {
			addToast('Nie wybrano zamówienia.', { appearance: 'error' });
			return false;
		}
		if (!entity.items?.length) {
			addToast('Nie wybrano asortymentu.', { appearance: 'error' });
			return false;
		}

		for (let ass of entity.items) {
			if (!ass.assortment_id) {
				addToast('Nie wybrano asortymentu.', { appearance: 'error' });
				return false;
			}
			if (!ass.delivered_quantity || !ass.received_quantity) {
				addToast('Nie podano ilości.', { appearance: 'error' });
				return false;
			}
			if (!(ass.handling_delivery_cost + '').length) {
				addToast('Nie podano kosztów obsługi dostawy.', {
					appearance: 'error'
				});
				return false;
			}
		}

		if (!entity.warehouse_id) {
			addToast('Nie wybrano magazynu.', { appearance: 'error' });
			return false;
		}

		return true;
	};

	const handleSave = () => {
		let entity = {
			...data,
			supplier_id: data.supplier?.id,
			order_id: selectedOrder?.id,
			items: items && !!items.length ? items.map(item => ({
				id: false, ...item, document_pz_id: data.document_id
			})) : null
		};

		if (!validate(entity)) {
			return;
		}

		setLoading(true);
		DocumentsPZAPI.updateOrCreate(entity, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message, { appearance: 'success' });
				setTimeout(() => history.push(PATHS.DocumentPZList), 100);
			} else {
				addToast(response.message, { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (selectedOrder) {
			setAssortmentsOptions(
				selectedOrder.assortments.map(i => {
					return { ...i.assortment, quantity: i.quantity, stock: i.stock };
				})
			);
		}
	}, [selectedOrder]);

	useEffect(() => {
		if (orders.length && data.order_id) {
			setSelectedOrder(orders.find(i => +i.id === data.order_id));
		}
	}, [data.order_id, orders]);

	useEffect(() => {
		let temp = {
			price: 0,
			value: 0,
			stock: 0,
			handling_delivery_cost: 0
		};

		for (let item of items) {
			temp.price += isNaN(item.price) ? 0 : +item.price;
			temp.value += isNaN(item.value) ? 0 : +item.value;
			temp.stock += isNaN(item.stock) ? 0 : +item.stock;
			temp.handling_delivery_cost += isNaN(item.handling_delivery_cost)
				? 0
				: +item.handling_delivery_cost;
		}
		let [price_zl, price_gr] = getPriceZlAndGr(temp.price);
		let [value_zl, value_gr] = getPriceZlAndGr(temp.value);

		setTotals({
			price_zl: price_zl,
			price_gr: price_gr,
			value_zl: value_zl,
			value_gr: value_gr,
			stock: temp.stock,
			handling_delivery_cost: temp.handling_delivery_cost
		});
	}, [items]);

	useEffect(() => {
		if (contractors && data) {
			setData({
				...data,
				supplier: contractors.find(i => i.id === data.supplier_id)
			});
		}
	}, [contractors, data.supplier_id]);

	return (
		<>
			<Breadcrumbs
				list={['Dokumenty', { label: 'Przyjęcia', path: PATHS.DocumentPZList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.DocumentPZList}
			/>

			<Loader loading={loading}>
				<Document onSaveAndGenerate={handleSave}>
				<div className="flex">
					<div style={{ width: '1370px', minWidth: '1370px' }}>
						<div className="flex">
							<div style={{ flex: '0 0 382px' }}>
								<DocumentBox
									heading="Pieczęć firmowa"
									extra_classes="border"
									box_height={175}>
									<div>Firma {myCompany.company_name}</div>
									<div>{myCompany.street} {myCompany.numbers}</div>
									<div>{myCompany.postal_code} {myCompany.city}</div><br/>
									{myCompany.nip && <div>NIP: {myCompany.nip}</div>}
								</DocumentBox>
							</div>

							<div style={{ flex: '0 0 400px' }}>
								<DocumentBox
									heading="Dostawca"
									extra_classes="bt br bb"
									box_height={175}>
									<DocumentContractors
										name="supplier"
										id={data.supplier_id}
										contractors={contractors}
										onChange={c => setData({ ...data, supplier: c, supplier_id: c.id })}
									/>
									<div className="gray-color" style={{ marginTop: '1rem' }}>
										{data.supplier && (
											<>
												<div>{data.supplier.street} {data.supplier.numbers}</div>
												<div>{data.supplier.postal_code} {data.supplier.city}</div>
												{data.supplier.nip && <div>NIP: {data.supplier.nip}</div>}
											</>
										)}
									</div>
								</DocumentBox>
							</div>

							<div
								style={{ flex: '0 0 207px', textAlign: 'center' }}
								className="box steal-bg bt br bb">
								<div style={{ fontSize: '3.5em' }}> PZ</div>
								<div>Przyjęcie materiałów</div>

								<div> Z zewnątrz</div>
							</div>

							<div style={{ flex: '0 0 189px' }}>
								<DocumentBox
									heading="Nr bieżący"
									extra_classes="bt br bb"
									box_height={175}>
									<FormInput
										disabled
										name="document_id"
										value={data.document_id}
									/>
								</DocumentBox>
							</div>

							<div style={{ flex: '0 0 192px' }}>
								<DocumentBox
									heading="Egz."
									extra_classes="bt br bb"
									box_height={88}>
									1
								</DocumentBox>

								<DocumentBox
									heading="Data wystawienia"
									extra_classes="br bb"
									box_height={87}>
									<DatePicker
										value={data.issue_date}
										onChange={val => {
											setData({ ...data, issue_date: val });
										}}
									/>
								</DocumentBox>
							</div>
						</div>

						<div className="flex">
							<div style={{ flex: '0 0 382px' }}>
								<DocumentBox
									heading="Nr zamówienia"
									extra_classes="bl br bb"
									box_height={87}>
									<Select
										placeholder="Wybierz"
										noOptionsMessage={() => 'Brak opcji'}
										options={orders}
										getOptionLabel={option => [
											option.order_number,
											' (', option.purchaser.name, ' / ', option.assortments[0].assortment.name, ')'
										].join('')}
										getOptionValue={option => option.id}
										value={selectedOrder}
										isClearable={false}
										menuPlacement="auto"
										onChange={v => {
											setSelectedOrder(v);
											setData({
												...data,
												supplier: v.purchaser,
												supplier_id: v.purchaser.id,
												assortments: v.assortments,
												warehouse_id: v.warehouse_id,
											});
										}}
									/>
								</DocumentBox>
							</div>

							<div style={{ flex: '0 0 208px' }}>
								<DocumentBox
									heading="Środek transportu"
									extra_classes="br bb"
									box_height={87}>
									<FormInput
										name="transport_type"
										value={data.transport_type || ''}
										onChange={changeData}
									/>
								</DocumentBox>
							</div>

							<div style={{ flex: '0 0 294px' }}>
								<DocumentBox
									heading="Przeznaczenie"
									extra_classes="br bb"
									box_height={87}>
									<FormInput
										name="purpose"
										value={data.purpose || ''}
										onChange={changeData}
									/>
								</DocumentBox>
							</div>

							<div style={{ flex: '0 0 207px' }}>
								<DocumentBox
									heading="Data wysyłki"
									extra_classes="br bb"
									box_height={87}>
									<DatePicker
										value={data.shipment_date}
										onChange={val => {
											setData({ ...data, shipment_date: val });
										}}
									/>
								</DocumentBox>
							</div>

							<div style={{ flex: 'auto' }}>
								<DocumentBox
									heading="Wysyłka na koszt"
									extra_classes="br bb"
									box_height={87}>
									<Select
										placeholder="Wybierz"
										noOptionsMessage={() => 'Brak opcji'}
										options={costShippingOptions}
										value={costShippingOptions.find(
											i => i.value === data.cost_free_shipping
										)}
										onChange={v =>
											setData({ ...data, cost_free_shipping: v.value })
										}
										isSearchable={false}
									/>
								</DocumentBox>
							</div>
						</div>

						{selectedOrder && (
							<DocumentPZCreateTable
								PZ={PZ}
								key={selectedOrder?.id}
								assortments={data.assortments}
								assortments_options={assortmentsOptions}
								onRowsChange={rows => setItems(rows)}
							/>
						)}
					</div>
				</div>

				<div className="flex" style={{ marginTop: 20 }}>
					<div style={{ width: '1370px', minWidth: '1370px' }}>
						<div className="flex">
							<div
								style={{ flex: '0 0 940px', textAlign: 'right' }}
								className="box border steal-bg">
								RAZEM
							</div>
							<div
								style={{ flex: '0 0 68px', maxWidth: 68, borderLeft: 0 }}
								className="box border steal-bg">
								{totals.price_zl}
							</div>
							<div
								style={{ flex: '0 0 73px', maxWidth: 73, borderLeft: 0 }}
								className="box border steal-bg">
								{totals.price_gr}
							</div>
							<div
								style={{ flex: '0 0 62px', maxWidth: 62, borderLeft: 0 }}
								className="box border steal-bg">
								{totals.value_zl}
							</div>
							<div
								style={{ flex: '0 0 65px', maxWidth: 65, borderLeft: 0 }}
								className="box border steal-bg">
								{totals.value_gr}
							</div>
							<div
								style={{ flex: '0 0 162px', maxWidth: 162, borderLeft: 0 }}
								className="box border steal-bg">
								{totals.stock}
							</div>
							{/* <div style={{ flex: 'auto' }} className="box bt bb br" /> */}
						</div>

						<div className="flex bl">
							{bottomContent.map((item, i) => {
								const { width, heading, content, group } = item;

								if (heading && content) {
									return (
										<div
											key={i}
											style={{ flex: width ? `0 0 ${width}` : 'auto' }}>
											<div
												style={{ height: '80px' }}
												className="df-centered box steal-bg br bb">
												{heading}
											</div>

											<div
												style={{ height: '48px' }}
												className="df-centered box br bb">
												{content}
											</div>
										</div>
									);
								}

								if (group) {
									return (
										<div key={i} style={{ flex: `0 0 ${width}` }}>
											<div
												style={{ height: '38px' }}
												className="df-centered box steal-bg br bb">
												{group.heading}
											</div>

											<div className="flex">
												{group.items.map((subitem, j) => (
													<div key={j} style={{ flex: '1 1' }}>
														<div
															style={{ height: '42px' }}
															className="df-centered box steal-bg br bb">
															{subitem.heading}
														</div>
														<div
															style={{ height: '48px' }}
															className="df-centered box br bb">
															{subitem.content}
														</div>
													</div>
												))}
											</div>
										</div>
									);
								}

								return null;
							})}
						</div>
					</div>

					<div
						style={{ marginLeft: '20px', flex: 'auto', height: '29px' }}
						className="box border">
						{totals.handling_delivery_cost}
					</div>
				</div>
			</Document>
			</Loader>
		</>
	);
}

export default DocumentPZ;
