import React from 'react';

import { DocumentTable } from 'components/document/DocumentTable';
import DocumentWZCreateTableRow from './DocumentWZCreateTableRow';

const COLUMN_SIZES = [54, 140, 422, 194, 61, 159, 74, 82, 70, 78, 201];

const TABLE_HEAD = [
	'Lp.',
	'Kod towaru',
	'Nazwa materiału, wyrobu, opakowania',
	{
		head: 'Ilość',
		items: [
			{ th: 'Żądana', index: 3 },
			{ th: 'j.m.', index: 4 },
			{ th: 'Wydana', index: 5 }
		]
	},
	{
		head: 'Cena',
		items: [
			{ th: 'zł', index: 6 },
			{ th: 'gr', index: 7 }
		]
	},
	{
		head: 'Wartość',
		items: [
			{ th: 'zł', index: 8 },
			{ th: 'gr', index: 9 }
		]
	},
	'Zapas Ilość'
];

export default function DocumentWZCreateTable(props) {
	return (
		<DocumentTable
			{...props}
			head={TABLE_HEAD}
			column_sizes={COLUMN_SIZES}
			RowComponent={DocumentWZCreateTableRow}
		/>
	);
}

