import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	root: {
		overflow: 'hidden'
	},
	table: {
		width: 360,
		margin: '20px auto'
	}
});

export default function CompareTable(props) {
	const [data, setData] = useState({
		ax: 0,
		bx: 0,
		cx: 0,
		ay: 0,
		by: 0,
		cy: 0,
		az: 0,
		bz: 0,
		cz: 0
	});

	const classes = useStyles();

	const getStyle = val => {
		let cellStyle = {};
		if (val <= 5) {
			cellStyle.backgroundColor = '#ffffff';
		} else if (val <= 10) {
			cellStyle.backgroundColor = '#ecfbe9';
		} else if (val <= 15) {
			cellStyle.backgroundColor = '#c7f3bf';
		} else if (val > 15) {
			cellStyle.backgroundColor = '#a1ea95';
		}
		return { ...cellStyle, borderBottom: 0, textAlign: 'center' };
	};

	const getPercent = (d, g) => {
		return (
			Math.round((d?.filter(i => i.group === g)?.length * 10000) / d?.length) /
			100
		);
	};

	useEffect(() => {
		if (props.data) {
			setData({
				ax: getPercent(props.data, 'AX'),
				bx: getPercent(props.data, 'BX'),
				cx: getPercent(props.data, 'CX'),
				ay: getPercent(props.data, 'AY'),
				by: getPercent(props.data, 'BY'),
				cy: getPercent(props.data, 'CY'),
				az: getPercent(props.data, 'AZ'),
				bz: getPercent(props.data, 'BZ'),
				cz: getPercent(props.data, 'CZ')
			});
		}
	}, [props.data]);

	return (
		<TableContainer className={classes.root}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead style={{ borderBottom: '1px solid #E1E3E5' }}>
					<TableRow>
						<TableCell
							style={{
								width: 60,
								textAlign: 'center',
								borderRight: '1px solid #E1E3E5'
							}}>
							&nbsp;
						</TableCell>
						<TableCell style={{ width: 100, textAlign: 'center' }}>A</TableCell>
						<TableCell style={{ width: 100, textAlign: 'center' }}>B</TableCell>
						<TableCell style={{ width: 100, textAlign: 'center' }}>C</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow>
						<TableCell
							align="center"
							component="th"
							scope="row"
							style={{
								borderRight: '1px solid #E1E3E5',
								borderBottom: 0,
								backgroundColor: 'white'
							}}>
							X
						</TableCell>
						<TableCell style={getStyle(data?.ax)}>{data?.ax || 0}%</TableCell>
						<TableCell style={getStyle(data?.bx)}>{data?.bx || 0}%</TableCell>
						<TableCell style={getStyle(data?.cx)}>{data?.cx || 0}%</TableCell>
					</TableRow>

					<TableRow>
						<TableCell
							align="center"
							component="th"
							scope="row"
							style={{
								borderRight: '1px solid #E1E3E5',
								borderBottom: 0,
								backgroundColor: 'white'
							}}>
							Y
						</TableCell>
						<TableCell style={getStyle(data?.ay)}>{data?.ay || 0}%</TableCell>
						<TableCell style={getStyle(data?.by)}>{data?.by || 0}%</TableCell>
						<TableCell style={getStyle(data?.cy)}>{data?.cy || 0}%</TableCell>
					</TableRow>

					<TableRow>
						<TableCell
							align="center"
							component="th"
							scope="row"
							style={{
								borderRight: '1px solid #E1E3E5',
								borderBottom: 0,
								backgroundColor: 'white'
							}}>
							Z
						</TableCell>
						<TableCell style={getStyle(data?.az)}>{data?.az || 0}%</TableCell>
						<TableCell style={getStyle(data?.bz)}>{data?.bz || 0}%</TableCell>
						<TableCell style={getStyle(data?.cz)}>{data?.cz || 0}%</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}
