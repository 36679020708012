import React from 'react';

import { IconButton } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

import useGlobalStyles from 'assets/style/styles';

const PrintButton = props => {
	const globalClasses = useGlobalStyles();
	return (
		<IconButton
			className={globalClasses.iconButton}
			{...props}>
			<PrintOutlinedIcon className={globalClasses.icon} />
		</IconButton>
	);
};

export default PrintButton;
