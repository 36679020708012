/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Link, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import clsx from 'clsx';
import useStyles from './style';
import useGlobalStyles from 'assets/style/styles';
import { ProgressBar } from 'components';
import AuthAPI from 'apis/AuthAPI';
import storage from 'utils/storage';
import constants from 'utils/constants';
import { useToasts } from 'react-toast-notifications';
import AuthContext from 'context/AuthContext';
import PATHS from 'routes/paths';
import ContactFooter from './ContactFooter';

const SignIn = () => {
	// const { history } = props;

	const logIn = useContext(AuthContext).logIn;
	const classes = useStyles();
	const global_classes = useGlobalStyles();
	// const [checkStatus, setCheckStatus] = useState(false);
	const [input, setInput] = useState({ email: '', password: '' });
	const [error, setError] = useState({});
	const [progressStatus, setProgressStatus] = useState(false);
	const [tryLogin, setTryLogin] = useState(false);
	const { addToast } = useToasts();

	const handleChange = event => {
		let arr = JSON.parse(JSON.stringify(input));
		arr[event.target.name] = event.target.value;
		setInput(arr);
	};

	// const handleRememberMe = () => {
	//   setCheckStatus(!checkStatus);
	// };

	const handleSignIn = () => {
		setTryLogin(true);
		if (
			(error &&
				((error.email && error.email.length > 0) ||
					(error.password && error.password.length > 0))) ||
			!input.email ||
			!input.password
		) {
			addToast(constants.CHECK_ALL_FIELDS, {
				appearance: 'warning',
				autoDismissTimeout: 5000,
				autoDismiss: true
			});
		} else {
			setProgressStatus(true);
			AuthAPI.login(input.email, input.password).then(response => {
				if (response.code === 200) {
					setProgressStatus(false);
					logIn();
				} else {
					addToast(response.data?.message || 'Unknown error.', {
						appearance: 'error',
						autoDismissTimeout: 5000,
						autoDismiss: true
					});
					setProgressStatus(false);
				}
			});
		}
	};
	useEffect(() => {
		let arr = JSON.parse(JSON.stringify(input));
		if (storage.getStorage('email') && storage.getStorage('email').length > 0) {
			arr['email'] = storage.getStorage('email');
		}
		setInput(arr);
	}, []);
	useEffect(() => {
		let arr = JSON.parse(JSON.stringify(error));
		let pattern = new RegExp(
			/^[-!#$%&'*+/\d=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/\d=?A-Z^_a-z`{|}~])*@[a-zA-Z\d](-*\.?[a-zA-Z\d])*\.[a-zA-Z](-?[a-zA-Z\d])+$/i
		);
		if (input['email'] && !pattern.test(input['email'])) {
			arr['email'] = constants.ENTER_VALID_EMAIL;
		} else {
			arr['email'] = '';
		}

		setError(arr);
	}, [input]);

	const handleKeyPress = event => {
		if (event.charCode === 13) {
			handleSignIn();
		}
	};
	return (
		<>
			<div className={classes.root}>
				<div className={classes.loginForm}>
					<Typography
						className={clsx(classes.description, global_classes.normal_font)}
						variant={'h2'}>
						Witamy w aplikacji internetowej „MonZa Edu" przeznaczonej do
						nauczania w zakresie magazynowania w zawodzie technik logistyk!
					</Typography>
					<Typography
						className={clsx(classes.title, global_classes.normal_font)}
						variant={'h2'}>
						Zaloguj się, aby rozpocząć korzystanie z aplikacji.
					</Typography>
					<Typography
						className={clsx(classes.title, global_classes.normal_font)}
						variant={'h2'}>
						Jeżeli nie posiadasz konta wybierz opcję "Zarejestruj się".
					</Typography>
					<div className={classes.form}>
						<div className={classes.input_box_label}>Login</div>
						<input
							aria-label="e-mail"
							autoComplete="off"
							className={classes.input_box}
							id="email"
							name="email"
							onChange={handleChange}
							onKeyPress={handleKeyPress}
							type="email"
							value={input.email}
						/>
						<div className={classes.error_log}>
							{tryLogin &&
								error['email'] &&
								error['email'].length > 0 &&
								error.email}
						</div>
						<div className={classes.input_box_label}>
							<label htmlFor="password">Hasło</label>
						</div>
						<input
							className={classes.input_box}
							id="password"
							name="password"
							onChange={handleChange}
							onKeyPress={handleKeyPress}
							type="password"
							value={input.password}
						/>
						<div className={classes.error_log}>
							{tryLogin &&
								error['password'] &&
								error['password'].length > 0 &&
								error.password}
						</div>
						<div className={classes.buttonContainer}>
							<Button
								className={clsx(
									classes.btnLogin,
									global_classes.outline_button
								)}
								onClick={handleSignIn}
								variant="outlined">
								Zaloguj się
							</Button>
							<Link
								className={classes.btnForgot}
								component={RouterLink}
								to={PATHS.ForgotPassword}>
								Odzyskaj hasło
							</Link>
							<Link
								className={classes.btnRegister}
								component={RouterLink}
								to={PATHS.Registration}>
								Zarejestruj się <ArrowRightAltIcon className={classes.arrow} />
							</Link>
						</div>
						<div className={classes.linkContainer}>
							<Link
								className={classes.btnForgot}
								href="/regulamin-aplikacji.pdf">
								Regulamin aplikacji
							</Link>
							<Link
								className={classes.btnForgot}
								component={RouterLink}
								to={PATHS.PriceList}>
								Cennik
							</Link>
						</div>
					</div>

					<ContactFooter />
				</div>
			</div>
			<ProgressBar progressStatus={progressStatus} />
		</>
	);
};

SignIn.propTypes = {
	history: PropTypes.object
};

export default withRouter(SignIn);
