import React from 'react';
import PieChart from './PieChart';
import StackedChart from './StackedChart';

const Chart = props => {
	const { data } = props;

	return (
		<>
			{data[1] && (
				<>
					{data[1].y >= 0 && <PieChart data={data} />}
					{data[1].y < 0 && <StackedChart data={data} />}
				</>
			)}
		</>
	);
};

export default Chart;
