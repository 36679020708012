/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import OrderAPI from 'apis/OrderAPI';
import ContractorAPI from 'apis/ContractorAPI';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Table, Row, Cell } from 'components/MyTable';
import PreviewButton from 'components/misc/PreviewButton';
import EditButton from 'components/misc/EditButton';
import TrashButton from 'components/misc/TrashButton';
import PreviewTable from './PreviewTable';
import { clone, exportToXlsx } from 'utils/functions';
import { order_header } from 'utils/xlsx_headers';
import useStyles from "../WarehouseGroup/style";

const OrderList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const [header, setHeader] = useState([
		{ id: 'order_number', label: 'Numer zamówienia', filter: 'text' },
		{ id: 'purchaser', label: 'Zamawiający', filter: 'select', list: [] },
		{ id: 'document_of_date', label: 'Dokument z dnia', filter: 'date' },
		{ id: 'street', label: 'Adres wysyłkowy', filter: 'text' },
		{ id: 'regon', label: 'REGON', filter: 'text' },
		{ id: 'payment_terms', label: 'Warunki płatności', filter: 'text' },
		{ id: 'bank_account', label: 'Konto bankowe', filter: 'text' },
		{ id: 'nip', label: 'NIP', filter: 'text' },
		{
			id: 'action',
			label: 'Akcje',
			align: 'center',
			disableSort: true,
			disableExport: true
		}
	]);
	const [loadingInfo, setLoadingInfo] = useState(true);

	const handleEdit = id => {
		history.push(PATHS.OrderEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			OrderAPI.delete(deleteId).then((response) => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		OrderAPI.import(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		OrderAPI.export(options).then(response => {
			if (response.code === 200) {
				let data = [];
				response.data.forEach(row => {
					const items = row.assortments;
					delete row.assortments; // avoid reference related problems
					let values = [];
					if (items.length) {
						items.forEach((a, index) => {
							let item = clone(row);
							a['index'] = index + 1;
							item['item'] = a;
							values.push(item);
						});
					} else {
						values.push(row);
					}

					data.push(...values);
				});
				exportToXlsx(order_header, data, 'Zamówienia');
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		OrderAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	const handlePreview = index => {
		setData(prev => {
			prev[index].preview = !prev[index].preview;
			return [...prev];
		});
	};

	useEffect(() => {
		setLoadingInfo(true);
		ContractorAPI.getAll({ active: 1 }).then(res => {
			setHeader([
				...header.slice(0, 1),
				{ ...header[1], list: res.data },
				...header.slice(2)
			]);

			setLoadingInfo(false);
		});
	}, []);

	return (
		<>
			<TopButtonsBox
				add_button={{
					path: PATHS.OrderCreate,
					label: 'Dodaj dokument'
				}}
				importHandler={handleImport}
				exportHandler={handleExport}
				header_list={order_header}
			/>
			<Breadcrumbs list={['Dokumenty', 'Zamówienia']} />

			<Table
				header={header}
				loading={loading || loadingInfo}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => (
					<React.Fragment key={index}>
						<Row>
							<Cell>{row.order_number}</Cell>
							<Cell>{row.purchaser?.name}</Cell>
							<Cell>{moment(row.document_of_date).format('DD/MM/YYYY')}</Cell>
							<Cell>{`${row.street} ${row.building_number}/${row.apartment_number}, ${row.postal_code} ${row.city}`}</Cell>
							<Cell>{row.regon}</Cell>
							<Cell>{row.payment_terms}</Cell>
							<Cell>{row.bank_account}</Cell>
							<Cell>{row.nip}</Cell>
							<Cell align="center" width={180}>
								<PreviewButton
									active={row.preview}
									onClick={() => handlePreview(index)}
								/>
								<EditButton onClick={() => handleEdit(row.id)} />
								<TrashButton onClick={() => setDeleteId(row.id)} />
							</Cell>
						</Row>
						{row.preview && (
							<Row>
								<Cell colSpan={9}>
									<PreviewTable assortments={row.assortments} />
								</Cell>
							</Row>
						)}
					</React.Fragment>
				))}
			</Table>
		</>
	);
};

export default OrderList;
