import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import useGlobalStyles from '../../assets/style/styles';

const TrashButton = props => {
	const globalClasses = useGlobalStyles();
	return (
		<IconButton
			className={globalClasses.iconButton}
			{...props}>
			<DeleteOutlineOutlinedIcon className={globalClasses.icon} />
		</IconButton>
	);
};

export default TrashButton;
