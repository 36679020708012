/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import { Table, Row, Cell } from 'components/MyTable';
import PreviewButton from 'components/misc/PreviewButton';
import EditButton from 'components/misc/EditButton';
import { useToasts } from 'react-toast-notifications';
import TrashButton from 'components/misc/TrashButton';
import PreviewTable from './PreviewTable';
import moment from 'moment';
import { clone, exportToXlsx } from 'utils/functions';
import DocumentWZAPI from 'apis/DocumentWZAPI';
import ContractorAPI from 'apis/ContractorAPI';
import OrdersAPI from 'apis/OrderAPI';
import { document_wz_header } from 'utils/xlsx_headers';
import useStyles from './style';
import axios from 'axios';

const DocumentWZList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(true);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();
	const rowsPerPage = 20;
	const ships = [
		{ id: 'seller', name: 'Sprzedającego' },
		{ id: 'buyer', name: 'Zamawiajacego' }
	];
	const [header, setHeader] = useState([
		{ id: 'document_id', label: 'Numer dokumentu', filter: 'text' },
		{ id: 'recipient_id', label: 'Odbiorca', filter: 'select', list: [] },
		{ id: 'shipment_date', label: 'Data wysyłki', filter: 'date' },
		{
			id: 'order_id',
			label: 'Numer zamówienia',
			filter: 'select',
			list: [],
			filterOptionText: 'order_number'
		},
		{ id: 'transport_type', label: 'Środek transportu', filter: 'text' },
		{ id: 'purpose', label: 'Przeznaczenie', filter: 'text' },
		{ id: 'issue_date', label: 'Data wydania', filter: 'date' },
		{
			id: 'cost_free_shipping',
			label: 'Wysyłka na koszt',
			filter: 'select',
			list: ships
		},
		{ id: 'invoice_id', label: 'Nr FV', filter: 'text' },
		{
			id: 'action',
			label: 'Akcje',
			align: 'center',
			disableSort: true,
			disableExport: true
		}
	]);
	const [loadingInfo, setLoadingInfo] = useState(true);

	const handleEdit = id => {
		history.push(PATHS.DocumentWZEdit(id));
	};

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			DocumentWZAPI.delete(deleteId).then(response => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handleImport = rows => {
		setLoading(true);
		DocumentWZAPI.import(rows).then(response => {
			if (response?.message) {
				addToast(response.message, { appearance: response?.code === 200 ? 'success' : 'error' });
			}
			if (response.code === 200) {
				getData();
			}
			setLoading(false);
		});
	};

	const handleExport = () => {
		setLoading(true);
		DocumentWZAPI.export(options).then(response => {
			if (response.code === 200) {
				let data = [];
				response.data.forEach(row => {
					const items = row.items;
					delete row.items; // avoid reference related problems
					let values = [];
					if (items.length) {
						items.forEach((a, index) => {
							let item = clone(row);
							a['index'] = index + 1;
							item['item'] = a;
							values.push(item);
						});
					} else {
						values.push(row);
					}

					data.push(...values);
				});
				exportToXlsx(document_wz_header, data, 'Wydania zewnętrzne');
			} else {
				addToast(response?.message || 'Błąd serwera', { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	const getData = () => {
		setLoading(true);
		DocumentWZAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	const handlePreview = index => {
		let rows = JSON.parse(JSON.stringify(data));
		rows[index].preview = !rows[index].preview;
		setData(rows);
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	useEffect(() => {
		axios.all([
			ContractorAPI.getRecipients(),
			OrdersAPI.getAll(),
		]).then(axios.spread((recipients, orders) => {
			setHeader([
				...header.slice(0, 1),
				{ ...header[1], list: recipients.data?.contractors_list },
				...header.slice(2, 3),
				{ ...header[3], list: orders.data },
				...header.slice(4)
			]);
			setLoadingInfo(false);
		}));
	}, []);

	return (
		<>
			<TopButtonsBox
				add_button={{
					path: PATHS.DocumentWZCreate,
					label: 'Dodaj dokument'
				}}
				importHandler={handleImport}
				exportHandler={handleExport}
				header_list={document_wz_header}
			/>

			<Breadcrumbs list={['Dokumenty', 'Wydania']} />

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading || loadingInfo}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{row.document_id}</Cell>
								<Cell>{row.recipient?.name}</Cell>
								<Cell>{moment(row.shipment_date).format('DD/MM/YYYY')}</Cell>
								<Cell>{row.order?.order_number}</Cell>
								<Cell>{row.transport_type}</Cell>
								<Cell>{row.purpose}</Cell>
								<Cell>{moment(row.issue_date).format('DD/MM/YYYY')}</Cell>
								<Cell>
									{ships.find(i => i.id === row.cost_free_shipping)?.name}
								</Cell>
								<Cell>{row.invoice_id}</Cell>
								<Cell align="center" width={180}>
									<PreviewButton
										active={!!row.preview}
										onClick={() => handlePreview(index)}
									/>
									<EditButton onClick={() => handleEdit(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>
							{row.preview && (
								<Row>
									<Cell colSpan={10}>
										<PreviewTable assortments={row.assortments} />
									</Cell>
								</Row>
							)}
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default DocumentWZList;
