import React from 'react';
import { FormInput, SingleDetail } from 'components';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import { getRole } from 'apis/AuthAPI';

export const getMissingFields = (data, all = true) => {
	let requiredFields = {
		first_name: 'Imię',
		last_name: 'Nazwisko',
	};
	if (all) {
		requiredFields = {
			...requiredFields,
			street: 'Ulica',
			building_number: 'Nr budynku',
			// apartment_number: 'Nr lokalu',
			post_office: 'Poczta',
			postal_code: 'Kod pocztowy',
			city: 'Miejscowość',
			country: 'Państwo',
		}
	}
	const missing = Object.entries(requiredFields).filter(([key]) => !data[key]?.length);
	if (missing.length) {
		return missing.map(v => v[1]);
	}
	return false;
};

const ProfileDetails = props => {
	const classes = useStyles();
	const { data = { country: 'Polska' }, handleChange = () => {}, fullWidth = false, hideUsernames = false, forAdmin = false } = props;
	const role = getRole();
	const required = !forAdmin && role !== 'superadmin';

	return <div style={{ marginBottom: '4rem' }}>
		<SingleDetail hideButton fullWidth={fullWidth}>
			<>
				<Grid container spacing={2}>
					<Grid item xs={fullWidth ? 12 : 3}>
						<Typography variant="h5">Dane rozliczeniowe</Typography>
					</Grid>
					<Grid item xs={fullWidth ? 12 : 9}>
						<Grid container spacing={2}>
							{hideUsernames ? '' : (
								<>
									<Grid item xs={6}>
										<FormInput
											required={required}
											title="Imię"
											name="first_name"
											type="input"
											value={data.first_name}
											handleChange={handleChange}
										/>
									</Grid>
									<Grid item xs={6}>
										<FormInput
											required={required}
											title="Nazwisko"
											name="last_name"
											type="input"
											value={data.last_name}
											handleChange={handleChange}
										/>
									</Grid>
								</>
							)}

							<Grid item xs={12}>
								<FormInput
									title="Nazwa firmy"
									name="company_name"
									type="input"
									value={data.company_name}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="NIP"
									name="nip"
									type="input"
									value={data.nip}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="NIP UE"
									name="nip_ue"
									type="input"
									value={data.nip_ue}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="REGON"
									name="regon"
									type="input"
									value={data.regon}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="KRS"
									name="krs"
									type="input"
									value={data.krs}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="Nazwa oddziału"
									name="department"
									type="input"
									value={data.department}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Stanowisko"
									name="position"
									type="input"
									value={data.position}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={2} className={classes.lineTop}>
					<Grid item xs={fullWidth ? 12 : 3}>
						<Typography variant="h5">Adres</Typography>
					</Grid>
					<Grid item xs={fullWidth ? 12 : 9}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									required={required}
									title="Ulica"
									name="street"
									type="input"
									value={data.street}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormInput
									required={required}
									title="Nr budynku"
									name="building_number"
									type="input"
									value={data.building_number}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={3}>
								<FormInput
									title="Nr lokalu"
									name="apartment_number"
									type="input"
									value={data.apartment_number}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									required={required}
									title="Miejscowość"
									name="city"
									type="input"
									value={data.city}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={3}>
								<FormInput
									required={required}
									title="Kod pocztowy"
									name="postal_code"
									type="input"
									value={data.postal_code}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									required={required}
									title="Poczta"
									name="post_office"
									type="input"
									value={data.post_office}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									required={required}
									title="Państwo"
									name="country"
									type="input"
									value={data.country}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={2} className={classes.lineTop}>
					<Grid item xs={fullWidth ? 12 : 3} style={fullWidth ? {display: 'flex', justifyContent: 'space-between'} : {}}>
						<Typography variant="h5">Adres korespondencyjny</Typography>
						<Typography className={classes.hint}>Jeśli inny niż adres podstawowy</Typography>
					</Grid>
					<Grid item xs={fullWidth ? 12 : 9}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Ulica"
									name="contact_street"
									type="input"
									value={data.contact_street}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={3}>
								<FormInput
									title="Nr budynku"
									name="contact_building_number"
									type="input"
									value={data.contact_building_number}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormInput
									title="Nr lokalu"
									name="contact_apartment_number"
									type="input"
									value={data.contact_apartment_number}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="Miejscowość"
									name="contact_city"
									type="input"
									value={data.contact_city}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormInput
									title="Kod pocztowy"
									name="contact_postal_code"
									type="input"
									value={data.contact_postal_code}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="Poczta"
									name="contact_post_office"
									type="input"
									value={data.contact_post_office}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Państwo"
									name="contact_country"
									type="input"
									value={data.contact_country}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={2} className={classes.lineTop}>
					<Grid item xs={fullWidth ? 12 : 3}>
					<Typography variant="h5">Inne</Typography>
				</Grid>
					<Grid item xs={fullWidth ? 12 : 9}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="PESEL"
									name="pesel"
									type="input"
									value={data.pesel}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Numer telefonu"
									name="phone_number"
									type="input"
									value={data.phone_number}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Numer faksu"
									name="fax_number"
									type="input"
									value={data.fax_number}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Strona www"
									name="website"
									type="input"
									value={data.website}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<FormInput
											title="Adres e-mail"
											name="company_email"
											type="input"
											value={data.company_email}
											handleChange={handleChange}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={4}>
								<FormInput
									title="Bank"
									name="bank_name"
									type="input"
									value={data.bank_name}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={8}>
								<FormInput
									title="Numer podstawowego rachunku bankowego"
									name="bank_account_number"
									type="input"
									value={data.bank_account_number}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		</SingleDetail>
	</div>
};

export default ProfileDetails;
