import React from 'react';
import { Link } from 'react-router-dom';
import { isObject } from 'validate.js';
import './breadcrumbs.scss';
import { Typography } from '@material-ui/core';

export default function Breadcrumbs(props) {
	const { list, extra_classes, back_url, extraText } = props;

	return (
		<div className={`breadcrumbs ${extra_classes || ''}`}>
			<div className='breadcrumbs__items'>
				{list && list.map((item, index) => (
					<span key={index} title={item?.label || item}>
						{isObject(item) && item?.path && item?.path
							? <Link to={item.path}>{item.label}</Link>
							: item
						}
					</span>
				))}
			</div>

			{extraText && <Typography className='breadcrumbs_text'>{extraText}</Typography>}

			{back_url &&
				<Link to={back_url} className='breadcrumbs__back'>
					&#8249; Powrót
				</Link>
			}
		</div>
	);
};
