/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, Loader, SingleDetail } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import MeasurementUnitAPI from 'apis/MeasurementUnitAPI';
import PATHS from 'routes/paths';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';

const MeasurementUnit = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [listInfo, setListInfo] = useState({});

	useEffect(() => {
		axios.all([
			id ? MeasurementUnitAPI.get(id) : null,
			MeasurementUnitAPI.getInfo(),
		]).then(axios.spread((item, info) => {
			item?.data && setData(item.data);
			info?.data && setListInfo(info.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		if (!data.type) {
			addToast('Nie wybrano jednostki.', { appearance: 'error' });
			return;
		}
		if (!data.name?.length) {
			addToast('Nie podano nazwy.', { appearance: 'error' });
			return;
		}
		setLoading(true);
		MeasurementUnitAPI.updateOrCreate(data, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.MeasurementUnitList), 100);
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		MeasurementUnitAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.MeasurementUnitList), 100);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Dane podstawowe', { label: 'Jednostki miary', path: PATHS.MeasurementUnitList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.MeasurementUnitList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane dotyczące jednostki"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Jednostka"
							name="type"
							type="single"
							value={data.type}
							list={listInfo.typeList}
							handleChange={handleChange}
						/>
						<FormInput
							title="Nazwa jednostki"
							name="name"
							type="input"
							value={data.name}
							handleChange={handleChange}
						/>
						<FormInput
							title="Opis"
							name="description"
							type="area"
							value={data.description}
							handleChange={handleChange}
						/>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default MeasurementUnit;
