/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, SingleDetail } from 'components';
import DatePicker from 'components/FormInput/MyDatePicker';
import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import WarehouseOperationAPI from 'apis/WarehouseOperationAPI';
import main from 'utils/main';
import PATHS from 'routes/paths';
import Loader from 'components/Loader';
import { commaToDot, dotToComma } from 'utils/functions';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

const WarehouseOperation = () => {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [listInfo, setListInfo] = useState({});

	useEffect(() => {
		axios.all([
			id ? WarehouseOperationAPI.get(id) : null,
			WarehouseOperationAPI.getInfo(),
		]).then(axios.spread((item, info) => {
			info?.data && setListInfo(info.data);
			if (item?.data) {
				let assortment = listInfo.assortment?.find(() => item.data?.assortment);
				setData({
					...item.data,
					purchase_price: assortment?.purchase_price,
					sale_price: assortment?.sale_price,
					received_value: 0,
					release_value: 0,
					stock_pln: 0,
					order_pln: 0
				});
			}
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		switch (name){
			case 'purchase_price':
			case 'sale_price':
			case 'stock_value':
			case 'order':
			case 'handling_delivery_cost':
			case 'release':
			case 'release_value':
			case 'release_number':
			case 'received':
			case 'received_value':
			case 'received_number':
				value = commaToDot(value);
				break;
		}
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		WarehouseOperationAPI.updateOrCreate(data, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.WarehouseOperationList), 100);
			}
		});
	};

	const handleDelete = () => {
		setLoading(true);
		WarehouseOperationAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.WarehouseOperationList), 100);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		let purchase_price = main.getAttrFromArray(
			listInfo.assortment || [],
			data.assortment,
			'purchase_price',
			''
		);
		let sale_price = main.getAttrFromArray(
			listInfo.assortment || [],
			data.assortment,
			'sale_price',
			''
		);
		let calculated_received_value = main.round(
			main.convertStrToNum(purchase_price) *
				main.convertStrToNum(data.received_number),
			2
		);
		let calculated_releases_value = main.round(
			main.convertStrToNum(sale_price) * main.convertStrToNum(data.release_number),
			2
		);
		let stock_value = main.round(
			main.convertStrToNum(purchase_price) * main.convertStrToNum(data.stock),
			2
		);
		let order_value = main.round(
			main.convertStrToNum(sale_price) * main.convertStrToNum(data.order),
			2
		);
		setData({
			...data,
			purchase_price: purchase_price,
			sale_price: sale_price,
			received_value: main.convertNumToStr(calculated_received_value),
			release_value: main.convertNumToStr(calculated_releases_value),
			stock_pln: main.convertNumToStr(stock_value),
			order_pln: main.convertNumToStr(order_value)
		});
	}, [
		data.assortment,
		data.received,
		data.release,
		data.received_number,
		data.release_number,
		data.stock,
		data.order
	]);

	return (
		<>
			<Breadcrumbs
				list={['Dane podstawowe', {
					label: 'Operacje magazynowe', path: PATHS.WarehouseOperationList,
				}, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.WarehouseOperationList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane dotyczące operacje"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<React.Fragment>
						<FormInput
							title="Asortyment"
							name="assortment"
							type="single"
							value={data.assortment}
							list={listInfo.assortment}
							handleChange={handleChange}
						/>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Cena zakupu"
									name="purchase_price"
									type="input"
									value={dotToComma(data.purchase_price)}
									handleChange={handleChange}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Cena sprzedaży"
									name="sale_price"
									type="input"
									value={dotToComma(data.sale_price)}
									handleChange={handleChange}
									disabled={true}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography style={{ marginTop: 17 }}>Data</Typography>
								{data.date ? <DatePicker
									value={"2008-12-31"}
									onChange={v => setData({ ...data, date: v })}
								/> : <Skeleton variant="rect" width={412} height={37} />}
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Magazyn"
									name="warehouse"
									type="single"
									value={data.warehouse_id}
									list={listInfo.warehouse}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Jednostka miary"
									name="measure_unit"
									type="single"
									value={data.measure_unit}
									list={listInfo.measure_unit}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Jednostka logistyczna"
									name="logistic_unit"
									type="single"
									value={data.logistic_unit}
									list={listInfo.logistic_unit}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Wielkość przyjęć [j. m.] [jedn. log.]"
									name="received_number"
									type="number"
									value={dotToComma(data.received_number)}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Wielkość wydań [j. m.] [jedn. log.]"
									name="release_number"
									type="number"
									value={dotToComma(data.release_number)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Liczba przyjęć [-]"
									name="received"
									type="number"
									value={dotToComma(data.received)}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Liczba wydań [-]"
									name="release"
									type="number"
									value={dotToComma(data.release)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Wartość przyjęć [PLN]"
									name="received_value"
									type="input"
									value={dotToComma(data.received_value)}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Wartość wydań [PLN]"
									name="release_value"
									type="input"
									value={dotToComma(data.release_value)}
									handleChange={handleChange}
									disabled={true}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Koszt obslugi i dostawy"
									name="handling_delivery_cost"
									type="number"
									value={dotToComma(data.handling_delivery_cost)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Zapas [j. m.] [jedn. log.]"
									name="stock_value"
									type="number"
									value={dotToComma(data.stock_value)}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Wielkość zamówienia [j. m.] [jedn. log.]"
									name="order"
									type="number"
									value={dotToComma(data.order)}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Wartość zapasu [PLN]"
									name="stock"
									type="input"
									value={data.stock}
									handleChange={handleChange}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Wartość zamówienia [PLN] "
									name="order_pln"
									type="input"
									value={data.order_pln}
									handleChange={handleChange}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</React.Fragment>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default WarehouseOperation;
