/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Link, Typography, Modal } from '@material-ui/core';
import { FormInput, Loader } from 'components';
import useStyles from './style';
import AuthAPI from 'apis/AuthAPI';
import constants from 'utils/constants';
import useGlobalStyles from 'assets/style/styles';
import clsx from 'clsx';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {useToasts} from 'react-toast-notifications';
import PATHS from 'routes/paths';
import PasswordStrengthBar from 'react-password-strength-bar';
import ContactFooter from '../SignIn/ContactFooter';
import ProfileDetails, { getMissingFields } from '../Profile/ProfileDetails';
import { getResponseMessage } from 'utils/functions';
import { validatePassword } from 'helpers/form';

const SignUp = () => {
	const classes = useStyles();
	const global_classes = useGlobalStyles();
	const [input, setInput] = useState({ agreement: true });
	const [profile, setProfile] = useState({ country: 'Polska' });
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const [tryLogin, setTryLogin] = useState(false);
	const {addToast} = useToasts();
	const [open, setOpen] = useState(false);
	const [modalStyle] = React.useState(getModalStyle);

	function getModalStyle() {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}

	// const handleClose = () => {
	// 	setOpen(false);
	// };

	useEffect(() => {
		let arr = JSON.parse(JSON.stringify(error));
		const emailPattern = new RegExp(/^[-!#$%&'*+/\d=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/\d=?A-Z^_a-z`{|}~])*@[a-zA-Z\d](-*\.?[a-zA-Z\d])*\.[a-zA-Z](-?[a-zA-Z\d])+$/i);
		if (input['email'] && !emailPattern.test(input['email'])) {
			arr['email'] = constants.ENTER_VALID_EMAIL;
		} else {
			arr['email'] = '';
		}
		arr['password'] = validatePassword(input['password']);
		if (input['reset_password'] && input['reset_password'] !== input['password']) {
			arr['reset_password'] = constants.ENTER_SAME_PASSWORD;
		} else {
			arr['reset_password'] = '';
		}

		setError(arr);
	}, [input]);

	const handleChange = event => {
		let arr = JSON.parse(JSON.stringify(input));
		arr[event.target.name] = event.target.value;
		setInput(arr);
	};

	const handleProfileChange = (name, value) => {
		setProfile({ ...profile, [name]: value });
	};

	const handleSignUp = () => {
		setTryLogin(true);
		if ((error && ((error.email && error.email.length > 0) || (error.password && error.password.length > 0) || (error.reset_password && error.reset_password.length > 0)))
			|| !input.email || !input.password || !input.reset_password) {
			addToast(constants.CHECK_ALL_FIELDS, { appearance: 'warning', autoDismissTimeout: 10000, autoDismiss: true });
			return;
		}
		setTryLogin(false);

		const missing = getMissingFields(profile);
		if (missing) {
			missing.forEach(field => {
				addToast(`Pole "${field}" jest wymagane.`, {
					appearance: 'warning',
					autoDismissTimeout: 10000,
					autoDismiss: true,
				});
			});
			return;
		}

		if (!input.agreement) {
			addToast('Akceptacja regulaminu jest wymagana.', {
				appearance: 'warning',
				autoDismissTimeout: 10000,
				autoDismiss: true,
			});
			return;
		}

		setLoading(true);
		AuthAPI.register(input.email, input.password, profile)
			.then(response => {
				let appearance = 'error';
				if (response.code === 200) {
					appearance = 'success';
					setOpen(true);
					setLoading(false);
				}
				addToast(getResponseMessage(response), {
					appearance,
					autoDismissTimeout: 10000,
					autoDismiss: true,
				});
				setLoading(false);
			});
	};

	const handleKeyPress = (event) => {
		if (event.charCode === 13) {
			handleSignUp();
		}
	};

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<h2 id="simple-modal-title">Dziękujemy za rejestracje.</h2>
			<br/>
			<br/>
			<p id="simple-modal-description">
				W celu weryfikacji konta otrzymasz potwierdzenie na podany adres e-mail.
			</p>
			<Link to={PATHS.Login} component={RouterLink} className={classes.btnGoToLogin}>Przejdź do strony logowania</Link>
		</div>
	);

	return (
		<Loader loading={loading}>
			<div className={classes.root}>
				<div className={classes.loginForm}>
					<Typography variant={'h2'} className={clsx(classes.description, global_classes.normal_font)}>
						Podaj swój adres e-mail oraz dwukrotnie wprowadź swoje hasło.
					</Typography>
					<div className={classes.form}>
						<div className={classes.input_box_label}><label htmlFor='email'>E-mail</label></div>
						<input className={classes.input_box} type='email' value={input.email} name='email' placeholder='E-mail'
									 onChange={handleChange} onKeyPress={handleKeyPress}/>
						<div
							className={classes.error_log}>{tryLogin && error['email'] && error['email'].length > 0 && error.email}</div>
						<div className={classes.input_box_label}><label htmlFor='password'>Hasło</label></div>
						<input className={classes.input_box} type='password' value={input.password} name='password'
									 placeholder='Hasło' onChange={handleChange} onKeyPress={handleKeyPress}/>
						<PasswordStrengthBar
							password={input.password}
							shortScoreWord={'hasło jest zbyt słabe'}
							scoreWords={[
								'bardzo słabe',
								'słabe',
								'średnie',
								'silne',
								'bardzo silne'
							]}/>
						<div
							className={classes.error_log}>{tryLogin && error['password'] && error['password'].length > 0 && error.password}</div>
						<div className={classes.input_box_label}><label htmlFor='password'>Powtórz hasło</label></div>
						<input className={classes.input_box} type='password' value={input.reset_password} name='reset_password'
									 placeholder='Powtórz hasło' onChange={handleChange} onKeyPress={handleKeyPress}/>
						<div
							className={classes.error_log}>{tryLogin && error['reset_password'] && error['reset_password'].length > 0 && error.reset_password}</div>

					</div>

					<ProfileDetails fullWidth data={profile} handleChange={handleProfileChange} />

					<div>
						<FormInput
							type="check_box"
							name="agreement"
							value={input.agreement}
							handleChange={(name, value) => setInput({ ...input, [name]: value })}
							title={<>
								Oświadczam, że zapoznałem/am się z
								<Link
									className={classes.inlineLink}
									href="/regulamin-aplikacji.pdf">
									Regulaminem Aplikacji
								</Link>
								i akceptuję wszystkie zawarte w nim warunki.
							</>}
						/>
					</div>

					<div className={classes.form}>
						<div className={classes.buttonContainer}>
							<Button variant='outlined' className={clsx(classes.btnLogin, global_classes.outline_button)}
											onClick={handleSignUp}>
								Zarejestruj się
							</Button>
							<Link to={PATHS.Login} component={RouterLink} className={classes.btnRegister}>Mam konto - zaloguj
								się<ArrowRightAltIcon className={classes.arrow}/></Link>
						</div>
					</div>

					<ContactFooter />
				</div>
			</div>
			<Modal
				open={open}
				onClose={() => {}}
				disableEscapeKeyDown={true}
			>
				{body}
			</Modal>
		</Loader>
	);
};

SignUp.propTypes = {
	history: PropTypes.object
};

export default withRouter(SignUp);
