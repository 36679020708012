import API from './API';
import { getParamsByOptions } from '../utils/functions';

const WarehouseGroupAPI = {
	getInfo: () => API.get('/info', { params: { 'type': 'warehouses_groups' } }),
	get: id => API.get(`/warehouses_groups/${id}`),
	index: options => API.get('/warehouses_groups' + getParamsByOptions(options)),
	export: options => API.get('/warehouses_groups/export' + getParamsByOptions(options)),
	updateOrCreate: (data, id) => id ? API.put(`/warehouses_groups/${id}`, data) : API.post('/warehouses_groups', data),
	delete: id => API.delete(`/warehouses_groups/${id}`),
	create_list: data => API.post('/warehouses_groups/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/warehouses_groups/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default WarehouseGroupAPI;
