import React from 'react';
import { Breadcrumbs } from 'components';
import { ProfileForm } from 'views/Profile';

const Profile = () => {
	return <>
		<Breadcrumbs list={['Profil', 'Edytuj']}/>
		<ProfileForm profileEdit />
	</>
};

export default Profile;
