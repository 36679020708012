/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import constants from 'utils/constants';

import PATHS from 'routes/paths';
import OrdersAPI from 'apis/OrderAPI';
import UserAPI from 'apis/UserAPI';
import ContractorAPI from 'apis/ContractorAPI';
import InvoicesAPI from 'apis/InvoiceAPI';
import GeneralAPI from 'apis/GeneralAPI';
import { getPriceZlAndGr, getResponseMessage } from 'utils/functions';
import { Breadcrumbs, Loader } from 'components';
import Document from 'components/document/Document';
import DocumentContractors from 'components/document/DocumentContractors';
import FormInput from 'components/form/FormInput';

import moment from 'moment';
import InvoiceCreateTable from './InvoiceCreateTable';
import DatePicker from 'components/FormInput/MyDatePicker';
import Select from 'react-select';
import axios from "axios";

const today = moment(new Date()).format('YYYY-MM-DD');

export default function Invoice() {
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);

	const [data, setData] = useState({
		invoice_number: '',
		issue_date: today,
		sale_date: today,
		payment_due: today,
		payment_type: '',
		annotations: ''
	});
	const [assortments, setAssortments] = useState([]);
	const [contractors, setContractors] = useState([]);
	const [myCompany, setMyCompany] = useState({});
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState();
	const [assortmentsOptions, setAssortmentsOptions] = useState([]);
	const [taxesOptions, setTaxesOptions] = useState({});
	const [priceGrossText, setPriceGrossText] = useState(null);

	const assortmentsTotals = useMemo(() => {
		const getWithTax = (tax, value_nett) => {
			return getPriceZlAndGr(String((+value_nett * 100 * tax) / 100));
		};
		const filledAssortments = assortments.filter(
			item => +item.price_gross >= 0 && +item.value_nett >= 0
		);
		if (filledAssortments && !!filledAssortments.length) {
			let with_tax = Object.values(taxesOptions).reduce((arr, item) => (arr[item.tax_id] = 0, arr), {});
			const total = filledAssortments.reduce(
				(result, current) => {
					with_tax[current.tax_id] += Math.round(parseFloat(current.price_gross || 0) * 100) / 100;

					result.price_gross = String(
						(result.price_gross * 100 + current.price_gross * 100) / 100
					);
					result.value_nett = String(
						(result.value_nett * 100 + current.value_nett * 100) / 100
					);
					result.tax_value = String(
						(result.tax_value * 100 + current.tax_value * 100) / 100
					);

					return result;
				},
				{ price_gross: '0', value_nett: '0', tax_value: '0' }
			);

			const [price_gross_zl, price_gross_gr] = getPriceZlAndGr(
				total.price_gross
			);
			const [value_nett_zl, value_nett_gr] = getPriceZlAndGr(total.value_nett);
			const [tax_value_zl, tax_value_gr] = getPriceZlAndGr(total.tax_value);

			Object.entries(with_tax).forEach(([id, v]) => {
				with_tax[id] = getWithTax(1, v);
			});

			return {
				...total,
				value_nett_zl,
				value_nett_gr,
				price_gross_zl,
				price_gross_gr,
				tax_value_zl,
				tax_value_gr,
				with_tax,
			};
		}
	}, [assortments, data.assortments, taxesOptions]);

	const sellerData = [
		{ label: 'Firma', value: myCompany?.company_name || '' },
		{
			label: 'Adres',
			value: `${myCompany?.street || ''} ${myCompany?.building_number ||
				''}, ${myCompany?.postal_code || ''} ${myCompany?.city || ''}`
		},
		{ label: 'NIP', value: myCompany.nip || '' }
	];

	const buyerData = [
		{
			label: 'Adres',
			value: data.buyerInfo
				? `${data.buyerInfo?.street} ${data.buyerInfo?.building_number}/${data.buyerInfo?.apartment_number}, ${data.buyerInfo?.postal_code} ${data.buyerInfo?.city}`
				: ''
		},
		{ label: 'Imię i nazwisko', value: 'Jan Kowalski' },
		{ label: 'NIP', value: data.buyerInfo?.nip }
	];

	const paymentInfoData = [
		{ label: 'w banku', value: myCompany.bank_name || '---' },
		{ label: 'numer konta', value: myCompany.bank_account_number || '---' },
		{ label: 'Do zapłaty', value: assortmentsTotals?.price_gross !== undefined ? parseFloat(assortmentsTotals.price_gross).toFixed(2) : '---' },
		{ label: 'Słownie', value: priceGrossText || '---' }
	];

	useEffect(() => {
		axios.all([
			id ? InvoicesAPI.get(id) : InvoicesAPI.getInfo(),
			UserAPI.get(),
			ContractorAPI.getRecipients(),
			OrdersAPI.getAll(),
			GeneralAPI.getTaxes(),
		]).then(axios.spread((
			item,
			company,
			contractors,
			orders,
			taxes,
		) => {
			if (item?.data) {
				if (id) {
					setData(item.data);
					setAssortments(item.data?.assortments);
				} else {
					setData({ ...data, invoice_number: item.data.invoice?.formated })
				}
			}
			company?.data && setMyCompany(company.data);
			contractors?.data && setContractors(contractors.data.contractors_list);
			orders?.data && setOrders(orders.data);
			taxes?.data && setTaxesOptions(
				taxes.data?.reduce((arr, item) => (arr[item.id] = {
					value: item.id,
					value_double: item.value_double,
					value_int: item.value_int,
					tax_id: item.id,
					label: item.name.replace('VAT', '').trim()
				}, arr), {})
			);

			setLoading(false);
		}));
	}, []);

	const changeData = (name, value) => setData({ ...data, [name]: value });

	const validate = entity => {
		if (!entity.buyer) {
			addToast('Nie wybrano nabywcy.', { appearance: 'error' });
			return false;
		}
		if (!entity.order_id) {
			addToast('Nie wybrano zamówienia.', { appearance: 'error' });
			return false;
		}
		if (!entity.assortments?.length) {
			addToast('Nie wybrano asortymentu.', { appearance: 'error' });
			return false;
		}

		for (let ass of entity.assortments) {
			if (!ass.assortment_id) {
				addToast('Brakuje asortymentu.', { appearance: 'error' });
				return false;
			}
			if (parseInt(taxesOptions[ass.tax_id] ? taxesOptions[ass.tax_id].value_int : 0) !== constants.VAT && !ass.symbol) {
				addToast('Symbol jest wymagany dla obniżonych stawek VAT.', { appearance: 'error' });
				return false;
			}
			if (!ass.quantity) {
				addToast('Nie podano ilości.', { appearance: 'error' });
				return false;
			}
			// if (!('' + ass.tax_rate).length) {
			// 	addToast('Nie podano podatku.', { appearance: 'error' });
			// 	return false;
			// }
		}
		// if (!entity.annotations) {
		// 	addToast('Nie podano adnotacji.', { appearance: 'error' });
		// 	return false;
		// }

		return true;
	};

	useEffect(() => {
		if (selectedOrder) {
			setAssortmentsOptions(selectedOrder.assortments.map(i => i.assortment));
		}
	}, [selectedOrder]);

	useEffect(() => {
		if (orders.length && data.order_id) {
			setSelectedOrder(orders.find(i => +i.id === data.order_id));
		}
	}, [data.order_id, orders]);

	useEffect(() => {
		if (contractors && data) {
			setData({
				...data,
				buyerInfo: contractors.find(i => i.id === data.buyer)
			});
		}
	}, [contractors, data.buyer]);

	useEffect(() => {
		if (contractors && data) {
			setData({
				...data,
				buyerInfo: contractors.find(i => i.id === data.buyer)
			});
		}
	}, [contractors, data.buyer]);

	useEffect(() => {
		if (assortmentsTotals?.price_gross) {
			GeneralAPI.getNumberAsText(assortmentsTotals?.price_gross).then(res => {
				res.code === 200 && setPriceGrossText(res.data.number_text);
			});
		}
	}, [assortmentsTotals?.price_gross]);

	const handleSave = () => {
		let entity = {
			...data,
			buyer: data.buyerInfo?.id,
			total_value: assortmentsTotals?.value_nett,
			total_value_text: priceGrossText,
			gross: Math.round(+assortmentsTotals?.price_gross * 100) / 100,
			order_id: selectedOrder?.id,
			assortments: assortments && !!assortments.length ? assortments.map(item => ({
				id: item?.id, ...item
			})) : null
		};

		if (!validate(entity)) {
			return;
		}

		setLoading(true);
		InvoicesAPI.updateOrCreate(entity, id).then(response => {
			let message = getResponseMessage(response);
			if ([200, 201].includes(response.code)) {
				addToast(response.message, { appearance: 'success' });
				setTimeout(() => history.push(PATHS.InvoiceList), 100);
			} else {
				addToast(message, { appearance: 'error' });
			}
			setLoading(false);
		});
	};

	return (
		<>
			<Breadcrumbs
				list={['Dokumenty', { label: 'Faktury', path: PATHS.InvoiceList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.InvoiceList}
			/>

			<Loader loading={loading}>
				<Document onSaveAndGenerate={handleSave}>
				<div className="flex">
					<div style={{ flex: '0 0 564px' }} className="box border">
						<div className="df-ac" style={{ marginBottom: '15px' }}>
							<div style={{ width: 150 }}> Sprzedawca:</div>
						</div>

						{sellerData.map(({ label, value }, i) => (
							<div key={i} className="df-ac" style={{ marginBottom: '15px' }}>
								<div style={{ width: 150 }}>{label}</div>
								<div className="gray-color" style={{ width: '100%' }}>
									{value}
								</div>
							</div>
						))}
					</div>

					<div style={{ flex: '0 0 560px' }} className="box steal-bg bt br bb">
						<div
							style={{
								textAlign: 'center',
								fontSize: '2.5em',
								margin: '25px 0'
							}}>
							{' '}
							FAKTURA
						</div>

						<div className="df-ac">
							<span style={{ marginRight: '8px' }}> Numer </span>
							<FormInput
								disabled
								name="invoice_number"
								value={data.invoice_number}
							/>
						</div>
					</div>

					<div style={{ flex: 'auto' }} className="box br bt bb">
						<div className="df-ac" style={{ marginBottom: '15px' }}>
							<div style={{ minWidth: '175px', maxWidth: '175px' }}>
								{' '}
								Miejscowość:
							</div>
							<div className="gray-color"> {myCompany.city} </div>
						</div>

						<div className="df-ac" style={{ marginBottom: '15px' }}>
							<div style={{ minWidth: '175px', maxWidth: '175px' }}>
								{' '}
								Data wystawienia
							</div>
							<DatePicker
								value={data.issue_date}
								onChange={v => setData({ ...data, issue_date: v })}
							/>
						</div>

						<div className="df-ac" style={{ marginBottom: '15px' }}>
							<div style={{ minWidth: '175px', maxWidth: '175px' }}>
								{' '}
								Dzień, miesiąc, rok dokonania sprzedaży
							</div>
							<DatePicker
								value={data.sale_date}
								onChange={v => setData({ ...data, sale_date: v })}
							/>
						</div>
					</div>
				</div>

				<div className="box bl br bb" style={{ paddingTop: '7px' }}>
					<div className="row">
						<div
							className="df-ac"
							style={{
								flex: '0 0 50%',
								marginBottom: '15px',
								paddingRight: '10px'
							}}>
							<div style={{ minWidth: '147px' }}> Nabywca</div>

							<DocumentContractors
								box_width={400}
								name="buyer"
								id={data.buyer}
								contractors={contractors}
								onChange={c => setData({ ...data, buyer: c.id, buyerInfo: c })}
							/>
						</div>

						{buyerData.map(({ label, value }, i) => (
							<div
								key={i}
								className="df-ac"
								style={{
									flex: '0 0 50%',
									marginBottom: '15px',
									paddingRight: '10px'
								}}>
								<div style={{ minWidth: '147px' }}> {label} </div>
								<div className="gray-color"> {value} </div>
							</div>
						))}
					</div>
				</div>

				{selectedOrder && Object.values(taxesOptions).length && (
					<InvoiceCreateTable
						assortments={data.assortments}
						assortments_options={assortmentsOptions}
						taxesOptions={Object.values(taxesOptions)}
						onRowsChange={rows => setAssortments(rows)}
					/>
				)}

				<div className="flex" style={{ marginTop: 20 }}>
					<div style={{ flex: '0 0 672px' }}>
						<div className="flex">
							<div className="box border df-ac" style={{ flex: '1' }}>
								<div style={{ minWidth: 125, paddingTop: 8 }}>
									Sposób zapłaty
								</div>
								<FormInput
									name="payment_type"
									value={data.payment_type}
									onChange={changeData}
								/>
							</div>

							<div className="box df-ac bt br bb" style={{ flex: '1' }}>
								<div style={{ minWidth: 125, paddingTop: 8 }}>
									Termin zapłaty
								</div>
								<DatePicker
									value={data.payment_due}
									onChange={v => setData({ ...data, payment_due: v })}
								/>
							</div>
						</div>

						{paymentInfoData.map(({ label, value }, i) => (
							<div
								key={i}
								className="box df-ac bl br bb"
								style={{ minHeight: '68px' }}>
								<div style={{ minWidth: 150 }}> {label} </div>
								<div className="gray-color" style={{ width: '100%' }}>
									{value}
								</div>
							</div>
						))}

						<div className="box df-ac bl br bb" style={{ minHeight: '68px' }}>
							<div style={{ minWidth: 150 }}> Zamówienie</div>
							<div style={{ width: 300 }}>
								<Select
									placeholder="Wybierz"
									noOptionsMessage={() => 'Brak opcji'}
									options={orders}
									getOptionLabel={option => option.order_number}
									getOptionValue={option => option.id}
									value={selectedOrder}
									isClearable={false}
									menuPlacement="auto"
									onChange={v => {
										setSelectedOrder(v);
										if (data.order_id !== v.id) {
											setData({ ...data, assortments: [] });
										}
									}}
								/>
							</div>
							{/* <FormSelect
								name="order_id"
								value={data?.order_id}
								options={orders_options}
								onChange={changeData}
							/> */}
						</div>

						<div className="box df-ac bl br bb" style={{ minHeight: '68px' }}>
							<div style={{ minWidth: 150 }}> Adnotacje</div>
							<FormInput
								type="text"
								name="annotations"
								value={data.annotations}
								onChange={changeData}
							/>
						</div>
					</div>

					<div style={{ flex: 'auto' }}>
						<div className="flex bt bb br">
							<div
								className="box df-centered steal-bg br"
								style={{
									textAlign: 'center',
									flex: '0 0 259px',
									minHeight: '205px'
								}}>
								ZESTAWIENIE SPRZEDAŻY WG STAWEK PODATKÓW
							</div>

							<div style={{ flex: 'auto' }}>
								{Object.values(taxesOptions).map(option => (
									<div className="df-ac" key={option.tax_id}>
										{[102, 98, 80, 65, 65, 95, 100].map((size, i) => {
											let content;

											switch (i) {
												case 0:
													content = assortmentsTotals?.with_tax?.[option.tax_id]?.[0] || '0';
													break;
												case 1:
													content = assortmentsTotals?.with_tax?.[option.tax_id]?.[1] || '0';
													break;
												case 2:
													content = option.label;
													break;
												default:
													content = '';
													break;
											}

											return (
												<div
													key={i}
													className="df-centered gray-color bb br"
													style={{ height: '32px', flex: `0 0 ${size}px` }}>
													{content}
												</div>
											);
										})}
									</div>
								))}
							</div>
						</div>

						<div className="flex br bb">
							<div
								className="box df-centered steal-bg br"
								style={{
									textAlign: 'center',
									flex: '0 0 259px',
									minHeight: '205px'
								}}>
								RAZEM
							</div>

							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 102px` }}>
								{assortmentsTotals?.value_nett_zl || '0'}
							</div>
							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 98px` }}>
								{assortmentsTotals?.value_nett_gr || '0'}
							</div>
							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 80px` }}></div>
							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 65px` }}>
								{assortmentsTotals?.tax_value_zl || '0'}
							</div>
							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 65px` }}>
								{assortmentsTotals?.tax_value_gr || '0'}
							</div>
							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 95px` }}>
								{assortmentsTotals?.price_gross_zl || '0'}
							</div>
							<div
								className="box df-centered br gray-color"
								style={{ flex: `0 0 100px` }}>
								{assortmentsTotals?.price_gross_gr || '0'}
							</div>
						</div>

						<div
							className="flex box bb br"
							style={{ height: 42, paddingTop: 10 }}>
							Podpis wystawcy faktury : {myCompany.company_name}
						</div>
					</div>
				</div>
			</Document>
			</Loader>
		</>
	);
}
