import API from './API';
import { getParamsByOptions } from '../utils/functions';

const StockAnalyzeAPI = {
	index: () => API.get('/stock_analyzes'),
	index: options => API.get('/stock_analyzes' + getParamsByOptions(options)),
	get: id => API.get(`/stock_analyzes/${id}`),
	create: data => API.post('/stock_analyzes', data),
	delete: id => API.delete(`/stock_analyzes/${id}`)
};

export default StockAnalyzeAPI;
