import React from 'react';

import { DocumentTable } from 'components/document/DocumentTable';
import DocumentPZCreateTableRow from './DocumentPZCreateTableRow';

const COLUMN_SIZES = [50, 125, 370, 180, 74, 141, 68, 73, 62, 65, 162, 20, 146];

const TABLE_HEAD = [
	'Lp.',
	'Kod towaru',
	'Nazwa materiału, wyrobu, opakowania',
	{
		head: 'Ilość',
		items: [
			{ th: 'Dostarczona', index: 3 },
			{ th: 'j.m.', index: 4 },
			{ th: 'Przyjęta', index: 5 }
		]
	},
	{
		head: 'Cena',
		items: [
			{ th: 'zł', index: 6 },
			{ th: 'gr', index: 7 }
		]
	},
	{
		head: 'Wartość',
		items: [
			{ th: 'zł', index: 8 },
			{ th: 'gr', index: 9 }
		]
	},
	'Zapas Ilość',
	'',
	'Koszty obsługi I dostawy'
];

export default function DocumentPZCreateTable(props) {

	return (
		<DocumentTable
			{...props}
			head={TABLE_HEAD}
			column_sizes={COLUMN_SIZES}
			RowComponent={DocumentPZCreateTableRow}
		/>
	);
}
