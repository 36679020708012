import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const WelcomeExpired = () => {
	const classes = useStyles();
	return (
		<>
			<Grid container
						spacing={4}
						direction='row'
						justifyContent='center'>
				<Grid item xs={12} sm={10} md={8}>
					<Typography variant='h1' component='h2' className={classes.welcomeIntro}>
						Witaj w naszym systemie!
					</Typography>
					<Typography component='p' className={classes.welcomeText}>
						<strong>UWAGA!</strong> Wypełnij dane w zakładce "Moja Firma", aby poprawnie korzystać z aplikacji.
					</Typography>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
						<Card className={classes.root} variant='outlined'>
							<CardContent>
								<Grid container
											spacing={2}
											direction='row'
											justifyContent='center'>
									<Grid item xs={11}>
										<Typography className={classes.title} align="center">Abonament wygasł</Typography>
										<Typography className={classes.text} align="center">Skontaktuj się z opiekunem swojej grupy.</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default WelcomeExpired;
