import API from './API';
import { getParamsByOptions } from '../utils/functions';

const ContractorAPI = {
	getInfo: () => API.get('/info', { params: { 'type': 'contractors' } }),
	getRecipients: () => API.get('/info', { params: { 'type': 'recipient' } }),
	getProviders: () => API.get('/info', { params: { 'type': 'provider' } }),
	index: options => API.get('/contractors' + getParamsByOptions(options)),
	getAll: params => API.get('/contractors/all', { params }),
	get: id => API.get(`/contractors/${id}`),
	updateOrCreate: (data, id) => id ? API.put(`/contractors/${id}`, data) : API.post('/contractors', data),
	delete: id => API.delete(`/contractors/${id}`),
	export: options => API.get('/contractors/export' + getParamsByOptions(options)),
	create_list: data => API.post('/contractors/list', data),
	getListByOption: (sort_option, count, page, search_option) =>
		API.post('/contractors/filter_list', {
			sort_option: sort_option,
			count: count,
			page: page,
			search_option: search_option
		}),
};

export default ContractorAPI;
