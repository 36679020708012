/* eslint-disable no-use-before-define */

import React from 'react';
import Select from 'react-select';

export default function Autocomplete(props) {
	return (
		<Select
			placeholder="Wybierz"
			noOptionsMessage={() => 'Brak opcji'}
			{...props}
			className="basic-multi-select"
			classNamePrefix="select"
			// menuPortalTarget={document.body}
			menuPlacement='auto'
			// isClearable={props.isClearable ? props.isClearable : true}
			closeMenuOnScroll={true}
			styles={{
				container: base => ({
					...base,
					flex: 1
				})
			}}
		/>
	);
}
