/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

const optionsArray = data => {
	let arr = [];
	data &&
		data.map(item => {
			arr.push({
				id: item.id,
				value: item.id,
				title: item.name,
				offset: false
			});
			item.children &&
				item.children.map(expandedItem => {
					arr.push({
						id: expandedItem.id,
						value: expandedItem.id,
						title: expandedItem.name,
						offset: true
					});
					return null;
				});
			return null;
		});
	return arr;
};

const Option = props => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>
				<label style={{ marginLeft: props.data.offset ? 30 : 10 }}>
					{props.label}
				</label>
			</components.Option>
		</div>
	);
};

export default function AutocompleteCheckboxed(props) {
	const { data, label, handleChange, value } = props;
	const [options, setOptions] = useState([]);
	const handleCheck = (selected, clicked) => {
		if (clicked.action === 'clear') {
			selected = [];
		} else {
			let item = data.find(
				i =>
					i.id ===
					(clicked.action === 'remove-value' || clicked.action === 'pop-value'
						? clicked.removedValue.id
						: clicked.option.id)
			);
			if (item) {
				if (item.children?.length) {
					let children = item.children.map(i =>
						options.find(j => j.id === i.id)
					);
					if (clicked.action === 'select-option') {
						selected = selected
							.concat(children)
							.filter((v, i, a) => a.indexOf(v) === i);
					} else if (clicked.action === 'deselect-option') {
						selected = selected.filter(v => children.indexOf(v) < 0);
					}
				}
			} else {
				// this is for children clicked.
			}
		}
		handleChange(selected);
	};

	useEffect(() => {
		if (data) {
			setOptions(optionsArray(data));
		}
	}, [data]);

	return (
		<div
			style={{
				position: 'relative',
				zIndex: 2
			}}>
			<Select
				value={value}
				isMulti
				name="colors"
				options={options}
				hideSelectedOptions={false}
				getOptionLabel={option => option.title}
				getOptionValue={option => option.value}
				className="basic-multi-select"
				classNamePrefix="select"
				placeholder={label}
				closeMenuOnSelect={false}
				components={{ Option }}
				onChange={handleCheck}
				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
				menuPortalTarget={document.body}
			/>
		</div>
	);
}
