/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from 'components/FormInput/Autocomplete';
import { groupByFirstLetter } from 'utils/functions';

import FormInput from 'components/form/FormInput';
import { LinkIconSvg } from 'svg/icons';

export default function DocumentContractors(props) {
	const { box_width, contractors, id, name, onChange } = props;

	const [selectedOption, setSelectedOption] = useState({ name: '' });

	const handleSelectPurchaser = contractor => {
		setSelectedOption(contractor);
		onChange(contractor);
	};

	useEffect(() => {
		setSelectedOption(contractors.find(i => +i.id === +id) || { name: '' });
	}, [contractors, id]);

	return (
		<div
			className="df-ac"
			style={{ width: box_width ? `${box_width}px` : 'auto' }}>
			<FormInput
				box_style={{ width: '64px' }}
				placeholder="ID"
				value={selectedOption?.id || ''}
				disabled
			/>

			<div className="df-centered" style={{ minWidth: '41px' }}>
				<LinkIconSvg />
			</div>

			<Autocomplete
				id={name}
				options={groupByFirstLetter(contractors)}
				value={selectedOption}
				groupBy={option => option.firstLetter}
				getOptionLabel={option => option.name}
				getOptionValue={option => option.id}
				onChange={contractor => handleSelectPurchaser(contractor)}
			/>
		</div>
	);
}
