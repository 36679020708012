import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PATHS from 'routes/paths';
import Api from 'apis/SubscriptionPlanAPI';
import { Loader } from 'components';
import { getPeriod, getPeriodName, getTypeDescription } from './index';
import { isSubscriptionExpiring, subscriptionValidUntil } from 'apis/AuthAPI';

const SubscriptionPlans = props => {
	const classes = useStyles();
	const { isPublic = false } = props;
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	let index = 0;

	useEffect(() => {
		Api.index().then(res => {
			setData(res.data);
			setLoading(false);
		});
	}, []);

	return <Loader loading={loading}>
		{isPublic ? '' : (
			<Grid item xs={12}>
				<Typography variant='h1' component='h2' className={classes.welcomeIntro}>
					Witaj w naszym systemie!
				</Typography>
				<Typography component='p' className={classes.welcomeText}>
					{isSubscriptionExpiring() ? (<>
						Twoja subskrypcja wygaśnie w dniu {subscriptionValidUntil()}.<br/>
						Aby po tym terminie nadal korzystać z aplikacji przedłuż swój abonament.
					</>) : (<>
						Aby korzystać z aplikacji należy wykupić abonament.
						Wybierz abonament z listy, a następnie przejdź do płatności.
					</>)}
				</Typography>
			</Grid>
		)}
		<Grid container spacing={4} className={classes.priceList}>
			{data && Object.entries(data).map(([period, types]) =>
				<Grid key={++index} item xs={12} sm={6} md={4} className={classes.item}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography className={classes.itemTitle}>Abonament {getPeriodName(period)}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography className={classes.itemTime}>{getPeriod(period)} dostępu</Typography>
						</Grid>
						{types && Object.entries(types).map(([type, prices]) =>
							<React.Fragment key={++index}>
								<Grid item className={classes.list}>
									<Typography>{getTypeDescription(type)}</Typography>
									<Typography className={classes.note}>płatność wyłącznie za pomocą PayPro</Typography>
									{prices && Object.entries(prices).map(([users_nb, price]) =>
										<div key={++index} className={classes.priceRow}>
											{users_nb > 1 ? <Typography>dla grupy do {users_nb} osób</Typography> : ''}
											<Typography className={classes.price}>{price} zł</Typography>
										</div>
									)}
								</Grid>
								{isPublic ? '' : (
									<Grid container
												spacing={4}
												direction='row'
												justifyContent='center'>
										<Grid item xs={6}>
											<Button component={Link} to={PATHS.SubscriptionPlan(type, period)}
															className={classes.filled_button}
															variant='outlined'>
												KUP
											</Button>
										</Grid>
									</Grid>
								)}
							</React.Fragment>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	</Loader>;
};

SubscriptionPlans.propTypes = {
	isPublic: PropTypes.bool,
}

export default SubscriptionPlans;
