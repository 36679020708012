import React from 'react';

import { DocumentTable } from 'components/document/DocumentTable';
import InvoiceCreateTableRow from './InvoiceCreateTableRow';

const COLUMN_SIZES = [41, 215, 120, 192, 60, 80, 105, 118, 102, 98, 80, 65, 65, 95, 100];

const TABLE_HEAD = [
	'Lp.',
	'Nazwa towaru lub usługi (zakres wykonanych usług)',
	'Kod towaru',
	'Symbol klasyfikacji\nstatystycznej\nlub podstawa prawna\nzwolnienia od podatku',
	'Ilość',
	'Miara',
	{
		head: 'Cena jednostkowa\nBez podatku',
		items: [
			{ th: 'zł', index: 6 },
			{ th: 'gr', index: 7 }
		]
	},
	{
		head: 'Wartość towarów (usług)\nBez podatków',
		items: [
			{ th: 'zł', index: 8 },
			{ th: 'gr', index: 9 }
		]
	},
	'Stawka podatku',
	{
		head: 'Kwota podatku',
		items: [
			{ th: 'zł', index: 11 },
			{ th: 'gr', index: 12 }
		]
	},
	{
		head: 'Wartość towarów (usług)\nWraz z podatkiem',
		items: [
			{ th: 'zł', index: 13 },
			{ th: 'gr', index: 14 }
		]
	}
];


export default function InvoiceCreateTable(props) {
	return (
		<DocumentTable
			{...props}
			head={TABLE_HEAD}
			column_sizes={COLUMN_SIZES}
			RowComponent={InvoiceCreateTableRow}
		/>
	);
}

