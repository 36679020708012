/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormInput, SingleDetail } from 'components';
import useStyles from './style';
import AdminInvoiceAPI from 'apis/AdminInvoiceAPI';
import PATHS from 'routes/paths';
import Loader from 'components/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import { Grid, Typography } from '@material-ui/core';

const AdminInvoice = () => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams();
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({ country: 'Polska' });

	useEffect(() => {
		axios.all([
			id ? AdminInvoiceAPI.get(id) : null,
		]).then(axios.spread((item) => {
			item?.data && setData(item.data);
			setLoading(false);
		}));
	}, []);

	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSave = () => {
		// if (!data.type) {
		// 	return addToast('Nie wybrano typu.', { appearance: 'error' });
		// }

		setLoading(true);
		AdminInvoiceAPI.updateOrCreate(data, id).then(response => {
			if ([200, 201].includes(response.code)) {
				addToast(response.message || 'Zapisano.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AdminInvoiceList), 100);
			}
			setLoading(false);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		AdminInvoiceAPI.delete(id).then(response => {
			if (response.code === 200) {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				setTimeout(() => history.push(PATHS.AdminInvoiceList), 100);
			}
			setLoading(false);
		});
	};

	return (<>
			<Breadcrumbs
				list={['Administracja', { label: 'Faktury', path: PATHS.AdminInvoiceList }, id ? 'Edytuj' : 'Dodaj']}
				back_url={PATHS.AdminInvoiceList}
			/>

			<Loader loading={loading}>
				<SingleDetail
					title="Dane faktury"
					type={id && 'edit'}
					handleSave={handleSave}
					handleDelete={handleDelete}>
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormInput
									title="Imię"
									name="first_name"
									type="input"
									value={data.first_name}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Nazwisko"
									name="last_name"
									type="input"
									value={data.last_name}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<FormInput
									title="Nazwa firmy"
									name="company_name"
									type="input"
									value={data.company_name}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="NIP"
									name="nip"
									type="input"
									value={data.nip}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="REGON"
									name="regon"
									type="input"
									value={data.regon}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2} className={classes.lineTop}>
							<Grid item xs={6}>
								<FormInput
									title="Ulica"
									name="street"
									type="input"
									value={data.street}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormInput
									title="Nr budynku"
									name="building_number"
									type="input"
									value={data.building_number}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={3}>
								<FormInput
									title="Nr lokalu"
									name="apartment_number"
									type="input"
									value={data.apartment_number}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Miejscowość"
									name="city"
									type="input"
									value={data.city}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={3}>
								<FormInput
									title="Kod pocztowy"
									name="postal_code"
									type="input"
									value={data.postal_code}
									handleChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormInput
									title="Poczta"
									name="post_office"
									type="input"
									value={data.post_office}
									handleChange={handleChange}
								/>
							</Grid>

							<Grid item xs={6}>
								<FormInput
									title="Państwo"
									name="country"
									type="input"
									value={data.country}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
					</>
				</SingleDetail>
			</Loader>
		</>
	);
};

export default AdminInvoice;
