/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes/paths';
import { Breadcrumbs } from 'components';
import TopButtonsBox from 'components/TopButtonsBox';
import {
	Typography,
	Grid,
	InputAdornment,
	Box,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import CheckMark from 'components/misc/CheckMark';
import moment from 'moment';
import AnalyzeXyzAPI from 'apis/AnalyzeXyzAPI';
import PreviewButton from 'components/misc/PreviewButton';
import DetailButton from 'components/misc/DetailButton';
import TrashButton from 'components/misc/TrashButton';
import useStyles from './style';
import { useToasts } from 'react-toast-notifications';

const AnalizeXyzList = () => {
	const classes = useStyles();
	const history = useHistory();
	const { addToast } = useToasts();

	const rowsPerPage = 20;

	const header = [
		{ id: 'name', label: 'Nazwa', filter: 'text' },
		{
			id: 'abc_analysis',
			label: 'ABC',
			filter: 'check',
			disableSort: true,
			align: 'center'
		},
		{
			id: 'xyz_analysis',
			label: 'XYZ',
			filter: 'check',
			disableSort: true,
			align: 'center'
		},
		{ id: 'range_from', label: 'Zakres od', filter: 'date', align: 'center' },
		{ id: 'range_to', label: 'Zakres do', filter: 'date', align: 'center' },
		{ id: 'assortments_group', label: 'Grupy asortymentowe', filter: 'text' },
		{ id: 'description', label: 'Opis', filter: 'text' },
		{
			id: 'updated_at',
			label: 'Data wykonania',
			filter: 'date',
			align: 'center'
		},
		{ id: 'action', label: 'Akcje', align: 'center', disableSort: true }
	];
	const [data, setData] = useState([]);
	const [options, setOptions] = useState();
	const [loading, setLoading] = useState(false);
	const [rowsCount, setRowsCount] = useState(0);
	const [deleteId, setDeleteId] = useState();

	const handleDelete = sure => {
		if (sure) {
			setLoading(true);
			AnalyzeXyzAPI.delete(deleteId).then(response => {
				addToast(response.message || 'Usunięto.', { appearance: 'success' });
				getData();
			});
		}
		setDeleteId(null);
	};

	const handlePreview = index => {
		let rows = JSON.parse(JSON.stringify(data));
		rows[index].preview = !rows[index].preview;
		setData(rows);
	};

	const handleGoToResult = id => {
		history.push(PATHS.AnalyzeXyzResult(id));
	};

	const getData = () => {
		setLoading(true);
		AnalyzeXyzAPI.index(options).then(response => {
			if (response.code === 200) {
				setData(response.data);
				setRowsCount(response.total);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (options) {
			getData();
		}
	}, [options]);

	return (
		<>
			<TopButtonsBox
				importHandler={false}
				exportHandler={false}
				add_button={{
					path: PATHS.AnalyzeXyzCreate,
					label: 'Dodaj nową analizę'
				}}
			/>

			<Breadcrumbs list={['Analizy', 'Analiza ABC i XYZ']}/>

			<Table
				className={classes.table}
				rows={data}
				header={header}
				loading={loading}
				rowsCount={rowsCount}
				rowsPerPage={rowsPerPage}
				onChange={setOptions}
				deleteId={deleteId}
				onDelete={handleDelete}>
				{data.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<Row>
								<Cell>{row.name}</Cell>
								<Cell align="center">
									<CheckMark checked={row.abc_analysis} />
								</Cell>
								<Cell align="center">
									<CheckMark checked={row.xyz_analysis} />
								</Cell>
								<Cell align="center">
									{moment(row.range_from).format('DD/MM/YYYY')}
								</Cell>
								<Cell align="center">
									{moment(row.range_to).format('DD/MM/YYYY')}
								</Cell>
								<Cell>{row.assortment_groups_main_names}</Cell>
								<Cell>{row.description}</Cell>
								<Cell align="center">
									{moment(row.updated_at).format('DD/MM/YYYY')}
								</Cell>
								<Cell align="center" width={180}>
									<PreviewButton
										active={!!row.preview}
										onClick={() => handlePreview(index)}
									/>
									<DetailButton onClick={() => handleGoToResult(row.id)} />
									<TrashButton onClick={() => setDeleteId(row.id)} />
								</Cell>
							</Row>

							{row.preview && (
								<Row>
									<Cell colSpan={9}>
										<Grid container>
											<Grid item xs={6}>
												<FormGroup>
													<FormControlLabel
														control={<Checkbox checked={!!row.abc_analysis} />}
														label="Analiza ABC"
													/>
												</FormGroup>
												<Box display="flex" style={{ paddingLeft: 30 }}>
													<Box>
														<FormGroup>
															<FormControlLabel
																control={
																	<Checkbox checked={!!row.releases_size} />
																}
																label="Wielkość wydań"
															/>
														</FormGroup>
														<FormGroup>
															<FormControlLabel
																control={
																	<Checkbox checked={!!row.releases_value} />
																}
																label="Wartość wydań"
															/>
														</FormGroup>
														<FormGroup>
															<FormControlLabel
																control={
																	<Checkbox checked={!!row.stock_size} />
																}
																label="Wielkość zapasu"
															/>
														</FormGroup>
														<FormGroup>
															<FormControlLabel
																control={
																	<Checkbox checked={!!row.stock_value} />
																}
																label="Wartość zapasu"
															/>
														</FormGroup>
													</Box>
													<Box style={{ paddingLeft: 20 }}>
														<Box
															display="flex"
															alignItems="center"
															style={{ marginBottom: 10 }}>
															<Typography style={{ marginRight: 10 }}>
																Kategoria A:
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.abc_analysis_details_category_a_min}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
															<Typography
																style={{ marginLeft: 10, marginRight: 10 }}>
																-
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.abc_analysis_details_category_a_max}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
														</Box>
														<Box
															display="flex"
															alignItems="center"
															style={{ marginBottom: 10 }}>
															<Typography style={{ marginRight: 10 }}>
																Kategoria B:
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.abc_analysis_details_category_b_min}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
															<Typography
																style={{ marginLeft: 10, marginRight: 10 }}>
																-
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.abc_analysis_details_category_b_max}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
														</Box>
														<Box
															display="flex"
															alignItems="center"
															style={{ marginBottom: 10 }}>
															<Typography style={{ marginRight: 10 }}>
																Kategoria C:
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.abc_analysis_details_category_c_min}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
															<Typography
																style={{ marginLeft: 10, marginRight: 10 }}>
																-
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.abc_analysis_details_category_c_max}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
														</Box>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={6}>
												<FormGroup>
													<FormControlLabel
														control={<Checkbox checked={!!row.xyz_analysis} />}
														label="Analiza XYZ"
													/>
												</FormGroup>
												<Box display="flex" style={{ paddingLeft: 30 }}>
													<Box style={{ paddingLeft: 100 }}>
														<Box
															display="flex"
															alignItems="center"
															style={{ marginBottom: 10 }}>
															<Typography style={{ marginRight: 10 }}>
																Kategoria X:
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.xyz_analysis_details_category_a_min}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
															<Typography
																style={{ marginLeft: 10, marginRight: 10 }}>
																-
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.xyz_analysis_details_category_a_max}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
														</Box>
														<Box
															display="flex"
															alignItems="center"
															style={{ marginBottom: 10 }}>
															<Typography style={{ marginRight: 10 }}>
																Kategoria Y:
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.xyz_analysis_details_category_b_min}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
															<Typography
																style={{ marginLeft: 10, marginRight: 10 }}>
																-
															</Typography>
															<TextField
																type="number"
																variant="outlined"
																size="small"
																disabled
																value={row.xyz_analysis_details_category_b_max}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			%
																		</InputAdornment>
																	)
																}}
															/>
														</Box>
														<Box
															display="flex"
															justifyContent="space-between"
															alignItems="center"
															style={{ marginBottom: 10 }}>
															<Typography style={{ marginRight: 10 }}>
																Kategoria Z:
															</Typography>
															<Box display="flex" alignItems="center">
																<Typography
																	style={{ marginLeft: 10, marginRight: 25 }}>
																	powyżej
																</Typography>
																<TextField
																	type="number"
																	variant="outlined"
																	size="small"
																	disabled
																	value={
																		row.xyz_analysis_details_category_c_max
																	}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position="start">
																				%
																			</InputAdornment>
																		)
																	}}
																/>
															</Box>
														</Box>
													</Box>
												</Box>
											</Grid>
										</Grid>
									</Cell>
								</Row>
							)}
						</React.Fragment>
					);
				})}
			</Table>
		</>
	);
};

export default AnalizeXyzList;
