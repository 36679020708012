import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	buttonContainer: {
		flexShrink: 1,
		marginTop: theme.spacing(6),
		maxWidth: 300,
		width: '100%',
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	priceList: {
		marginTop: theme.spacing(4),
		width: '100%',
		maxWidth: 1500,
		flexGrow: 1,
		justifyContent: 'center',
	},
	itemTitle: {
		fontSize: '120%',
	},
	itemTime: {
		color: theme.palette.green,
	},
	price: {
		fontWeight: 'bold',
	},
	priceRow: {
		display: 'flex',
		marginTop: theme.spacing(1),
		justifyContent: 'space-between'
	},
	list: {
		position: 'relative',
		marginLeft: theme.spacing(3),
		'&::before': {
			content: '"■"',
			position: 'absolute',
			left: -10,
			top: 11,
			fontSize: 9,
			color: theme.palette.green,
		}
	},
}));

export default useStyles;
