/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { Box } from '@material-ui/core';
import { Table, Row, Cell } from 'components/MyTable';
import ChartButton from 'components/misc/ChartButton';
import Chart from 'components/Chart/ConditionalChart';
import { sortData, exportToXlsx, exportToPng } from 'utils/functions';

const AssortmentPercent = props => {
	const header = [
		{ id: 'name', label: 'Nazwa' },
		{ id: 'index', label: 'Indeks' },
		{ id: 'assortment_group_name', label: 'Grupa' },
		{ id: 'measure_name', label: 'Jedn.miary' },
		{
			id: 'percentage_zc',
			label: 'Udział procent ZC w ZŚ ilośiowo',
			info: ['U = ZC/ZŚ * 100', 'ZC - zapas cykliczn', 'ZŚ - zapas średni']
		},
		{
			id: 'percentage_zn',
			label: 'Udział procent ZN w ZŚ ilośiowo',
			info: ['U = ZN/ZŚ * 100%', 'ZN - zapas nadmierny', 'ZŚ - zapas średni']
		},
		{
			id: 'percentage_zz',
			label: 'Udział procent ZZ w ZŚ ilośiowo',
			info: [
				'U = ZZ/ZŚ * 100%',
				'ZZ - zapas zabezpieczający',
				'ZŚ - zapas średni'
			]
		},
		{
			id: 'average_stock',
			label: 'Zapas średni całk. ilościowo (ZŚ)',
			info: ['ZŚ = Z', 'Z - średni stan zapasu']
		},
		{ id: 'action', label: '' }
	];
	const [data, setData] = useState([]);
	const [activeRow, setActiveRow] = useState({});
	const [chartData, setChartData] = useState([]);

	const handleExport = () => {
		exportToXlsx(header, data);
	};

	const handleDrawChart = row => {
		setChartData([
			{ label: `ZC`, y: row.percentage_zc },
			{ label: `ZN`, y: row.percentage_zn },
			{ label: `ZZ`, y: row.percentage_zz }
		]);
		setActiveRow(row);
	};

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	return (
		<>
			<Box mb={2}>
				<Button onClick={handleExport}>Eksportuj do XLS</Button>
				<Button
					onClick={() => exportToPng(props.chartId)}
					style={{ marginLeft: 20 }}>
					Pobierz wykres .PNG
				</Button>
			</Box>
			<Box mb={2}>
				<Table
					header={header}
					disablePagination={true}
					onSort={o => setData([...sortData(data, o.columnId, o.direction)])}>
					{data.map((row, index) => {
						return (
							<Row key={index}>
								<Cell>{row.name}</Cell>
								<Cell>{row.index}</Cell>
								<Cell>{row.assortment_group_name}</Cell>
								<Cell>{row.measure_name}</Cell>
								<Cell>{row.percentage_zc}</Cell>
								<Cell>{row.percentage_zn}</Cell>
								<Cell>{row.percentage_zz}</Cell>
								<Cell>{row.average_stock}</Cell>
								<Cell>
									<ChartButton
										active={activeRow === row}
										onClick={() => handleDrawChart(row)}
									/>
								</Cell>
							</Row>
						);
					})}
				</Table>
			</Box>
			<Box id={props.chartId} mb={2}>
				<Chart data={chartData} />
			</Box>
		</>
	);
};

export default AssortmentPercent;
