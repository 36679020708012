/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Collapse from 'components/Collapse/Collapse';
import AssortmentAmount from './AssortmentAmount';
import GroupAmount from './GroupAmount';
import uniqid from 'uniqid';

const IndicativeQuantitative = props => {
	return (
		<>
			<Collapse
				defaultOpen
				title="Analiza wskaźnikowa ilościowa - ujęcie asortymentowe"
				content={<AssortmentAmount data={props.data?.assortment_shot} />}
			/>

			<Collapse
				defaultOpen
				title="Analiza wskaźnikowa ilościowa - ujęcie grupowe"
				content={
					<GroupAmount chartId={uniqid()} data={props.data?.group_shot} />
				}
			/>
		</>
	);
};

export default IndicativeQuantitative;
